import { Suspense, lazy } from "react";
import { Outlet } from "react-router-dom";
// auth
import { AuthGuard } from "src/auth/guard";
// layouts
import DashboardLayout from "src/layouts/dashboard";
// components
import { LoadingScreen } from "src/components/loading-screen";
import DemoListView from "src/sections/create-demo/view/create-demo-view";
import DemoQuestionsListView from "src/sections/demo-questions/view/demo-questions-view";
import { FollowUpListView } from "src/sections/user/view";
import LinkedinListPage from "src/pages/dashboard/linkedin/list";
import LinkedinListView from "src/pages/dashboard/linkedin/linkedin-lead-list-view";
import EmailEditPage from "src/pages/dashboard/emailtemplate/edit";
import VideoEditPage from "src/pages/dashboard/video/edit";
import EmailAccountsEditPage from "src/pages/dashboard/video/edit";
import VideoEditView from "src/sections/video/video-edit-view";
import GoogleOAuth from "src/sections/user/view/gmail-api";
import OutlookOAuth from "src/sections/user/view/outlook-api";
import LeadFinderEditPage from "src/pages/dashboard/leadfinder/leadfindereditpage";
import TriggerPage from "src/pages/dashboard/triggers/triggerpage";

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import("src/pages/dashboard/app"));
const OverviewEcommercePage = lazy(() =>
  import("src/pages/dashboard/ecommerce")
);
const OverviewAnalyticsPage = lazy(() =>
  import("src/pages/dashboard/analytics")
);
const OverviewMarketingPage = lazy(() =>
  import("src/pages/dashboard/marketing")
);
const OverviewSalesPage = lazy(() => import("src/pages/dashboard/sales"));
const OverviewBankingPage = lazy(() => import("src/pages/dashboard/banking"));
const OverviewBookingPage = lazy(() => import("src/pages/dashboard/booking"));
const OverviewFilePage = lazy(() => import("src/pages/dashboard/file"));
// PRODUCT
const ProductDetailsPage = lazy(() =>
  import("src/pages/dashboard/product/details")
);
const ProductListPage = lazy(() => import("src/pages/dashboard/product/list"));
const ProductCreatePage = lazy(() => import("src/pages/dashboard/product/new"));
const ProductEditPage = lazy(() => import("src/pages/dashboard/product/edit"));
// ORDER
const OrderListPage = lazy(() => import("src/pages/dashboard/order/list"));
const OrderDetailsPage = lazy(() =>
  import("src/pages/dashboard/order/details")
);
// INVOICE
const InvoiceListPage = lazy(() => import("src/pages/dashboard/invoice/list"));
const InvoiceDetailsPage = lazy(() =>
  import("src/pages/dashboard/invoice/details")
);
const InvoiceCreatePage = lazy(() => import("src/pages/dashboard/invoice/new"));
const InvoiceEditPage = lazy(() => import("src/pages/dashboard/invoice/edit"));
// USER
const UserProfilePage = lazy(() => import("src/pages/dashboard/user/profile"));
const UserCardsPage = lazy(() => import("src/pages/dashboard/user/cards"));
const UserListPage = lazy(() => import("src/pages/dashboard/user/list"));
const UserAccountPage = lazy(() => import("src/pages/dashboard/user/account"));
const UserCreatePage = lazy(() => import("src/pages/dashboard/user/new"));
const UserEditPage = lazy(() => import("src/pages/dashboard/user/edit"));
// BLOG
const BlogPostsPage = lazy(() => import("src/pages/dashboard/post/list"));
const BlogPostPage = lazy(() => import("src/pages/dashboard/post/details"));
const BlogNewPostPage = lazy(() => import("src/pages/dashboard/post/new"));
const BlogEditPostPage = lazy(() => import("src/pages/dashboard/post/edit"));
// JOB
const JobDetailsPage = lazy(() => import("src/pages/dashboard/job/details"));
const JobListPage = lazy(() => import("src/pages/dashboard/job/list"));
const JobCreatePage = lazy(() => import("src/pages/dashboard/job/new"));
const JobEditPage = lazy(() => import("src/pages/dashboard/job/edit"));
// TOUR
const TourDetailsPage = lazy(() => import("src/pages/dashboard/tour/details"));
const TourListPage = lazy(() => import("src/pages/dashboard/tour/list"));
const TourCreatePage = lazy(() => import("src/pages/dashboard/tour/new"));
const TourEditPage = lazy(() => import("src/pages/dashboard/tour/edit"));
// FILE MANAGER
const FileManagerPage = lazy(() => import("src/pages/dashboard/file-manager"));
// APP
const ChatPage = lazy(() => import("src/pages/dashboard/chat"));
const MailPage = lazy(() => import("src/pages/dashboard/mail"));
const CalendarPage = lazy(() => import("src/pages/dashboard/calendar"));
const KanbanPage = lazy(() => import("src/pages/dashboard/kanban"));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() =>
  import("src/pages/dashboard/permission")
);
// BLANK PAGE
const BlankPage = lazy(() => import("src/pages/dashboard/blank"));
const DesignPage = lazy(() => import("src/pages/dashboard/blank"));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: "leads", element: <UserListPage /> },
      { path: "inbox", element: <MailPage /> },
      { path: "emailtemplate", element: <EmailEditPage /> },
      { path: "leadfinder", element: <LeadFinderEditPage /> },
      { path: "trigger", element: <TriggerPage /> },
      { path: "video", element: <VideoEditView /> },
      { path: "emailaccounts", element: <EmailAccountsEditPage /> },
      { path: "gmail", element: <GoogleOAuth /> },
      { path: "outlook", element: <OutlookOAuth /> },
      // { path: "linkedin", element: <LinkedinListView /> },
      // { path: "followups", element: <FollowUpListView /> },

      {
        path: "user",
        children: [
          { element: <UserProfilePage />, index: true },
          { path: "profile", element: <UserProfilePage /> },
          { path: "cards", element: <UserCardsPage /> },
          { path: "list", element: <UserListPage /> },
          { path: "new", element: <UserCreatePage /> },
          { path: ":id/edit", element: <UserEditPage /> },
          { path: "account", element: <UserAccountPage /> },
        ],
      },
    ],
  },
];
