import * as Yup from "yup";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
// @mui
import LoadingButton from "@mui/lab/LoadingButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// routes
import { paths } from "src/routes/paths";
import { RouterLink } from "src/routes/components";
// assets
import { PasswordIcon } from "src/assets/icons";
// components
import Iconify from "src/components/iconify";
import FormProvider, { RHFTextField } from "src/components/hook-form";
import PlanFreeIcon from "src/assets/icons/plan-free-icon";
import {
  addDocument,
  getSingle,
} from "src/auth/context/firebase/auth-provider";
import uuidv4 from "src/utils/uuidv4";
import { useNavigate } from "react-router";
import { CircularProgress } from "@mui/material";
import DemoVideo from "../demo-video";

// ----------------------------------------------------------------------

DemoWelcomeView.propTypes = {
  complete: PropTypes.func,
};

export default function DemoWelcomeView({ complete }) {
  const isMobileOrTablet = window.matchMedia("(max-width: 768px)").matches;
  if (isMobileOrTablet) {
    return (
      <Box
        component="main"
        xs={12}
        sx={{
          minHeight: "100vh",
          width: "100vw",
          display: "flex",
          flexDirection: "column", // Stack content vertically
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <h1>Please view this on a Laptop</h1>
      </Box>
    );
  }

  return (
    <Box
      component="main"
      sx={{
        // mt: 12,
        display: "flex",
        minHeight: "100vh",
        textAlign: "center",
        // px: { xs: 6, md: 0 },
        // position: 'relative',
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <DemoVideo />
    </Box>
  );
}
