import PropTypes from "prop-types";
import * as Yup from "yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";

import CryptoJS from "crypto-js";

// @mui
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import {
  addDocument,
  updateSingle,
} from "src/auth/context/firebase/auth-provider";
import {
  Box,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useAuthContext } from "src/auth/hooks";
import { useNavigate } from "react-router";
import AccountGeneral from "src/sections/account/account-general";
import EmailEditForm from "./email-edit-form";
import { ClosedCaption } from "@mui/icons-material";

// ----------------------------------------------------------------------

export default function EmailFlowModalView({ open, onClose, flow }) {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { minHeight: "80vh" },
      }}
    >
      <DialogTitle id="id">
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>{flow.campaignFlowStep}</Box>
          <Box>
            <IconButton onClick={onClose}>x</IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent sx={{}}>
        &nbsp; &nbsp;
        <EmailEditForm user={user} flow={flow} />
      </DialogContent>
    </Dialog>
  );
}

EmailFlowModalView.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
