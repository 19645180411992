import { Helmet } from "react-helmet-async";
import EmailAccountsEditView from "src/sections/emailaccounts/emailaccounts-edit-view";

// ----------------------------------------------------------------------

export default function EmailAccountsEditPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Email Accounts Edit</title>
      </Helmet>

      <EmailAccountsEditView />
    </>
  );
}
