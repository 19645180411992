import { Navigate, useRoutes, Outlet } from "react-router-dom";
// layouts
import MainLayout from "src/layouts/main";
// config
import { PATH_AFTER_LOGIN } from "src/config-global";
//
import { mainRoutes, HomePage } from "./main";
import { authRoutes } from "./auth";
import { authDemoRoutes } from "./auth-demo";
import { dashboardRoutes } from "./dashboard";
import { componentsRoutes } from "./components";
import { useAuthContext } from "src/auth/hooks";
import { Crisp } from "crisp-sdk-web";

// ----------------------------------------------------------------------

export default function Router() {
  const { user } = useAuthContext();
  if (user) {
    Crisp.user.setEmail(user?.email);
    Crisp.user.setNickname(user?.displayName);
  }

  return useRoutes([
    // SET INDEX PAGE WITH SKIP HOME PAGE
    {
      path: "/",
      element: <Navigate to="/video" replace />,
    },

    // ----------------------------------------------------------------------

    // SET INDEX PAGE WITH HOME PAGE
    // {
    //   path: '/',
    //   element: (
    //     <MainLayout>
    //       <Outlet />
    //     </MainLayout>
    //   ),
    //   children: [{ element: <HomePage />, index: true }],
    // },

    // Auth routes
    ...authRoutes,
    // ...authDemoRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // Main routes
    ...mainRoutes,

    // Components routes
    // ...componentsRoutes,

    // No match 404
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
