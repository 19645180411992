import PropTypes from "prop-types";
import { useEffect } from "react";
// @mui
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
// hooks
import { useResponsive } from "src/hooks/use-responsive";
// hooks
import { useAuthContext } from "src/auth/hooks";
// components
import Logo from "src/components/logo";
import Scrollbar from "src/components/scrollbar";
import { usePathname } from "src/routes/hook";
import { NavSectionVertical } from "src/components/nav-section";
//
import { NAV } from "../config-layout";
import { useNavData } from "./config-navigation";
import { NavToggleButton, NavUpgrade } from "../_common";
import NavDemoLink from "../_common/nav-demo-link";
import NavOnboarding from "../_common/nav-onbaording";

// ----------------------------------------------------------------------

export default function NavVertical({ openNav, onCloseNav }) {
  const { user } = useAuthContext();

  const pathname = usePathname();

  const lgUp = useResponsive("up", "lg");

  const navData = useNavData();

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <div style={{ maxHeight: "20%" }}>
        <Logo sx={{ mt: 3, ml: 4, mb: 1 }} />
      </div>
      <NavSectionVertical
        data={navData}
        config={{
          currentRole: user?.role || "admin",
        }}
      />

      <Box sx={{ flexGrow: 1 }} />

      {/* {!user?.onboardingComplete && <NavOnboarding />} */}
      <NavUpgrade />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_VERTICAL },
      }}
    >
      <NavToggleButton />

      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: "fixed",
            width: NAV.W_VERTICAL,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

NavVertical.propTypes = {
  onCloseNav: PropTypes.func,
  openNav: PropTypes.bool,
};
