import { useEffect, useCallback, useState } from "react";

import Container from "@mui/material/Container";
// routes
import { paths } from "src/routes/paths";
import { useParams } from "src/routes/hook";
// _mock
import { _userList } from "src/_mock";
// components
import { useSettingsContext } from "src/components/settings";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";
import axios from "axios";

import EmailEditForm from "./email-edit-form";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WorkIcon from "@mui/icons-material/Work";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import BuildIcon from "@mui/icons-material/Build";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import StarIcon from "@mui/icons-material/Star";
import { useAuthContext } from "src/auth/hooks";
import {
  AddCircleOutline,
  ExpandMoreOutlined,
  KeyboardArrowDown,
  KeyboardArrowDownOutlined,
  KeyboardDoubleArrowDown,
} from "@mui/icons-material";
import FollowupEmailEditForm from "./followup-email-edit-form";
import { useNavigate } from "react-router";
import {
  addDocument,
  getMultiple,
  getMultipleDetailed,
  getSingle,
  getSingleDetailed,
  updateOrCreateSingle,
  updateSingle,
} from "src/auth/context/firebase/auth-provider";
import { useBoolean } from "src/hooks/use-boolean";
import CampaignListView from "../user/view/campaign-list-view";
import EmailFlowModalView from "./email-flow-modal";
import { testdata } from "./testdata";
import LeadFinderListView from "./lead-finder-list-view";

// ----------------------------------------------------------------------

export default function LeadFinderView() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const settings = useSettingsContext();
  const modal = useBoolean();

  const params = useParams();

  const { id } = params;

  const { user } = useAuthContext();

  const [page, setPage] = useState(1);
  const [searchData, setSearchData] = useState([]);
  const [location, setLocation] = useState("");
  const [jobTitles, setJobTitles] = useState("");
  const [skills, setSkills] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyLocation, setCompanyLocation] = useState("");
  const [industry, setIndustry] = useState("");

  const [minCompanySize, setMinCompanySize] = useState("0"); // Initialize with default value 0
  const [maxCompanySize, setMaxCompanySize] = useState("10000"); // Initialize with default value 10000

  const handleMinCompanySizeChange = (e) => {
    const value = e.target.value.replace(/\D/, ""); // Replace any non-digit characters with an empty string
    setMinCompanySize(value);
  };

  const handleMaxCompanySizeChange = (e) => {
    const value = e.target.value.replace(/\D/, ""); // Replace any non-digit characters with an empty string
    setMaxCompanySize(value);
  };

  useEffect(() => {
    if (!location) {
      getSearch();
    }
  }, [searchData, page, location]);

  const getSearch = async () => {
    const search = await getSingle("leadFinder", "client", user?.client);
    if (search[0]) {
      const data = search[0];
      setLocation(data?.location);
      setJobTitles(data?.jobTitles);
      setSkills(data?.skills);
      setCompanyName(data?.companyName);
      setCompanyLocation(data?.companyLocation);
      setIndustry(data?.industry);
      setMinCompanySize(data?.minCompanySize);
      setMaxCompanySize(data?.maxCompanySize);
    }
  };

  const handleSearch = async (addPage) => {
    setLoading(true);
    let newMinCompanySize;
    let newMaxCompanySize;
    if (minCompanySize == undefined) {
      newMinCompanySize = "0";
    } else {
      newMinCompanySize = minCompanySize;
    }
    if (maxCompanySize == undefined) {
      newMaxCompanySize = "10000";
    } else {
      newMaxCompanySize = maxCompanySize;
    }
    console.log(newMinCompanySize, newMaxCompanySize, "minCompanySize");
    updateOrCreateSingle("leadFinder", "client", user?.client, {
      location,
      jobTitles,
      skills,
      companyName,
      companyLocation,
      industry,
      client: user?.client,
      minCompanySize: newMinCompanySize,
      maxCompanySize: newMaxCompanySize,
    });
    var newPage = page;
    if (addPage) {
      console.log(addPage, "addPage");
      newPage += 1;
      setPage((newPage += 1));
    }
    console.log(newPage);
    var allKeywords = "";
    if (skills) {
      allKeywords += skills;
    }
    if (companyName || industry) {
      if (allKeywords) {
        allKeywords += ",";
      }
      if (companyName) {
        allKeywords += companyName;
      }
      if (industry) {
        if (companyName) {
          allKeywords += ",";
        }
        allKeywords += industry;
      }
    }
    console.log(`${minCompanySize}, ${maxCompanySize}`);
    var data;
    if (allKeywords) {
      data = {
        api_key: "e0g0KL88pwCcCdnz5g3Nig",
        page: 1,
        per_page: 100,
        // q_keywords: allKeywords,
        contact_email_status: ["verified"],
        person_locations: [location],
        person_titles: [jobTitles],
        organization_num_employees_ranges: [
          `${newMinCompanySize}, ${newMaxCompanySize}`,
        ],
        page: page,
      };
    } else if (!allKeywords) {
      data = {
        api_key: "e0g0KL88pwCcCdnz5g3Nig",
        page: 1,
        per_page: 100,
        contact_email_status: ["verified"],
        person_locations: [location],
        person_titles: [jobTitles],
        organization_num_employees_ranges: [
          `${newMinCompanySize}, ${newMaxCompanySize}`,
        ],
        page: page,
      };
    }

    try {
      console.log(data, "data");
      const response = await axios.post(
        // "http://127.0.0.1:5001/video-outreach-773a2/us-central1/crawlApolloLeads",
        "https://us-central1-video-outreach-773a2.cloudfunctions.net/crawlApolloLeads",
        data
      );
      console.log(response, "RESPONSE FROM FFF");
      console.log(response.data.people, "PEOPLEHERE");
      // Handle the response data as needed
      if (addPage) {
        const allLeads = await getMultiple("leads", "client", user?.client);
        const datahere = [...searchData, ...response.data.people];
        console.log(datahere, "datahere");
        let filteredDataHere = datahere; // Initialize with the original data
        if (allLeads) {
          filteredDataHere = datahere.filter(
            (data) => !allLeads.map((lead) => lead.name).includes(data.name)
          );
        }
        setSearchData(filteredDataHere);
      } else {
        const allLeads = await getMultiple("leads", "client", user?.client);
        console.log(allLeads, "allLeads");
        const datahere = response.data.people;
        console.log(datahere, "datahere");
        let filteredDataHere = datahere; // Initialize with the original data
        if (allLeads) {
          filteredDataHere = datahere.filter(
            (data) => !allLeads.map((lead) => lead.leadName).includes(data.name)
          );
        }
        setSearchData(response.data.people);
      }
      setLoading(false);
    } catch (error) {
      // Handle errors
      console.error("Error searching:", error);
      setLoading(false);
    }
  };

  const handleReset = () => {
    // Implement reset functionality to clear filter values
    setSearchQuery("");
    setJobTitles("");
    setSkills("");
    setCompanyName("");
    setCompanyLocation("");
    setIndustry("");
    setCompanySize("");
    setFounded("");
    setRevenue("");
    setSpecialties("");
    // Reset other filters as needed
  };

  return (
    <Container maxWidth={settings.themeStretch ? false : "lg"}>
      <CustomBreadcrumbs
        heading="Edit"
        links={[
          {
            name: "Dashboard",
            href: paths.dashboard.root,
          },
          {
            name: "Lead Finder",
            // href: paths.dashboard.user.root,
          },
        ]}
        // sx={{
        // mb: { xs: 3, md: 5 },
        // }}
      />

      <Grid container sx={{ alignItems: "center", textAlign: "center" }}>
        <Typography variant="h6">Search</Typography>
        <Grid container spacing={1} sx={{ backgroundColor: "white" }}>
          <Grid item xs={4}>
            <TextField
              label="Location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationOnIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Job title"
              value={jobTitles}
              onChange={(e) => setJobTitles(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <WorkIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Skills"
              value={skills}
              onChange={(e) => setSkills(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BuildIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              label="Min Company Size"
              value={minCompanySize}
              onChange={handleMinCompanySizeChange}
              fullWidth
              margin="normal"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BusinessIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              label="Max Company Size"
              value={maxCompanySize}
              onChange={handleMaxCompanySizeChange}
              fullWidth
              margin="normal"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BusinessIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Company location"
              value={companyLocation}
              onChange={(e) => {
                setCompanyLocation(e.target.value);
              }}
              fullWidth
              margin="normal"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationOnIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Industry"
              value={industry}
              onChange={(e) => setIndustry(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BusinessOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <Button variant="outlined" onClick={handleReset} fullWidth>
              Reset filters
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSearch(false)}
              fullWidth
            >
              Search
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            marginTop: "20px",
          }}
        >
          {searchData.length < 1 && !loading ? (
            <Typography>Add a filter to initiate your search</Typography>
          ) : (
            <LeadFinderListView
              data={searchData}
              loadMoreData={() => {
                handleSearch(true);
              }}
              loading={loading}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
