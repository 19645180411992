import PropTypes from "prop-types";
import { forwardRef, useEffect, useState } from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import { useAuthContext } from "src/auth/hooks";
// routes
import { RouterLink } from "src/routes/components";
import MainLogo from "src/assets/logo/demogrowth-logo.png";
import { getAll, getSingle } from "src/auth/context/firebase/auth-provider";
import Cookies from "js-cookie";

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  const [businessLogo, setBusinessLogo] = useState();
  const { user } = useAuthContext();

  const saveDataToCookies = (logoData) => {
    const data = logoData;
    Cookies.set("logo", JSON.stringify(data));
  };

  const retrieveDataFromCookies = () => {
    const data = Cookies.get("logo");
    if (data) {
      const parsedData = JSON.parse(data);
      setBusinessLogo(parsedData.url);
    } else if (!data) {
      getBusinessLogo(); // Call the function to retrieve logo from Firebas
    }
  };

  useEffect(() => {
    if (!businessLogo) getBusinessLogo();
    // retrieveDataFromCookies();
  }, [businessLogo]);

  const getBusinessLogo = async () => {
    if (window.location.href.includes("localhost")) {
      const getLogo = await getSingle('business',"client",user?.client)
      if(getLogo[0]?.businessLogo){
        setBusinessLogo(
          getLogo[0]?.businessLogo
        );
      } else if(!getLogo[0]?.businessLogo){
        setBusinessLogo(
          "https://assets-global.website-files.com/659ce509ee317d064a34ba2b/659ce6008338a87f65681d88_1.png"
        );
      }
      
    } else if (window.location.href.includes("seogrowth.com.au")) {
      setBusinessLogo(
        "https://uploads-ssl.webflow.com/64c8c74f25075f4f57dfb033/64c8d3f2cec0d08ba3722081_seogrowth-no-background.png"
      );
    }
    else if (
      window.location.href.includes("outreachhippo")
    ) {
      setBusinessLogo(
        "https://assets-global.website-files.com/659ce509ee317d064a34ba2b/659ce6008338a87f65681d88_1.png"
      );
     } else if (
      !window.location.href.includes("seogrowth.com.au") ||
      !window.location.href.includes("localhost")
    ) {
      const logo = await getAll("business");
      const modifiedURL = window.location.href.replace(
        /^(https?:\/\/[^/]+)(\/.*)?$/,
        "$1"
      );
      if (logo) {
        const obj = logo[0];
        for (const key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const value = obj[key];
            if (
              typeof value === "string" &&
              value.includes(modifiedURL)
            ) {
              console.log(obj.businessLogo);
              setBusinessLogo(obj.businessLogo);
            }
          }
        }
      } else if (!logo) {
        setBusinessLogo(MainLogo);
      }
    }
  };
  const [loading, setLoading] = useState(true);
  const [businessName, setBusinessName] = useState("");
  const urlID = window.location.pathname;
  const lastPart = urlID.split("/").pop();
  useEffect(() => {
    getBusinessDetails();
  }, [urlID]);
  function getDemoId(url) {
    const regex = /\/demo\/([^/]+)/;
    const match = url.match(regex);

    if (match && match.length >= 2) {
      return match[1];
    }

    return null;
  }

  const demoId = getDemoId(urlID);
  const getBusinessDetails = async () => {
    const businessDetails = await getSingle("business", "demoid", demoId);
    if (businessDetails[0]?.businessName) {
      setBusinessName(businessDetails[0]?.businessName);
      document.title = `Demo | ${businessDetails[0]?.businessName}`;
      setLoading(false);
    } else {
      setBusinessName("SEO Growth");
      setLoading(false);
    }
  };

  // OR using local (public folder)
  // -------------------------------------------------------
  const logo = (
    <Box
      component="img"
      src={businessLogo}
      sx={{
        maxWidth: "80%",
        maxHeight: "80%",
        marginTop: "10px",
        cursor: "pointer",
        ...sx,
      }}
    />
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link
      component={RouterLink}
      // href="https://www.seogrowth.com.au/"
      sx={{ display: "contents" }}
    >
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
