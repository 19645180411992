import PropTypes from "prop-types";
import { useTheme, alpha } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Helmet } from "react-helmet";

// components
import Image from "src/components/image";
import { MotionViewport, varFade } from "src/components/animate";
import { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {
  getSingle,
  getVideoLength,
  updateSingle,
  updateTimeWatched,
} from "src/auth/context/firebase/auth-provider";
import { CircularProgress } from "@material-ui/core";
import { useAuthContext } from "src/auth/hooks";
import OverviewWebsiteAuditView from "./website-audit";

// ----------------------------------------------------------------------

export default function DemoVideo() {
  const theme = useTheme();
  const videoUrl = window.location.href;
  const { user } = useAuthContext();

  const [showVideo, setShowVideo] = useState(false);
  const [totalVideoTime, setTotalVideoTime] = useState();
  const [businessProfilePhoto, setBusinessProfilePhoto] = useState();
  const [businessName, setBusinessName] = useState();
  const [businessContact, setBusinessContact] = useState();
  const [businessContactTitle, setBusinessContactTitle] = useState();
  const [calendarLink, setCalendarLink] = useState();
  const urlID = window.location.pathname;
  const lastPart = urlID?.split("/").pop()?.toLowerCase().replace("%20", "-");
  const [leadName, setLeadName] = useState();
  const [video, setVideo] = useState();
  const [leadScreenshot, setLeadScreenshot] = useState();
  const [leadWebsite, setLeadWebsite] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  useEffect(() => {
    if (lastPart) getLeadDetails();
    if (user) {
      console.log(user);
    }
  }, [urlID, lastPart, businessContact]);
  const getLeadDetails = async () => {
    setLoading(true);
    const leadDetails = await getSingle("leads", "leadID", lastPart);
    const modifiedURL = window.location.href.replace(
      /^(https?:\/\/)?([^/]+)(\/.*)?$/,
      "$2"
    );
    if (leadDetails[0]) {
      setLeadWebsite(leadDetails[0].leadWebsite);
      const business = await getSingle(
        "business",
        "client",
        leadDetails[0].client
      );
      if (
        modifiedURL === business[0].customDomain ||
        modifiedURL === "localhost:3000"
      ) {
        const videos = await getSingle(
          "video",
          "client",
          leadDetails[0].client
        );
        if (videos) {
          setBusinessContact(business[0].businessContact);
          setBusinessContactTitle(business[0].businessContactTitle);
          setCalendarLink(business[0].calendarLink);
          setBusinessProfilePhoto(business[0].profilephoto);
          setLeadName(leadDetails[0].leadName);
          setLeadScreenshot(leadDetails[0].screenshot);
          setVideo(videos[0].url);
          // const videoLength = await getVideoLength(videos[0].url);
          document.title = `Audit | ${leadDetails[0].leadName}`;
          setLoading(false);
        }
      } else {
        consoel.log("CANT FIND VIDEO");
        setError(true);
        setLoading(true);
      }
    } else {
      console.log("CANT FIND LEAD");
      setError(true);
      setLoading(true);
    }
  };

  const videoRef = useRef(null);

  const [playStartTime, setPlayStartTime] = useState();
  const [playEndTime, setPlayEndTime] = useState();

  const savePlayed = async () => {
    await updateTimeWatched("leads", "leadID", lastPart, {
      played: true,
      date: new Date(),
    });
  };

  useEffect(() => {
    if (totalVideoTime) {
      // console.log(totalVideoTime, 'total');
      // updateSingle('leads', 'leadid', lastPart, { totalvideotime: totalVideoTime });
    }
  }, [totalVideoTime]);

  const handleStart = () => {
    setPlayStartTime(videoRef.current.getCurrentTime());
  };
  const [isPlaying, setIsPlaying] = useState(false);

  const handleVideoClick = async () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
        setIsPlaying(false);
      } else {
        videoRef.current.play();
        // setScrolling(true);
        // setTimeout(() => {
        //   setScrollDistance(500);
        // }, 5000);

        // setTimeout(() => {
        //   setScrollDistance(500);
        // }, 10000);

        // setTimeout(() => {
        //   setScrollDistance(250);
        // }, 15000);

        // setTimeout(() => {
        //   setScrollDistance(1500);
        // }, 20000);

        // setTimeout(() => {
        //   setScrollDistance(0);
        // }, 25000);
      }
      setIsPlaying(!isPlaying);
    }
  };
  const handleVideoPlay = () => {
    savePlayed();
  };
  const imageOpacity = isPlaying ? "100%" : "70%";

  const [scrolling, setScrolling] = useState(false);
  const [scrollDistance, setScrollDistance] = useState(0);

  const renderVideo = (
    <button
      data-render-video
      type="button"
      onClick={handleVideoClick}
      id="video-container"
      style={{
        position: "relative",
        border: "none", // Remove border
        padding: 0, // Remove padding
        background: "none", // Remove background
        cursor: "pointer",
        outline: "none",
      }}
    >
      <div
        style={{
          height: "720px", // Height of the visible portion
          overflow: "hidden",
          position: "relative",
        }}
      >
        <img
          className={`scrolling-image ${scrolling ? "scrolling" : ""}`}
          style={{
            borderRadius: "15px",
            opacity: imageOpacity,
            width: "auto",
            transition: "transform 2s linear",
            transform: scrolling
              ? `translateY(-${scrollDistance}px)`
              : "translateY(0)",
          }}
          src={leadScreenshot}
          // src="https://firebasestorage.googleapis.com/v0/b/video-outreach-773a2.appspot.com/o/screenshots%2Fscreenshot_1706060429453.png?alt=media"
          id="video-image"
          alt="leadScreenshotimage"
        />
      </div>
      <video
        ref={videoRef}
        onPlay={handleVideoPlay}
        id="overlay-video"
        autoPlay
        style={{
          opacity: imageOpacity,
          position: "absolute",
          bottom: "1rem",
          right: "1rem",
          width: "100px",
          height: "100px",
          borderRadius: "50%",
          objectFit: "cover",
        }}
        onClick={(e) => e.stopPropagation()} // Stop click event from propagating
      >
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
        <track kind="captions" srcLang="en" label="English captions" />
      </video>

      {isPlaying ? null : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="84"
          height="84"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1,
          }}
        >
          <circle cx="12" cy="12" r="10" />
          <polygon points="10 8 16 12 10 16 10 8" />
        </svg>
      )}
    </button>
  );

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleCalendlyClick = () => {
    window.Calendly.initPopupWidget({
      url: calendarLink,
    });
  };

  const handleEmailClick = () => {
    window.location.href = "mailto:tom@seogrowth.com.au";
  };

  return (
    <>
      <Helmet>
        <meta property="og:title" content={`Video for ${leadName}`} />
        <meta property="og:image" content={leadScreenshot} />
        <meta
          property="og:image:alt"
          content={`Preview image for ${leadName}`}
        />
        <meta property="og:url" content={videoUrl} />
        <meta
          property="og:description"
          content={`Watch the video for ${leadName}`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Video for ${leadName}`} />
        <meta
          name="twitter:description"
          content={`Watch the video for ${leadName}`}
        />
        <meta name="twitter:image" content={leadScreenshot} />
      </Helmet>
      <Box
        style={{ width: "100vw" }}
        sx={{
          maxWidth: "100vw",
          // pt: 55,
          pt: { xs: 0, lg: 55 },
          p: { xs: 0, lg: 10 },
          pb: { xs: 0, lg: 10 },
          // backgroundImage: 'url(/assets/background/overlay_4.jpg)',
          // pb: 10,
          "&:before": {
            width: 1,
            height: 1,
            zIndex: -1,
            content: "''",
            opacity: 0.24,
            backgroundImage: "url(/assets/background/overlay_4.jpg)",
          },
        }}
      >
        {error && (
          <Typography variant="h4" style={{ textAlign: "center" }}>
            Please check your URL
          </Typography>
        )}
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {!error && (
              <Typography variant="h4" style={{ textAlign: "left" }}>
                Hey {leadName?.split(" ")[0]},
              </Typography>
            )}
            &nbsp; &nbsp;
            <Grid container spacing={3} style={{ alignItems: "center" }}>
              {!error ? (
                <Grid item xs={12} md={12}>
                  {renderVideo}
                </Grid>
              ) : (
                <p>Unable to load. Please check the URL {error}</p>
              )}
              <div
                style={{
                  width: "100vw",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  textAlign: "center",
                  verticalAlign: "center",
                }}
              >
                <div style={{ width: "15%" }}>
                  <img
                    style={{
                      borderRadius: "80px",
                      width: "80px",
                      height: "80px",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                      border: "8px solid white",
                      boxSizing: "content-box",
                    }}
                    src={businessProfilePhoto}
                    alt="profile"
                  />
                </div>
                <div
                  style={{
                    width: "15%",
                    alignItems: "center",
                    textAlign: "center",
                    verticalAlign: "center",
                  }}
                >
                  <Typography variant="h6">{businessContact}</Typography>
                  <Typography variant="body1">
                    {businessContactTitle}
                  </Typography>
                </div>
                <div>
                  <link
                    href="https://assets.calendly.com/assets/external/widget.css"
                    rel="stylesheet"
                  />
                  <script
                    src="https://assets.calendly.com/assets/external/widget.js"
                    type="text/javascript"
                    async
                  />
                  <Button variant="contained" onClick={handleCalendlyClick}>
                    Schedule a time
                  </Button>
                </div>
              </div>
            </Grid>
          </>
        )}
        {leadWebsite && businessContact === "Tom" && (
          <OverviewWebsiteAuditView url={leadWebsite} />
        )}
      </Box>
    </>
  );
}
