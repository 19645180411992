import momentBusinessDays from "moment-business-days";
import {
  getMultiple,
  getSingleDetailed,
  getSingle,
  updateSingleDetailed,
} from "src/auth/context/firebase/auth-provider";

const getEmailTemplate = async (currentUser) => {
  const emailtemplate = await getSingleDetailed(
    "emailtemplate",
    "client",
    currentUser?.client,
    "campaignFlowStep",
    "Initial Email"
  );
  const flow = await getSingle("flows", "client", currentUser?.client);
  var allFollowUpEmails = [];
  if (flow) {
    for (let i = 0; i < flow[0].flow.length; i++) {
      const singleFlow = flow[0].flow[i];
      if (singleFlow !== "Initial Email") {
        const getFollowUpEmail = await getSingleDetailed(
          "emailtemplate",
          "client",
          currentUser?.client,
          "campaignFlowStep",
          singleFlow
        );
        allFollowUpEmails.push(getFollowUpEmail[0]);
      }
    }
  }

  const email = emailtemplate[0];
  const emailBody = currentUser?.personalBody || email.emailbody;
  const subjectLine = currentUser?.personalSubject || email.subject;
  const emailSignature = email.signature;
  const businessName = currentUser?.leadBusiness
    .replaceAll(/ltd/gi, "")
    .replaceAll(/pty/gi, "")
    .replaceAll(/foundation/gi, "")
    .replaceAll(/recruitment/gi, "");

  const businessnameremovepty = currentUser?.leadBusiness.replaceAll(
    /ltd/gi,
    ""
  );
  const businessShort = businessnameremovepty
    .replaceAll(/pty/gi, "")
    .replaceAll(/foundation/gi, "")
    .replaceAll(/recruitment/gi, "");

  return {
    email: email,
    followUpEmails: allFollowUpEmails,
    emailBody: emailBody,
    subjectLine: subjectLine,
    emailSignature: emailSignature,
    businessName: businessName,
    businessShort: businessShort,
  };
};

export const scheduleEmail = async (currentUser, business) => {
  if (currentUser) {
    const emailTemplate = await getEmailTemplate(currentUser);
    try {
      const type = business.type;
      const email = currentUser?.leadEmail;
      const name = currentUser?.leadName;
      const senderEmail = business.email;
      const businessURL = business.businessURL;
      const businessContact = business.businessContact;
      const followUpEmail = emailTemplate.followUpEmails;
      const emailNumber = business.emailNumber;
      console.log(
        type,
        email,
        name,
        senderEmail,
        businessURL,
        businessContact,
        followUpEmail,
        emailNumber
      );
      const newSubject = emailTemplate.subjectLine
        .replace(/{{business_name}}/g, emailTemplate.businessShort)
        .replace(/{{first_name}}/, name);
      const messageText = `

                  ${emailTemplate.emailBody
                    .replace(/{{business_name}}/g, emailTemplate.businessShort)
                    .replace(/{{first_name}}/, name)
                    .replace(
                      /{{screenshot}}/,
                      `  <a href="${businessURL}/video/${currentUser.leadID}"><img alt="${currentUser.leadBusiness}" src="${currentUser.preview}" width="500"></img></a><br><a href="${businessURL}/video/${currentUser.leadID}">${businessURL}/video/${currentUser?.leadID}</a>`
                    )
                    .replace(
                      /{{just_screenshot}}/,
                      `<img alt="${currentUser.leadBusiness}" src="${currentUser.preview}" width="500"></img>`
                    )}

                    <br>
                 ${emailTemplate.emailSignature}
              `;
      const rm =
        `From: ${businessContact} <${senderEmail}>\r\n` +
        `To: \"${name}\" <${email}>\r\n` +
        `Subject: ${newSubject}\r\n` +
        "Content-Type: text/html; charset=utf-8\r\n\r\n" +
        "<html>" +
        "<body>" +
        `${messageText}` +
        "</body>" +
        "</html>";
      var newFollowUpEmailCollection = [];

      followUpEmail.forEach((email) => {
        const messageTextFollowUp = `

          ${email.emailbody
            .replace(/{{business_name}}/g, emailTemplate.businessShort)
            .replace(/{{first_name}}/, name)}

          <br>
          <br>
          ${email.signature}
            <br>
      `;
        const rmFollowUpBody =
          "<html>" +
          "<body>" +
          `${messageTextFollowUp}` +
          "</body>" +
          "</html>";

        newFollowUpEmailCollection.push({
          email: rmFollowUpBody,
          emailed: false,
          followUpDate: null,
          subject: newSubject,
        });
      });

      const data = {
        account: type,
        emailFrom: senderEmail,
        body: messageText,
        step: "Follow Up 1",
        followUp: newFollowUpEmailCollection,
        subject: newSubject,
        scheduled: true,
        dateScheduledApproved: new Date(),
      };

      await updateSingleDetailed(
        "leads",
        "leadID",
        currentUser.leadID,
        data,
        "client",
        currentUser?.client
      );
      await updateSingleDetailed(
        "emailaccounts",
        "client",
        currentUser?.client,
        {
          emails: emailNumber + 1,
        },
        "email",
        senderEmail
      );
      return "Complete";
    } catch (error) {
      console.error("Error:", error);
    }
  }
};
