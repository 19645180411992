import { Helmet } from "react-helmet-async";
import EmailEditView from "src/sections/email/email-edit-view";

// ----------------------------------------------------------------------

export default function EmailEditPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Email Edit</title>
      </Helmet>

      <EmailEditView />
    </>
  );
}
