import PropTypes from "prop-types";
import * as Yup from "yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";

import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MenuItem from "@mui/material/MenuItem";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useSnackbar } from "src/components/snackbar";
import momentBusinessDays from "moment-business-days";

import {
  deleteSingle,
  getAll,
  getAllRealtime,
  getDocumentDocID,
  getMultiple,
  getSingle,
  getSingleDetailed,
  updateAllSingle,
  updateSingle,
  updateSingleByDocID,
  updateSingleDetailed,
} from "src/auth/context/firebase/auth-provider";
import {
  Card,
  CircularProgress,
  LinearProgress,
  Select,
  TextField,
} from "@mui/material";
import { sendEmailFromGmail } from "./view/gmail-api";
import moment from "moment/moment";
import { sendEmailFromOutlook } from "./view/outlook-api";
import { useAuthContext } from "src/auth/hooks";

// ----------------------------------------------------------------------

export default function LeadViewForm({
  currentUser,
  open,
  onClose,
  getLead,
  campaignID,
  onboarding,
}) {
  const { user } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .required("Email is required")
      .email("Email must be a valid email address"),
    company: Yup.string().required("Company is required"),
    status: Yup.string().required("Company is required"),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentUser?.name || "",
      email: currentUser?.email || "",
      status: currentUser?.status,
      company: currentUser?.company || "",
      id: currentUser?.id || "",
    }),

    [currentUser]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const [businessShort, setBusinessShort] = useState();

  const getFirstName = (inputString) => inputString.split(" ")[0];
  const newSubjectLine = `Propel Your Sales in 2024 with Tailored SEO & Google Ads Strategies`;
  // const newMessage = `I hope you're well. As the year is wrapping up, I want to email to say we're here if you need any help in SEO or Google Ads. We are a sales-based service, so our goal is to drive more sales to ${currentUser?.leadBusiness}. I've created a quick video for you to hopefully understand more about how we can bring growth in 2024.`
  const newMessage = `I hope this email finds you thriving as the year draws to a close. I understand that in the fast-paced world of business, especially at ${businessShort}, staying ahead of the curve is vital.<br><br>This is where our expertise in SEO and Google Ads can be a game-changer for you. We don't just focus on traffic; our core aim is to drive meaningful sales growth for ${businessShort}.<br><br>To give you a clear idea of our approach, I've created a short, custom video. This isn’t just another automated message - it's a personalised insight into how we can fuel growth for ${businessShort} in 2024.<br><br>`;
  const [subjectLine, setSubjectLine] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [emailSignature, setEmailSignature] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState(0);
  const [email, setEmail] = useState(0);
  const [body, setBody] = useState();
  const [personal, setPersonal] = useState();
  const [followUpEmail, setFollowUpEmails] = useState();

  const getEmailTemplate = async () => {
    const emailtemplate = await getSingleDetailed(
      "emailtemplate",
      "client",
      currentUser?.client,
      "campaignFlowStep",
      "Initial Email"
    );
    const flow = await getSingle("flows", "client", currentUser?.client);
    var allFollowUpEmails = [];
    if (flow) {
      for (let i = 0; i < flow[0].flow.length; i++) {
        const singleFlow = flow[0].flow[i];
        if (singleFlow !== "Initial Email") {
          const getFollowUpEmail = await getSingleDetailed(
            "emailtemplate",
            "client",
            currentUser?.client,
            "campaignFlowStep",
            singleFlow
          );
          allFollowUpEmails.push(getFollowUpEmail[0]);
        }
      }
      setFollowUpEmails(allFollowUpEmails);

      const email = emailtemplate[0];
      if (currentUser?.personalBody) {
        setEmailBody(currentUser.personalBody);
      } else {
        setEmailBody(email.emailbody);
      }
      if (currentUser?.personalSubject) {
        setSubjectLine(currentUser.personalSubject);
      } else {
        setSubjectLine(email.subject);
      }

      setEmailSignature(email.signature);
      setPersonal(newMessage);
      const businessnameremovepty = currentUser?.leadBusiness.replaceAll(
        /ltd/gi,
        ""
      );
      const result = businessnameremovepty
        .replaceAll(/pty/gi, "")
        .replaceAll(/foundation/gi, "")
        .replaceAll(/recruitment/gi, "");
      setBusinessShort(result);
    }
  };

  useEffect(() => {
    if (open) {
      getEmailTemplate();
    }
    setName(getFirstName(currentUser.leadName));
  }, [name, open, businessShort, personal]);

  // ACTUALL LEAD
  const params = `to=${currentUser.leadEmail}&from=tom@agencyseogrowth.com&subject=Quick Question For ${name}&name=${name}&business=${currentUser.leadBusiness}&leadID=${currentUser.leadID}&preview=${currentUser.preview}&number=${number}&email=${email}&personal=${personal}`;
  const [unsubscribe, setUnsubscribe] = useState(null); // Add a state for unsubscribe

  const collectionName = "content";
  const documentId = "z90dTSl6x4fJ0JPzQ0Ps";
  async function fetchData() {
    setName(getFirstName(currentUser.leadName));
    const data = await getDocumentDocID(collectionName, documentId);
    setNumber(data.copy);
    setEmail(data.email);
  }

  useEffect(() => {
    fetchData();
  });
  const [businessURL, setBusinessURL] = useState();
  const [allBusinessEmails, setAllBusinessEmails] = useState();
  const [businessEmail, setBusinessEmail] = useState();
  const [emailNumber, setEmailNumber] = useState();
  // const [replyTo, setReplyTo] = useState();
  const [businessContact, setBusinessContact] = useState();
  useEffect(() => {
    if (!businessURL) getBusinessURL();
    if (!businessEmail) getBusinessEmail();
  }, [businessURL, businessEmail]);

  const getBusinessURL = async () => {
    const url = await getSingle("business", "client", currentUser?.client);
    setBusinessURL(url[0].customDomain);
    // setReplyTo(url[0].replyTo);
    setBusinessContact(url[0].businessContact);
  };

  const getBusinessEmail = async () => {
    const allEmails = await getMultiple(
      "emailaccounts",
      "client",
      currentUser?.client
    );
    if (allEmails) {
      setAllBusinessEmails(allEmails);
      // Extract the number of emails for each email account
      const emailCounts = allEmails.map((email) => email.emails);

      // Find the minimum email count
      const minEmailCount = Math.min(...emailCounts);

      // Find the first email account with the minimum email count
      const businessEmail = allEmails.find(
        (email) => email.emails === minEmailCount
      );
      setEmailNumber(businessEmail?.emails);
      setBusinessEmail(businessEmail?.email);
    }
  };

  const fixThumnail = async () => {
    setLoading(true);
    const getPreview = await axios.post(
      `https://us-central1-video-outreach-773a2.cloudfunctions.net/takePreviewScreenshot?url=https://${businessURL}/video/${currentUser.leadID}`
    );
    const newPreview = getPreview.data;
    await updateSingle("leads", "leadID", currentUser.leadID, {
      preview: newPreview,
    });
    currentUser.preview = newPreview;

    setLoading(false);
    enqueueSnackbar("Thumnbail Updated");
  };

  const fixWebsite = async () => {
    setLoading(true);

    try {
      const getScreenshot = axios.post(
        `https://us-central1-video-outreach-773a2.cloudfunctions.net/takeScreenshot?url=${currentUser.leadWebsite}`
      );

      const getPreview = axios.post(
        `https://us-central1-video-outreach-773a2.cloudfunctions.net/takePreviewScreenshot?url=https://${businessURL}/video/${currentUser.leadID}`
      );

      const [screenshotResponse, previewResponse] = await Promise.all([
        getScreenshot,
        getPreview,
      ]);

      const newScreenshot = screenshotResponse.data;
      const newPreview = previewResponse.data;

      await updateSingle("leads", "leadID", currentUser.leadID, {
        preview: newPreview,
        screenshot: newScreenshot,
      });

      // Update currentUser.preview after the updateSingle operation
      currentUser.preview = newPreview;

      setLoading(false);
      enqueueSnackbar("Website Updated");
      // onClose();
    } catch (error) {
      // Handle errors here
      console.error("Error updating website:", error);
      setLoading(false);
      enqueueSnackbar("Website Update Failed", { variant: "error" });
    }
  };

  const sendEmail = async (scheduled) => {
    console.log(scheduled, "scheduled");
    if (currentUser) {
      setLoading(true);
      try {
        if (onboarding) {
          updateSingle("users", "email", currentUser?.email, {
            onboarding: "complete",
            onboardingComplete: true,
          });
        }

        const type = await getSingle("emailaccounts", "email", businessEmail);
        const email = currentUser?.leadEmail;
        const name = currentUser?.leadName;
        const senderEmail = businessEmail;
        const newSubject = subjectLine
          .replace(/{{business_name}}/g, businessShort)
          .replace(/{{first_name}}/, name);
        const messageText = `

                  ${emailBody
                    .replace(/{{business_name}}/g, businessShort)
                    .replace(/{{first_name}}/, name)
                    .replace(
                      /{{screenshot}}/,
                      `  <a href="${businessURL}/video/${currentUser.leadID}"><img alt="${currentUser.leadBusiness}" src="${currentUser.preview}" width="500"></img></a><br><a href="${businessURL}/video/${currentUser.leadID}">${businessURL}/video/${currentUser?.leadID}</a>`
                    )
                    .replace(
                      /{{just_screenshot}}/,
                      `<img alt="${currentUser.leadBusiness}" src="${currentUser.preview}" width="500"></img>`
                    )}
                
                    <br>
                 ${emailSignature}
              `;
        const rm =
          `From: ${businessContact} <${senderEmail}>\r\n` +
          `To: \"${name}\" <${email}>\r\n` +
          `Subject: ${newSubject}\r\n` +
          "Content-Type: text/html; charset=utf-8\r\n\r\n" +
          "<html>" +
          "<body>" +
          `${messageText}` +
          "</body>" +
          "</html>";
        console.log(rm, "rm");
        var newFollowUpEmailCollection = [];
        let businessDaysToAdd = 3;

        //   followUpEmail.forEach((email) => {
        //     const messageTextFollowUp = `

        //     ${email.emailbody
        //       .replace(/{{business_name}}/g, businessShort)
        //       .replace(/{{first_name}}/, name)}

        //     <br>
        //     <br>
        //     ${email.signature}
        //       <br>
        // `;
        //     const rmFollowUpBody =
        //       "<html>" +
        //       "<body>" +
        //       `${messageTextFollowUp}` +
        //       "</body>" +
        //       "</html>";
        //     const date = new Date();
        //     const formattedDate = moment(date).format("DD-MM-YYYY HH:SS");
        //     const followUpDate = momentBusinessDays(
        //       formattedDate,
        //       "DD-MM-YYYY HH:SS"
        //     ).businessAdd(businessDaysToAdd)._d;
        //     businessDaysToAdd += 3;

        //     newFollowUpEmailCollection.push({
        //       email: rmFollowUpBody,
        //       emailed: false,
        //       followUpDate: scheduled ? null : followUpDate,
        //       subject: newSubject,
        //     });
        //   });
        //   const outlookEmail = {
        //     message: {
        //       subject: `${subjectLine
        //         .replace(/{{business_name}}/g, businessShort)
        //         .replace(/{{first_name}}/, name)}`,
        //       body: {
        //         contentType: "HTML", // Specify HTML as the content type
        //         content: `<html><body>${messageText}</body></html>`,
        //       },
        //       toRecipients: [
        //         {
        //           emailAddress: {
        //             name: name,
        //             address: email,
        //           },
        //         },
        //       ],
        //     },
        //   };

        //   const url =
        //     "https://www.googleapis.com/gmail/v1/users/me/messages/send";

        //   const rawMessage = btoa(unescape(encodeURIComponent(rm)));
        //   try {
        //     if (newFollowUpEmailCollection) {
        //       console.log(
        //         newFollowUpEmailCollection,
        //         "newFollowUpEmailCollection"
        //       );
        //       if (type[0].type === "outlook") {
        //         console.log("SEND OUTLOOK");
        //         if (scheduled) {
        //           await updateSingleDetailed(
        //             "leads",
        //             "leadID",
        //             currentUser.leadID,
        //             {
        //               account: "outlook",
        //               emailFrom: businessEmail,
        //               dateEmailed: new Date(),
        //               body: messageText,
        //               step: "Follow Up 1",
        //               followUp: newFollowUpEmailCollection,
        //               subject: newSubject,
        //               scheduled: true,
        //               dateScheduledApproved: new Date(),
        //             },
        //             "client",
        //             currentUser?.client
        //           );
        //           await updateSingleDetailed(
        //             "emailaccounts",
        //             "client",
        //             currentUser?.client,
        //             {
        //               emails: emailNumber + 1,
        //             },
        //             "email",
        //             businessEmail
        //           );
        //           fetchData();
        //           getBusinessEmail();
        //           setLoading(false);
        //           fetchData();
        //           getBusinessEmail();
        //           enqueueSnackbar("Email Sent");
        //           fetchData();
        //           onClose();
        //         } else {
        //           const outlookEmailResult = await sendEmailFromOutlook(
        //             outlookEmail,
        //             businessEmail,
        //             subjectLine
        //               .replace(/{{business_name}}/g, businessShort)
        //               .replace(/{{first_name}}/, name),
        //             email
        //           );
        //           if (outlookEmailResult) {
        //             console.log(outlookEmailResult, "sendEmailResult");
        //             await updateSingleDetailed(
        //               "leads",
        //               "leadID",
        //               currentUser.leadID,
        //               {
        //                 messageId: outlookEmailResult.conversationID,
        //                 emailed: "true",
        //                 account: "outlook",
        //                 emailFrom: businessEmail,
        //                 dateEmailed: new Date(),
        //                 body: messageText,
        //                 step: "Follow Up 1",
        //                 followUp: newFollowUpEmailCollection,
        //                 subject: newSubject,
        //               },
        //               "client",
        //               currentUser?.client
        //             );
        //             await updateSingleDetailed(
        //               "emailaccounts",
        //               "client",
        //               currentUser?.client,
        //               {
        //                 emails: emailNumber + 1,
        //               },
        //               "email",
        //               businessEmail
        //             );
        //             fetchData();
        //             getBusinessEmail();
        //             setLoading(false);
        //             fetchData();
        //             getBusinessEmail();
        //             enqueueSnackbar("Email Scheduled");
        //             fetchData();
        //             onClose();
        //           }
        //         }
        //       } else if (type[0].type === "gmail") {
        //         console.log("GMAIL");
        //         if (scheduled) {
        //           console.log(currentUser.leadID, "SCHEUDLED");
        //           await updateSingleDetailed(
        //             "leads",
        //             "leadID",
        //             currentUser.leadID,
        //             {
        //               account: "gmail",
        //               emailFrom: businessEmail,
        //               dateEmailed: new Date(),
        //               body: messageText,
        //               step: "Follow Up 1",
        //               followUp: newFollowUpEmailCollection,
        //               subject: newSubject,
        //               scheduled: true,
        //               dateScheduledApproved: new Date(),
        //             },
        //             "client",
        //             currentUser?.client
        //           );
        //           await updateSingleDetailed(
        //             "emailaccounts",
        //             "client",
        //             currentUser?.client,
        //             {
        //               emails: emailNumber + 1,
        //             },
        //             "email",
        //             businessEmail
        //           );
        //           fetchData();
        //           getBusinessEmail();
        //           setLoading(false);
        //           fetchData();
        //           getBusinessEmail();
        //           enqueueSnackbar("Email Scheduled");
        //           fetchData();
        //           onClose();
        //         } else {
        //           const sendEmailResult = await sendEmailFromGmail(
        //             rawMessage,
        //             businessEmail,
        //             email,
        //             subjectLine
        //               .replace(/{{business_name}}/g, businessShort)
        //               .replace(/{{first_name}}/, name),
        //             messageText
        //           );
        //           console.log(sendEmailResult, "sendEmailResult");
        //           if (sendEmailResult) {
        //             await updateSingleDetailed(
        //               "leads",
        //               "leadID",
        //               currentUser.leadID,
        //               {
        //                 messageId: sendEmailResult.messageID
        //                   ? sendEmailResult.messageID
        //                   : "",
        //                 emailed: "true",
        //                 account: "gmail",
        //                 emailFrom: businessEmail,
        //                 dateEmailed: new Date(),
        //                 body: messageText,
        //                 step: "Follow Up 1",
        //                 followUp: newFollowUpEmailCollection,
        //                 subject: newSubject,
        //               },
        //               "client",
        //               currentUser?.client
        //             );
        //             await updateSingleDetailed(
        //               "emailaccounts",
        //               "client",
        //               currentUser?.client,
        //               {
        //                 emails: emailNumber + 1,
        //               },
        //               "email",
        //               businessEmail
        //             );
        //             fetchData();
        //             getBusinessEmail();
        //             setLoading(false);
        //             fetchData();
        //             getBusinessEmail();
        //             enqueueSnackbar("Email Sent");
        //             fetchData();
        //             onClose();
        //           }
        //         }
        //       } else if (type[0].type === "mailbox") {
        //         console.log("MAILBOX");
        //         if (scheduled) {
        //           await updateSingleDetailed(
        //             "leads",
        //             "leadID",
        //             currentUser.leadID,
        //             {
        //               account: "mailbox",
        //               emailFrom: businessEmail,
        //               dateEmailed: new Date(),
        //               body: messageText,
        //               step: "Follow Up 1",
        //               followUp: newFollowUpEmailCollection,
        //               subject: newSubject,
        //               scheduled: true,
        //               dateScheduledApproved: new Date(),
        //             },
        //             "client",
        //             currentUser?.client
        //           );
        //           await updateSingleDetailed(
        //             "emailaccounts",
        //             "client",
        //             currentUser?.client,
        //             {
        //               emails: emailNumber + 1,
        //             },
        //             "email",
        //             businessEmail
        //           );
        //           fetchData();
        //           getBusinessEmail();
        //           setLoading(false);
        //           fetchData();
        //           getBusinessEmail();
        //           enqueueSnackbar("Email Scheduled");
        //           fetchData();
        //           onClose();
        //         } else {
        //           // const url =
        //           //   "http://127.0.0.1:5001/video-outreach-773a2/us-central1/checkEmailRepliesMailbox";
        //           // const sendInboxURL =
        //           //   "http://127.0.0.1:5001/video-outreach-773a2/us-central1/sendMailboxEmail";
        //           const url =
        //             "https://us-central1-video-outreach-773a2.cloudfunctions.net/checkEmailRepliesMailbox";
        //           const sendInboxURL =
        //             "https://us-central1-video-outreach-773a2.cloudfunctions.net/sendMailboxEmail";
        //           const formData = type[0];
        //           const sendInbox = await axios.post(sendInboxURL, {
        //             formData: formData,
        //             to: email,
        //             subject: subjectLine
        //               .replace(/{{business_name}}/g, businessShort)
        //               .replace(/{{first_name}}/, name),
        //             html: messageText,
        //           });
        //           if (sendInbox.data) {
        //             await updateSingleDetailed(
        //               "leads",
        //               "leadID",
        //               currentUser.leadID,
        //               {
        //                 emailed: "true",
        //                 account: "mailbox",
        //                 emailFrom: businessEmail,
        //                 messageId: sendInbox.data.messageId,
        //                 dateEmailed: new Date(),
        //                 body: messageText,
        //                 step: "Follow Up 1",
        //                 followUp: newFollowUpEmailCollection,
        //                 subject: newSubject,
        //               },
        //               "client",
        //               currentUser?.client
        //             );
        //             await updateSingleDetailed(
        //               "emailaccounts",
        //               "client",
        //               currentUser?.client,
        //               {
        //                 emails: emailNumber + 1,
        //               },
        //               "email",
        //               businessEmail
        //             );
        //             fetchData();
        //             getBusinessEmail();
        //             setLoading(false);
        //             fetchData();
        //             getBusinessEmail();
        //             enqueueSnackbar("Email Sent");
        //             fetchData();
        //             onClose();
        //           }
        //         }
        //       }
        //     }
        //   } catch (error) {
        //     console.error("Error sending email:", error);
        //   }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const scheduleEmail = async () => {
    try {
      const type = await getSingle("emailaccounts", "email", businessEmail);

      const email = currentUser?.leadEmail;
      const name = currentUser?.leadName;
      const senderEmail = businessEmail;
      const newSubject = subjectLine
        .replace(/{{business_name}}/g, businessShort)
        .replace(/{{first_name}}/, name);
      const messageText = `

                ${emailBody
                  .replace(/{{business_name}}/g, businessShort)
                  .replace(/{{first_name}}/, name)
                  .replace(
                    /{{screenshot}}/,
                    `  <a href="${businessURL}/video/${currentUser.leadID}"><img alt="${currentUser.leadBusiness}" src="${currentUser.preview}" width="500"></img></a><br><a href="${businessURL}/video/${currentUser.leadID}">${businessURL}/video/${currentUser?.leadID}</a>`
                  )
                  .replace(
                    /{{just_screenshot}}/,
                    `<img alt="${currentUser.leadBusiness}" src="${currentUser.preview}" width="500"></img>`
                  )}
              
                  <br>
               ${emailSignature}
            `;
      const rm =
        `From: ${businessContact} <${senderEmail}>\r\n` +
        `To: \"${name}\" <${email}>\r\n` +
        // `To: \"${name}\" <hello@seogrowth.io>\r\n` +
        `Subject: ${newSubject}\r\n` +
        "Content-Type: text/html; charset=utf-8\r\n\r\n" +
        "<html>" +
        "<body>" +
        `${messageText}` +
        "</body>" +
        "</html>";
      var newFollowUpEmailCollection = [];

      followUpEmail.forEach((email) => {
        const messageTextFollowUp = `

          ${email.emailbody
            .replace(/{{business_name}}/g, businessShort)
            .replace(/{{first_name}}/, name)}

          <br>
          <br>
          ${email.signature}
            <br>
      `;
        const rmFollowUpBody =
          "<html>" +
          "<body>" +
          `${messageTextFollowUp}` +
          "</body>" +
          "</html>";

        newFollowUpEmailCollection.push({
          email: rmFollowUpBody,
          emailed: false,
          subject: newSubject,
        });
      });
      const schedule = await updateSingleDetailed(
        "leads",
        "leadID",
        currentUser.leadID,
        {
          body: messageText,
          bodyHTML: rm,
          scheduled: true,
          dateScheduledApproved: new Date(),
          emailFrom: businessEmail,
        },
        "client",
        currentUser?.client
      );
      fetchData();
      getBusinessEmail();
      setLoading(false);
      fetchData();
      getBusinessEmail();
      enqueueSnackbar("Email Scheduled");
      fetchData();
      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  const handleEmailChange = (event) => {
    setBusinessEmail(event.target.value);
    console.log(event.target.value, "event.target.value");
  };

  const removeLead = async () => {
    const deleteLead = await deleteSingle(
      "leads",
      currentUser?.leadEmail,
      "leadEmail",
      currentUser?.client,
      "client"
    );
    console.log(deleteLead);
    fetchData();
    onClose();
    enqueueSnackbar("Deleted", { variant: "error" });
  };

  const generateNewContent = () => {
    try {
      setLoading(true);
      axios
        .post(
          `https://us-central1-video-outreach-773a2.cloudfunctions.net/getInnerHTML?url=${currentUser.leadWebsite}`
          // `http://127.0.0.1:5001/video-outreach-773a2/us-central1/getInnerHTML?url=${currentUser.leadWebsite}`
        )
        .then((response) => {
          console.log(response.data.message, "response.data.message");
          const message =
            `Hey ${currentUser?.leadName}, ` +
            response.data.message +
            " I see a great opportunity for us to collaborate and further grow your reach." +
            "<br><br>" +
            `I've created a short video on how ${currentUser?.leadBusiness} can grow. Can I send it over?` +
            "<br><br>" +
            "{{just_screenshot}}";
          setEmailBody(message);

          const subject = response.data.subject.replaceAll('"', "");
          setSubjectLine(subject);
          setLoading(false);
        });
    } catch {}
  };
  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <Card sx={{ m: 5, p: 2, pb: 3 }}>
        {businessEmail ? (
          <Grid style={{ alignItems: "center" }} container spacing={3}>
            <Grid item xs={1}>
              <b>From:</b>
            </Grid>
            <Grid item xs={11}>
              <Select
                fullWidth
                value={businessEmail}
                onChange={handleEmailChange}
                label="Select Business Email"
              >
                {allBusinessEmails &&
                  allBusinessEmails.map((emailObj, index) => (
                    <MenuItem key={index} value={emailObj.email}>
                      {emailObj.email}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
          </Grid>
        ) : (
          <p style={{ borderRadius: "1px solid black", borderRadius: "15px" }}>
            Please add an Email Account
          </p>
        )}
        <Grid container style={{ alignItems: "center" }}>
          <Grid item xs={8}>
            <p>
              <b>To:</b> {currentUser?.leadEmail}
            </p>
          </Grid>
          {user?.premium === "infinity" && (
            <Grid
              item
              xs={4}
              style={{ alignItems: "right", textAlign: "right" }}
            >
              <Button onClick={generateNewContent} variant="contained">
                Personal Content
              </Button>
            </Grid>
          )}
        </Grid>
      </Card>

      <DialogContent>
        <Box>
          <b>
            {subjectLine
              .replace(/{{business_name}}/g, businessShort)
              .replace(/{{first_name}}/, name)}
          </b>
          <p>
            <div
              dangerouslySetInnerHTML={{
                __html: emailBody
                  .replace(/{{business_name}}/g, businessShort)
                  .replace(/{{first_name}}/, name)
                  .replace(
                    /{{screenshot}}/,
                    `  <a href="${businessURL}/video/${currentUser.leadID}"><img alt="${currentUser.leadBusiness}" src="${currentUser.preview}" width="500"></img></a><br><a href="${businessURL}/video/${currentUser.leadID}">${businessURL}/video/${currentUser?.leadID}</a>`
                  )
                  .replace(
                    /{{just_screenshot}}/,
                    `<img alt="${currentUser.leadBusiness}" src="${currentUser.preview}" width="500"></img>`
                  ),
              }}
            />
            <p>
              <div
                dangerouslySetInnerHTML={{
                  __html: emailSignature,
                }}
              />
            </p>
          </p>
        </Box>
      </DialogContent>

      {loading ? (
        <LinearProgress />
      ) : (
        <DialogActions>
          <Button variant="contained" onClick={() => removeLead()}>
            Remove Lead
          </Button>
          <Button variant="outlined" onClick={() => fixWebsite()}>
            Image Not Correct
          </Button>
          {/* <Button variant="outlined" onClick={() => fixThumnail()}>
            Thumbnail Not Correct
          </Button> */}
          {user?.premium ? (
            <Button variant="outlined" onClick={() => sendEmail()}>
              Send Email Now
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={{ ml: 2 }}
              href="https://app.outreachhippo.com/dashboard/user/account?billing"
            >
              Upgrade to Send
            </Button>
          )}
          {/* {user?.premium == "infinity" && ( */}
          <Button variant="contained" onClick={() => sendEmail(true)}>
            Approve to Send
          </Button>
          {/* )} */}
        </DialogActions>
      )}
    </Dialog>
  );
}

LeadViewForm.propTypes = {
  currentUser: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
