import isEqual from "lodash/isEqual";
import { useState, useCallback, useEffect } from "react";
import axios from "axios";
// @mui
import Papa from "papaparse";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Container from "@mui/material/Container";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import TableContainer from "@mui/material/TableContainer";
import {
  Box,
  CircularProgress,
  LinearProgress,
  TextField,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
// routes
import { paths } from "src/routes/paths";
import { useRouter } from "src/routes/hook";
import { RouterLink } from "src/routes/components";
// _mock
import { _userList, _roles, USER_STATUS_OPTIONS } from "src/_mock";
// hooks
import { useAuthContext } from "src/auth/hooks";
import { useBoolean } from "src/hooks/use-boolean";
import {
  addDocument,
  getAll,
  getMultiple,
  getSingle,
  getSingleDetailed,
  updateSingle,
  updateSingleDetailed,
  uploadJustMedia,
  uploadMedia,
} from "src/auth/context/firebase/auth-provider";
// components
import Label from "src/components/label";
import Iconify from "src/components/iconify";
import Scrollbar from "src/components/scrollbar";
import { ConfirmDialog } from "src/components/custom-dialog";
import { useSettingsContext } from "src/components/settings";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from "src/components/table";
//
import UserTableToolbar from "../user-table-toolbar";
import UserTableFiltersResult from "../user-table-filters-result";
import FormDialog from "./new-lead-dailog";
import LeadTableRow from "../lead-table-row";
import {
  AccessAlarm,
  AlternateEmail,
  BrokenImage,
  CloudUpload,
} from "@mui/icons-material";
import LeadViewForm from "../lead-view-form";
import { RoleBasedGuard } from "src/auth/guard";
import GoogleOAuthExample from "./gmail-api-agencyseogrowth";
import GoogleOAuthExampleDigitalSEOGrowth from "./gmail-api-digitalseogrowth";
import GoogleOAuthAgencySEOGrowth from "./gmail-api-agencyseogrowth";
import OnboardingModalView from "src/sections/overview/app/view/onboarding-modal-view";
import OnboardingUserModalView from "src/sections/overview/app/view/onboarding-user-view";
import { scheduleEmail, sendEmail } from "../sendEmail";
import { GridCloseIcon } from "@mui/x-data-grid";

// ----------------------------------------------------------------------

export const LEAD_STATUS_OPTIONS = [{ value: "emailed", label: "Emailed" }];

const STATUS_OPTIONS = [{ value: "all", label: "All" }, ...LEAD_STATUS_OPTIONS];

const defaultFilters = {
  name: "",
  role: [],
  status: "all",
};

// ----------------------------------------------------------------------

export default function LeadListView({ campaignID }) {
  const [openCalendar, setOpenCalendar] = useState(false);
  const TABLE_HEAD = [
    // { id: "emailed", label: "Emailed" },
    { id: "leadname", label: "Lead Name" },
    { id: "leadid", label: "Lead ID" },
    { id: "leadwebsite", label: "Lead Website" },
    { id: "" },
  ];
  const TABLE_HEAD_INFINITY = [
    // { id: "emailed", label: "Emailed" },
    { id: "leadname", label: "Lead Name" },
    { id: "leadid", label: "Lead ID" },
    { id: "leadwebsite", label: "Lead Website" },
    { id: "persona", label: "Personal" },
    { id: "" },
  ];
  const currentuser = useAuthContext();
  const { user } = useAuthContext();

  const table = useTable();

  const settings = useSettingsContext();

  const router = useRouter();

  const confirm = useBoolean();
  const uploadingCSV = useBoolean();

  const [tableData, setTableData] = useState();

  const [loadingCSV, setLoadingCSV] = useState(false);

  const [filters, setFilters] = useState(defaultFilters);
  const [totalLeads, setTotalLeads] = useState();
  const [leadsSyncing, setLeadsSyncing] = useState(false);

  const [onboarding, setOnboarding] = useState(0);
  const modalOnboarding = useBoolean();
  useEffect(() => {
    function isOnboardingInURL() {
      const urlParams = new URLSearchParams(window.location.search);
      return (
        urlParams.has("onboarding") && urlParams.get("onboarding") === "true"
      );
    }

    // Example usage:
    if (isOnboardingInURL()) {
      console.log("Onboarding is enabled in the URL.");
      modalOnboarding.onTrue();
      setOnboarding(1);
    }
  }, []);

  const nextOnboarding = () => {
    setOnboarding(2);
    var url = window.location.href;

    // Check if the URL contains the query parameter
    if (url.indexOf("?onboarding=true") !== -1) {
      // Remove the query parameter
      var updatedUrl = url.replace("?onboarding=true", "");

      // Update the URL without refreshing the page
      window.history.replaceState({}, document.title, updatedUrl);
    }
    updateSingle("users", "email", user?.email, {
      onboardingComplete: true,
      onboarding: "complete",
    });
    getBusinessURL();
    modalOnboarding.onFalse();
  };
  const getClients = useCallback(async (v, showSent) => {
    setLoading(true);
    const leads = await getMultiple(
      "leads",
      "client",
      currentuser?.user?.client
      // "leadEmail",
      // "tom+followup@seogrowth.io"
    );
    var filteredLeads;
    if (v) {
      setTotalLeads(leads.length);
      filteredLeads = leads;
    } else if (showSent) {
      filteredLeads = leads.filter((lead) => {
        // Check if "emailed" is true or a truthy string
        const isEmailed = lead.emailed === true || lead.emailed === "true";

        // If either "emailed" or "scheduled" meets the conditions, exclude the lead
        return isEmailed;
      });

      setTotalLeads(filteredLeads.length);
    } else {
      filteredLeads = leads.filter((lead) => {
        // Check if "emailed" is true or a truthy string
        const isEmailed = lead.emailed === true || lead.emailed === "true";

        // Check if "scheduled" is true or a truthy string
        const isScheduled =
          lead.scheduled === true || lead.scheduled === "true";

        // If either "emailed" or "scheduled" meets the conditions, exclude the lead
        return !isEmailed && !isScheduled;
      });

      setTotalLeads(filteredLeads.length);
    }

    // Slice `filteredLeads` and update `tableData`
    const sliceLeads = filteredLeads;
    setTableData(sliceLeads);
    setLoading(false);
  }, []);

  useEffect(() => {
    getClients();
    checkSyncingClients();
  }, [currentuser?.uid]);

  const checkSyncingClients = async () => {
    const getSyncingClients = await getSingle(
      "leadsSyncing",
      "client",
      user?.client
    );
    if (getSyncingClients[0]?.syncing) {
      setLeadsSyncing(true);
    }
  };

  var dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage =
    dataFiltered &&
    dataFiltered.slice(
      table.page * table.rowsPerPage,
      table.page * table.rowsPerPage + table.rowsPerPage
    );

  const denseHeight = table.dense ? 52 : 72;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound =
    !dataFiltered ||
    (!dataFiltered?.length && canReset) ||
    !dataFiltered?.length;

  const [searchQuery, setSearchQuery] = useState("");

  const handleFilters = useCallback(
    (name, value) => {
      table.onResetPage();
      console.log(value, "VALUE");
      setSearchQuery(value);
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleDeleteRow = useCallback(
    (id) => {
      getClients();
    },
    [getClients]
  );

  const handleDeleteRows = useCallback(() => {
    const deleteRows =
      tableData && tableData.filter((row) => !table.selected.includes(row.id));
    setTableData(deleteRows);

    const totalRows = tableData ? tableData?.length : 0;
    const totalRowsInPage = dataInPage ? dataInPage?.length : 0;
    const totalRowsFiltered = dataFiltered ? dataFiltered?.length : 0;

    table.onUpdatePageDeleteRows({
      totalRows,
      totalRowsInPage,
      totalRowsFiltered,
    });
  }, [dataFiltered, dataInPage, table, tableData]);

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.user.edit(id));
      getClients();
    },
    [router]
  );

  const handleFilterStatus = useCallback(
    (event, newValue) => {
      handleFilters("status", newValue);
    },
    [handleFilters]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
    setSearchQuery("");
  }, []);

  const handleFormSubmit = (submittedValue) => {
    if (submittedValue) {
      getClients();
    }
  };

  const handleQuickEditChange = (newValue) => {
    if (newValue === false) {
      console.log(newValue, "NEW VALUE");
      getClients();
      getClients();
      getClients();
    }
  };

  table.rowsPerPage = totalLeads;

  const [leadName, setLeadName] = useState("");
  const [leadBusiness, setLeadBusiness] = useState("");
  const [leadID, setLeadID] = useState("");
  const [leadWebsite, setLeadWebsite] = useState("");
  const [leadEmail, setLeadEmail] = useState("");
  const [subjectLine, setSubjectLine] = useState("");
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const getThumbnail = async () => {
    try {
      const getBusinessURL = await getSingle(
        "business",
        "client",
        user?.client
      );
      const url = getBusinessURL[0].customDomain;
      const response = await axios.post(
        `https://us-central1-video-outreach-773a2.cloudfunctions.net/takePreviewScreenshot?url=https://${url}/video/${leadID}`
      );
      return response.data;
    } catch (error) {
      console.error("Error taking screenshot:", error);
    }
    return null;
  };

  const getThumbnailCSV = async (csvID) => {
    try {
      const getBusinessURL = await getSingle(
        "business",
        "client",
        user?.client
      );
      const url = getBusinessURL[0].customDomain;
      const response = await axios.post(
        `https://us-central1-video-outreach-773a2.cloudfunctions.net/takePreviewScreenshot?url=https://${url}/video/${csvID}`
      );
      return response.data;
    } catch (error) {
      console.error("Error taking screenshot:", error);
    }
    return null;
  };

  const takeScreenshot = async () => {
    try {
      const response = await axios.post(
        `https://us-central1-video-outreach-773a2.cloudfunctions.net/takeScreenshot?url=${leadWebsite}`
        // `http://127.0.0.1:5001/video-outreach-773a2/us-central1/takeScreenshot?url=${leadWebsite}`
      );
      return response.data;
    } catch (error) {
      console.error("Error taking screenshot:", error);
    }
    return null;
  };

  const takeScreenshotFromCSV = async (csvWebsite) => {
    try {
      const response = await axios.post(
        `https://us-central1-video-outreach-773a2.cloudfunctions.net/takeScreenshot?url=${csvWebsite}`
        // `http://127.0.0.1:5001/video-outreach-773a2/us-central1/takeScreenshot?url=${leadWebsite}`
      );
      return response.data;
    } catch (error) {
      console.error("Error taking screenshot:", error);
    }
    return null;
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const checkNoRecord = await getSingle("leads", "leadName", leadName);
      if (!checkNoRecord[0]) {
        {
          const getScreenshot = await takeScreenshot();

          if (getScreenshot) {
            enqueueSnackbar("Create Page");
            await addDocument("leads", "", {
              leadName,
              leadBusiness,
              leadID,
              leadWebsite,
              leadEmail,
              screenshot: getScreenshot,
              date: new Date(),
            });
            enqueueSnackbar("Added");
            const thumbnail = copyImageUrlToClipboard();
            // Clear the input fields
            if (thumbnail) {
              setLeadName("");
              setLeadID("");
              setLeadBusiness("");
              setLeadWebsite("");
              setLeadEmail("");

              getClients();
            }
          }
        }
      } else {
        enqueueSnackbar("Lead Already Added!", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("Error adding lead data:", error);
    } finally {
      setLoading(false);
    }
  };

  const copyImageUrlToClipboard = async () => {
    try {
      const blobs = await getThumbnail();

      if (blobs) {
        await updateSingle("leads", "leadID", leadID, { preview: blobs });

        return blobs;
      }
    } catch (error) {
      console.error("Error copying image URL:", error);
      enqueueSnackbar("ERROR");
    }
    return null;
  };
  const copyImageUrlToClipboardCSV = async (id) => {
    try {
      const blobs = await getThumbnailCSV(id);

      if (blobs) {
        await updateSingleDetailed(
          "leads",
          "leadID",
          id,
          { preview: blobs },
          "client",
          user?.client
        );

        return blobs;
      }
    } catch (error) {
      console.error("Error copying image URL:", error);
      enqueueSnackbar("ERROR");
    }
    return null;
  };

  const getDatabase = () => {
    getClients();
  };

  const [csvFile, setCsvFile] = useState(null);
  const [totalRows, setTotalRows] = useState(0); // Initialize totalRows as a state variable

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setCsvFile(file);
  };

  // const handleUpload = async () => {
  //   if (!csvFile) {
  //     return;
  //   }

  //   setLoadingCSV(true);

  //   const reader = new FileReader();

  //   reader.onload = async (e) => {
  //     const csvData = e.target.result;

  //     try {
  //       const response = await axios.post(
  //         "http://127.0.0.1:5001/video-outreach-773a2/us-central1/addNewLeads",
  //         { csvData },
  //         { headers: { "Content-Type": "application/json" } }
  //       );

  //       console.log("CSV data processed successfully:", response.data);
  //     } catch (error) {
  //       console.error("Error uploading CSV data:", error);
  //     } finally {
  //       setLoadingCSV(false);
  //     }
  //   };

  //   reader.readAsText(csvFile);
  // };

  const handleUpload = async () => {
    if (csvFile) {
      console.log("STARTING");

      setLoadingCSV(true);
      const reader = new FileReader();

      reader.onload = async (e) => {
        const csvData = e.target.result;

        try {
          const result = await new Promise((resolve, reject) => {
            Papa.parse(csvData, {
              header: true,
              complete: (parsedResult) => resolve(parsedResult),
              error: (error) => reject(error),
            });
          });

          const rows = result.data;
          setTotalRows(rows.length);

          const failedRows = [];

          const checkTriggers = await getMultiple(
            "triggers",
            "client",
            user?.client
          );

          let trigger;
          for (const item of checkTriggers) {
            if (item.name === "Website" && item.connected) {
              trigger = "Website";
              break; // Exit the loop
            } else if (item.name === "Keyword Rankings" && item.connected) {
              trigger = "Keywords";
              break; // Exit the loop
            }
          }

          for (const row of rows) {
            const leadid = `${row.firstName}-${row.companyName}`;
            const leadIdentification = leadid.replace(/ /g, "-").toLowerCase();
            let formattedUrl = row.website;

            if (formattedUrl.startsWith("http")) {
              formattedUrl = formattedUrl.replace("http", "https");
            } else if (formattedUrl.startsWith("www")) {
              formattedUrl = "https://" + formattedUrl;
            } else if (!formattedUrl.startsWith("https://")) {
              formattedUrl = "https://www." + formattedUrl;
            }
            const checkNoRecord = await getSingleDetailed(
              "leads",
              "leadID",
              leadIdentification,
              "client",
              user?.client
            );
            if (!checkNoRecord[0]) {
              const getScreenshot = await takeScreenshotFromCSV(formattedUrl);
              var response;
              if (trigger === "Website") {
                try {
                  response = await axios.post(
                    `https://us-central1-video-outreach-773a2.cloudfunctions.net/getInnerHTML?url=${formattedUrl}`
                  );
                  console.log(response, "response");
                } catch (error) {
                  console.error(
                    "Error retrieving data from the server:",
                    error
                  );
                }
              } else if (trigger === "Keywords") {
                try {
                  response = await axios.post(
                    `https://us-central1-video-outreach-773a2.cloudfunctions.net/leadsMainKeyword?url=${formattedUrl}`
                    // `http://127.0.0.1:5001/video-outreach-773a2/us-central1/leadsMainKeyword?url=${formattedUrl}`
                  );
                  console.log(response, "response");
                } catch (error) {
                  console.error(
                    "Error retrieving data from the server:",
                    error
                  );
                }
                console.log("FIRE OFF RANKINGS");
              }

              try {
                var personalBody;
                var personalSubject;
                if (trigger === "Website") {
                  personalBody =
                    `Hey ${row?.firstName}, ` +
                    response.data.message +
                    " I see a great opportunity for us to collaborate and further grow your reach." +
                    "<br><br>" +
                    `I've created a short video on how ${row?.companyName} can grow. Can I send it over?` +
                    "<br><br>" +
                    "{{just_screenshot}}";
                  personalSubject = response.data.subject.replaceAll('"', "");
                }
                if (trigger === "Keywords") {
                  console.log(response.data, "KEYWORDS response.data");
                  var rankingText;
                  if (response.data.ranking === "No Ranking Data") {
                    rankingText = "not ranking for this keyword";
                  } else {
                    rankingText = response.data.ranking;
                  }

                  // Remove speech marks from the keyword
                  const keywordWithoutSpeechMarks =
                    response.data.keyword.replace(/"/g, "");

                  personalBody =
                    `Hey ${row?.firstName}, ` +
                    `I came across your website and noticed you're trying to rank for the keyword ${response.data.keyword}, but you're currently ${rankingText}.` +
                    " I see a great opportunity for us to collaborate and further grow your reach." +
                    "<br><br>" +
                    `I've created a short video on how ${row?.companyName} can grow. Can I send it over?` +
                    "<br><br>" +
                    "{{just_screenshot}}";
                  personalSubject =
                    keywordWithoutSpeechMarks.charAt(0).toUpperCase() +
                    keywordWithoutSpeechMarks.slice(1).replace(/"/g, "");

                  console.log(personalBody, personalSubject);
                  console.log("FIRE OFF RANKINGS");
                }
                if (getScreenshot) {
                  await addDocument("leads", "", {
                    leadName: row.firstName,
                    leadBusiness: row.companyName,
                    leadID: leadIdentification,
                    leadWebsite: formattedUrl,
                    leadEmail: row.email,
                    screenshot: getScreenshot,
                    client: currentuser?.user?.client,
                    date: new Date(),
                    step: "Initial Email",
                    personalBody: personalBody ? personalBody : "",
                    personalSubject: personalSubject ? personalSubject : "",
                  });
                }

                const thumbnail =
                  copyImageUrlToClipboardCSV(leadIdentification);
                if (thumbnail) {
                  enqueueSnackbar("Added");
                  setTotalRows((prevTotalRows) => prevTotalRows - 1); // Decrement totalRows

                  getClients();
                }
              } catch (error) {
                // Handle individual document adding error
                failedRows.push(row);
                setTotalRows((prevTotalRows) => prevTotalRows - 1); // Decrement totalRows

                console.error("Error adding document:", error);
              }
            } else {
              // Handle screenshot retrieval error
              failedRows.push(row);
              setTotalRows((prevTotalRows) => prevTotalRows - 1); // Decrement totalRows

              console.error("Error retrieving screenshot for row:", row);
              enqueueSnackbar("Lead Already Added");
            }

            if (failedRows.length > 0) {
              console.log("Rows that failed to save:", failedRows);
            }
          }
        } catch (error) {
          console.error("Error parsing CSV:", error);
        }
      };
      setLoadingCSV(false);
      uploadingCSV.onFalse;
      reader.readAsText(csvFile);
    }
  };

  const [businessURL, setBusinessURL] = useState();
  useEffect(() => {
    if (!businessURL) getBusinessURL();
  }, [businessURL]);

  const getBusinessURL = async () => {
    const url = await getSingle(
      "business",
      "client",
      currentuser?.user?.client
    );
    setBusinessURL(url[0].customDomain);
  };
  const personaliseEmails = async () => {
    enqueueSnackbar("Personalising Emails: Please Come back in 5");

    try {
      await Promise.all(
        dataFiltered
          .filter((row) => row.date)
          .filter((row) => !row.personalBody)
          .sort((a, b) => {
            const dateA = a.date.toDate();
            const dateB = b.date.toDate();
            return dateB - dateA;
          })
          .map(async (row) => {
            console.log(row.leadEmail, row.leadWebsite, "ROOW");
            const response = await axios.post(
              `https://us-central1-video-outreach-773a2.cloudfunctions.net/getInnerHTML?url=${row.leadWebsite}`
              // `http://127.0.0.1:5001/video-outreach-773a2/us-central1/getInnerHTML?url=${row.leadWebsite}`
            );
            console.log(response.data.message, "response.data.message");
            const message =
              `Hey ${row?.leadName}, ` +
              response.data.message +
              " I see a great opportunity for us to collaborate and further grow your reach." +
              "<br><br>" +
              `I've created a short video on how ${row?.leadBusiness} can grow. Can I send it over?` +
              "<br><br>" +
              "{{just_screenshot}}";
            const subject = response.data.subject.replaceAll('"', "");
            console.log(message, "message");
            const update = await updateSingleDetailed(
              "leads",
              "leadID",
              row.leadID,
              {
                personalBody: message,
                personalSubject: subject,
              },
              "client",
              row?.client
            );
            return update;
          })
      );

      enqueueSnackbar("Complete");
      getClients();
    } catch (error) {
      console.error("An error occurred:", error);
      enqueueSnackbar("Error occurred during personalization", {
        variant: "error",
      });
    }
  };
  const scheduleAll = async () => {
    enqueueSnackbar("Scheduling Emails");
    const business = await getAllBusinessEmail(user);
    await Promise.all(
      dataFiltered
        .filter((row) => row.date)
        .sort((a, b) => {
          const dateA = a.date.toDate();
          const dateB = b.date.toDate();
          return dateB - dateA;
        })
        .map(async (row, index) => {
          const emailIndex = index % business.emails.length; // Get the index of the email to use
          const email = business.emails[emailIndex].email;
          const count = business.emails[emailIndex].count;
          const type = business.emails[emailIndex].type;
          const newBusiness = {
            email: email,
            businessURL: business.businessURL,
            businessContact: business.businessContact,
            emailNumber: count,
            type: type,
          };
          console.log(newBusiness, "newBusiness");
          const schedule = await scheduleEmail(row, newBusiness);
          if (schedule) {
            return "Scheduled"; // Returning a value from the map function
          }
        })
    );

    // axios.post(
    //   `https://us-central1-video-outreach-773a2.cloudfunctions.net/platformlogSlack`,
    //   {
    //     message: "Scheduled All Emails: " + user?.client,
    //   }
    // );

    // This block will execute after all promises have resolved
    enqueueSnackbar("All emails scheduled");
    await new Promise((resolve) => setTimeout(resolve, 30000));

    // After the delay, call getClients()
    getClients();
  };

  const fixBrokenImages = async () => {
    enqueueSnackbar("Do not refresh page");
    const business = await getAllBusinessEmail(user);
    console.log(business.businessURL);
    await Promise.all(
      dataFiltered
        .filter((row) => row.date)
        .filter((row) => row.preview === undefined)
        .map(async (row, index) => {
          try {
            const getScreenshot = axios.post(
              `https://us-central1-video-outreach-773a2.cloudfunctions.net/takeScreenshot?url=${row.leadWebsite}`
            );

            const getPreview = axios.post(
              `https://us-central1-video-outreach-773a2.cloudfunctions.net/takePreviewScreenshot?url=https://${business.businessURL}/video/${row.leadID}`
            );

            const [screenshotResponse, previewResponse] = await Promise.all([
              getScreenshot,
              getPreview,
            ]);

            const newScreenshot = screenshotResponse.data;
            const newPreview = previewResponse.data;

            await updateSingle("leads", "leadID", row.leadID, {
              preview: newPreview,
              screenshot: newScreenshot,
            });
            console.log(row.leadID);
          } catch (error) {
            console.error("Error updating website:", error);
          }
        })
    );
    enqueueSnackbar("Broken Images Fixed");
    await new Promise((resolve) => setTimeout(resolve, 30000));
    getClients();
  };

  const getAllBusinessEmail = async (currentUser) => {
    const allEmails = await getMultiple(
      "emailaccounts",
      "client",
      currentUser?.client
    );
    const business = await getSingle("business", "client", currentUser?.client);

    var onlyEmails = [];
    allEmails.forEach((email) => {
      onlyEmails.push({
        email: email.email,
        type: email.type,
        count: email.emails,
      });
    });
    return {
      emails: onlyEmails,
      businessURL: business[0].customDomain,
      businessContact: business[0].businessContact,
    };
  };

  const [reviewAllLeads, setReviewAllLeads] = useState(false);

  const reviewAll = () => {
    setReviewAllLeads(true);
  };

  const handleSentOnly = useCallback(
    (value) => {
      if (value) {
        getClients("", true);
      } else if (!value) {
        getClients();
      }
    },
    [tableData]
  );

  const onCloseSyncingCard = () => {
    updateSingle("leadsSyncing", "client", user?.client, {
      syncing: false,
    });
    setLeadsSyncing(false);
  };

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : "lg"}>
        <CustomBreadcrumbs
          heading="Leads"
          links={[
            {
              name: "Dashboard",
              href: paths.dashboard.root,
            },
            {
              name: "Leads",
              // href: paths.dashboard.user.root,
            },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />
        {leadsSyncing && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Card
              style={{
                backgroundColor: "#133A26",
                color: "white",
                textAlign: "left",
                padding: "30px",
                marginBottom: "30px",
                position: "relative",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>
                Leads Syncing...
              </Typography>
              <LinearProgress
                fullWidth
                sx={{ width: "70vw", color: "white", mt: 3 }}
              />
              <IconButton
                onClick={onCloseSyncingCard}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  color: "white",
                }}
              >
                <GridCloseIcon />
              </IconButton>
            </Card>
          </div>
        )}
        {onboarding === 2 && (
          <Card
            style={{
              backgroundColor: "#133A26",
              color: "white",
              alignItems: "center",
              textAlign: "center",
              padding: "30px",
              marginBottom: "30px",
            }}
          >
            <Typography>Final Step</Typography>
            <Typography variant="h6">Now let's send an Email!</Typography>
            <Typography>
              We've added a demo lead so you can see how the email looks and
              feels!
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              onClick={() => setOpenCalendar(true)}
            >
              Still Got Questions?
            </Button>
            {openCalendar && (
              <>
                <Typography>Book a Demo</Typography>
                <iframe
                  src="https://tidycal.com/tomgalland/15-minute-meeting"
                  style={{
                    width: "60vw",
                    height: "100vh",
                    border: "none",
                    marginTop: "10px",
                    borderRadius: "15px",
                  }}
                ></iframe>
              </>
            )}
          </Card>
        )}
        <Card>
          <UserTableToolbar
            filters={filters}
            onFilters={handleFilters}
            //
            roleOptions={_roles}
            showSentOnly={handleSentOnly}
          />

          {canReset && (
            <UserTableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              //
              onResetFilters={handleResetFilters}
              //
              results={dataFiltered?.length}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <TableContainer sx={{ position: "relative", overflow: "unset" }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected?.length}
              rowCount={tableData?.length}
              onSelectAllRows={(checked) =>
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                )
              }
              action={
                <Tooltip title="Delete">
                  <IconButton color="primary" onClick={confirm.onTrue}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                  </IconButton>
                </Tooltip>
              }
            />
            <Scrollbar>
              <Table
                size={table.dense ? "small" : "medium"}
                sx={{ minWidth: 960 }}
              >
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={
                    user?.premium === "infinity"
                      ? TABLE_HEAD_INFINITY
                      : TABLE_HEAD
                  }
                  rowCount={tableData?.length}
                  numSelected={table.selected?.length}
                  onSort={table.onSort}
                  onSelectAllRows={(checked) =>
                    table.onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                />

                {/* {dataFiltered &&
                  dataFiltered
                    .filter((row) => row.leadName.toLowerCase().includes("tom"))
                    .slice(0, 25)
                    // .slice(
                    //   table.page * table.rowsPerPage,
                    //   table.page * table.rowsPerPage + table.rowsPerPage
                    // )
                    .filter((row) => !row.date) // Filter out rows where date is undefined
                    .map((row) => <p>{row.leadEmail}</p>)} */}
                {loading ? (
                  <LinearProgress fullWidth sx={{ width: "100vw" }} />
                ) : (
                  <TableBody>
                    {dataFiltered &&
                      dataFiltered
                        .filter((row) => {
                          const leadName = row.leadName
                            ? row.leadName.toLowerCase()
                            : "";
                          const leadEmail = row.leadEmail
                            ? row.leadEmail.toLowerCase()
                            : "";
                          return (
                            leadName.includes(searchQuery.toLowerCase()) ||
                            leadEmail.includes(searchQuery.toLowerCase())
                          );
                        })
                        .slice(0, 25)
                        .filter((row) => row.date) // Filter out rows where date is undefined
                        .sort((a, b) => {
                          const dateA = a.date.toDate(); // Convert Firebase Timestamp to Date
                          const dateB = b.date.toDate(); // Convert Firebase Timestamp to Date
                          return dateB - dateA; // Compare the dates in ascending order
                        })
                        .sort((a, b) => {
                          if (!a.personalBody && b.personalBody) return 1; // If a has no personalBody but b does, move b up
                          if (a.personalBody && !b.personalBody) return -1; // If b has no personalBody but a does, move a up
                          if (a.personalBody && b.personalBody) {
                            // If both have personalBody, compare them alphabetically
                            if (a.personalBody < b.personalBody) return -1;
                            if (a.personalBody > b.personalBody) return 1;
                          }
                          return 0;
                        })
                        .map((row) => (
                          <LeadTableRow
                            key={row.id}
                            row={row}
                            selected={table.selected.includes(row.id)}
                            onSelectRow={() => table.onSelectRow(row.id)}
                            onDeleteRow={() => handleDeleteRow(row.id)}
                            onEditRow={() => handleEditRow(row.id)}
                            onQuickEditChange={handleQuickEditChange}
                            client={currentuser?.user?.client}
                            campaignID={campaignID}
                            onboarding={onboarding}
                          />
                        ))}

                    <TableEmptyRows
                      height={denseHeight}
                      emptyRows={emptyRows(
                        table.page,
                        table.rowsPerPage,
                        tableData?.length
                      )}
                    />

                    <TableNoData notFound={notFound} />
                  </TableBody>
                )}
              </Table>
            </Scrollbar>
          </TableContainer>

          {loading ? (
            <></>
          ) : (
            <TablePaginationCustom
              count={dataFiltered?.length}
              page={table.page}
              rowsPerPage={table.rowsPerPage}
              onPageChange={table.onChangePage}
              onRowsPerPageChange={table.onChangeRowsPerPage}
              //
              dense={table.dense}
              onChangeDense={table.onChangeDense}
            />
          )}
        </Card>

        {businessURL ? (
          <>
            {loadingCSV ? (
              <CircularProgress />
            ) : (
              <Grid container sx={{ mt: 5 }} spacing={2}>
                {totalRows.length > 0 && (
                  <Grid item xs={12}>
                    <Typography variant="h5" align="center">
                      CSV File Upload
                    </Typography>
                    <Typography variant="h5" align="center">
                      {totalRows}
                    </Typography>
                  </Grid>
                )}
                {businessURL && (
                  <>
                    <Grid item xs={6}>
                      <input
                        type="file"
                        accept=".csv"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                        id="csv-upload"
                      />
                      <label htmlFor="csv-upload">
                        <Button
                          variant="contained"
                          sx={{
                            color: "primary.contrastText",
                            backgroundColor: "grey.800",
                          }}
                          component="span"
                          startIcon={<CloudUpload />}
                        >
                          Upload CSV
                        </Button>
                      </label>
                    </Grid>
                    {user?.premium === "infinity" && (
                      <Grid item container xs={6} spacing={2}>
                        <Grid item>
                          <Button
                            variant="contained"
                            sx={{
                              color: "primary.contrastText",
                              backgroundColor: "grey.800",
                            }}
                            startIcon={<AlternateEmail />}
                            onClick={personaliseEmails}
                          >
                            Personalise Emails
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            sx={{
                              color: "primary.contrastText",
                              backgroundColor: "grey.800",
                            }}
                            startIcon={<BrokenImage />}
                            onClick={fixBrokenImages}
                          >
                            Fix Images
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            sx={{
                              color: "primary.contrastText",
                              backgroundColor: "grey.800",
                            }}
                            startIcon={<AccessAlarm />}
                            onClick={scheduleAll}
                          >
                            Schedule All
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
                {businessURL && (
                  <>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        sx={{
                          color: "primary.contrastText",
                          backgroundColor: "grey.800",
                        }}
                        onClick={() => {
                          handleUpload();
                          uploadingCSV.onTrue();
                        }}
                        disabled={!csvFile}
                      >
                        Process CSV
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        sx={{
                          color: "primary.contrastText",
                          backgroundColor: "grey.800",
                        }}
                        onClick={() =>
                          window.open(
                            "https://firebasestorage.googleapis.com/v0/b/video-outreach-773a2.appspot.com/o/Video%20Outreach%20Template%20-%20Sheet1.csv?alt=media&token=8993e4d5-be3e-4a1f-a4c0-831ce0189834",
                            "_blank"
                          )
                        }
                      >
                        Download CSV Template
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            )}
          </>
        ) : (
          <>
            <Typography variant="h5" sx={{ mt: 3 }} align="center">
              To upload Leads, please add a custom domain in your user settings
            </Typography>
          </>
        )}
      </Container>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete{" "}
            <strong> {table.selected?.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
      <OnboardingUserModalView
        open={modalOnboarding.value}
        onClose={nextOnboarding}
      />
      <ConfirmDialog
        open={uploadingCSV.value}
        onClose={uploadingCSV.onFalse}
        title="Uploading CSV"
        content={
          <>
            {totalRows > 0 ? (
              <div>
                Please do not refresh the page.
                <br />
                <br />
              </div>
            ) : (
              <div></div>
            )}

            {totalRows > 0 ? `${totalRows} Leads Left` : "Completed"}
            <br></br>
            <br></br>
            {totalRows > 0 ? <LinearProgress /> : ""}
          </>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, comparator, filters }) {
  const { name, status, role } = filters;

  const stabilizedThis = inputData && inputData.map((el, index) => [el, index]);

  if (stabilizedThis) {
    const sortedStabilizedThis =
      stabilizedThis &&
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
    // Use sortedStabilizedThis as needed
  }

  inputData = stabilizedThis && stabilizedThis.map((el) => el[0]);

  // if (name) {
  //   inputData =
  //     inputData &&
  //     inputData.filter(
  //       (user) => user.name.toLowerCase().indexOf(name.toLowerCase()) !== -1
  //     );
  // }

  if (status !== "all") {
    inputData = inputData && inputData.filter((user) => user.status === status);
  }

  if (role?.length) {
    inputData =
      inputData && inputData.filter((user) => role.includes(user.role));
  }

  return inputData;
}
