import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import {
  Card,
  Container,
  Grid,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  getSingle,
  getSingleDetailed,
  updateSingle,
  updateSingleDetailed,
} from "src/auth/context/firebase/auth-provider";
import "draft-js/dist/Draft.css";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
  Modifier,
  RichUtils,
} from "draft-js";
import { Editor as DraftEditor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { enqueueSnackbar } from "notistack";
import "./toolbar.css";

const newCustomToolbar = {
  options: ["link"],
  link: {
    icon: <Link />,
  },
};

function EmailEditForm({ user, flow, close }) {
  const [subject, setSubject] = useState("");
  const [emailBodyContent, SetEmailBodyContent] = useState(
    EditorState.createEmpty()
  );
  const [emailSignature, setEmailSignature] = useState(
    EditorState.createEmpty()
  );

  const getEmailTemplate = async () => {
    const template = await getSingleDetailed(
      "emailtemplate",
      "client",
      user?.client,
      "campaignFlowStep",
      // step[0].name
      flow.campaignFlowStep
    );
    if (template[0]) {
      console.log(template, "template");
      if (template[0]?.subject) {
        setSubject(template[0]?.subject);
      }
      if (template[0]?.emailbody) {
        SetEmailBodyContent(
          convertFromHTMLToEditorState(template[0]?.emailbody)
        );
      }

      setEmailSignature(convertFromHTMLToEditorState(template[0]?.signature));
    }
  };

  useEffect(() => {
    if (!subject && user && flow) getEmailTemplate();
  }, [subject]);

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleEmailBodyContentChange = (newEditorState) => {
    SetEmailBodyContent(newEditorState);
  };

  const handleBottomEmailContentChange = (newEditorState) => {
    setBottomEmailContent(newEditorState);
  };

  const handleEmailSignatureChange = (newEditorState) => {
    setEmailSignature(newEditorState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Convert EditorState content to HTML
    const emailBodyHTML = convertToHTML(emailBodyContent.getCurrentContent());

    const emailsig = convertToHTML(emailSignature.getCurrentContent());
    console.log(emailBodyHTML, "emailBodyHTML");

    await updateSingleDetailed(
      "emailtemplate",
      "client",
      user?.client,
      {
        subject: subject,
        emailbody: emailBodyHTML,
        // topemail: topEmailHTML,
        // bottomemail: bottomEmailHTML,
        signature: emailsig,
      },
      "campaignFlowStep",
      flow.campaignFlowStep
    );
    enqueueSnackbar("Saved");
  };

  // Function to convert Draft.js content to HTML
  const convertToHTML = (contentState) => {
    const blockMap = contentState.getBlockMap();

    let html = "";
    blockMap.forEach((contentBlock) => {
      const blockType = contentBlock.getType();
      const text = contentBlock.getText();

      switch (blockType) {
        case "unstyled":
          html += `<p>${text}</p>`;
          break;
        case "header-one":
          html += `<h1>${text}</h1>`;
          break;
        case "header-two":
          html += `<h2>${text}</h2>`;
          break;
        // Add cases for other block types as needed
        default:
          html += `<p>${text}</p>`;
      }

      // Handle entities (e.g., links)
      contentBlock.findEntityRanges(
        (character) => {
          const entityKey = character.getEntity();
          return entityKey !== null;
        },
        (start, end) => {
          const entityKey = contentBlock.getEntityAt(start);
          const entity = contentState.getEntity(entityKey);
          if (entity && entity.type === "LINK") {
            const data = entity.getData();
            const url = data.url;
            html =
              html.slice(0, -4) +
              `<a href="${url}">${text.slice(start, end)}</a></p>`;
          }
        }
      );
    });

    return html;
  };

  // Function to convert HTML to Draft.js content
  const convertFromHTMLToEditorState = (html) => {
    const blocksFromHTML = convertFromHTML(html);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    return EditorState.createWithContent(contentState);
  };

  const handleInsertPlaceholder = (placeholderText) => {
    console.log(placeholderText, "placeholderText");
    // Get the current EditorState
    const currentEditorState = emailBodyContent;

    // Get the current selection state
    const selection = currentEditorState.getSelection();

    // Create a ContentState with the placeholder text
    const contentStateWithPlaceholder = Modifier.replaceText(
      currentEditorState.getCurrentContent(),
      selection,
      placeholderText
    );

    // Create a new EditorState with the updated content
    const newEditorState = EditorState.push(
      currentEditorState,
      contentStateWithPlaceholder,
      "insert-characters"
    );

    // Update the state with the new EditorState
    handleEmailBodyContentChange(newEditorState);
  };

  const variables = [
    { name: "First Name", variable: "{{first_name}}" },
    { name: "Business Name", variable: "{{business_name}}" },
    { name: "Screenshot", variable: "{{screenshot}}" },
    { name: "Just Screenshot", variable: "{{just_screenshot}}" },
  ];
  const [selectedVariable, setSelectedVariable] = useState("Insert Variable");
  const handleVariableSelect = (variable) => {
    const selectedValue = variable;
    console.log(selectedValue, "VALUE");

    if (selectedValue !== "Insert Variable") {
      handleInsertPlaceholder(selectedValue);
      setSelectedVariable("Insert Variable");
    }
  };

  const [preview, setPreview] = useState(false);
  const handlePreviewClick = () => {
    console.log(preview, "preview");
    if (!preview) {
      setPreview(true);
    } else if (preview) {
      setPreview(false);
    }
  };

  const modifiedEmailContent = EditorState.createWithContent(
    emailBodyContent.getCurrentContent()
  );

  // Replace {{first_name}} with "HEY THOMAS HER EMATE"
  const replacedEmailContent = EditorState.createWithContent(
    ContentState.createFromText(
      modifiedEmailContent
        .getCurrentContent()
        .getPlainText()
        .replaceAll("{{first_name}}", "Steve")
        .replaceAll("{{business_name}}", "Apple")
        .replaceAll(
          "{{screenshot}}",
          '  <a href="https://hello.outreachhippo.com/video/your-lead-client"><img src="https://firebasestorage.googleapis.com/v0/b/video-outreach-773a2.appspot.com/o/screenshots%2Fscreenshot_1705018942833.png?alt=media" alt="Thumbnail" /><a href="https://hello.outreachhippo.com/video/your-lead-client">https://hello.outreachhippo.com/video/your-lead-client</a>'
        )
        .replaceAll(
          "{{just_screenshot}}",
          '<img src="https://firebasestorage.googleapis.com/v0/b/video-outreach-773a2.appspot.com/o/screenshots%2Fscreenshot_1705018942833.png?alt=media" alt="Thumbnail" />'
        )
    )
  );

  // Convert the replaced content to HTML
  const replacedHtmlContent = convertToHTML(
    replacedEmailContent.getCurrentContent()
  );

  // Convert the emailSignature content to HTML
  const signatureHtmlContent = convertToHTML(
    emailSignature.getCurrentContent()
  );

  const addLinkToBody = (editorState, url) => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const contentStateWithEntity = contentState.createEntity(
      "LINK",
      "MUTABLE",
      { url: url }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newContentState = Modifier.applyEntity(
      contentState,
      selection,
      entityKey
    );
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "apply-entity"
    );
    return EditorState.forceSelection(
      newEditorState,
      newContentState.getSelectionAfter()
    );
  };

  const addLinkToSignature = (editorState, url) => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const contentStateWithEntity = contentState.createEntity(
      "LINK",
      "MUTABLE",
      { url: url }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newContentState = Modifier.applyEntity(
      contentState,
      selection,
      entityKey
    );
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "apply-entity"
    );
    return EditorState.forceSelection(
      newEditorState,
      newContentState.getSelectionAfter()
    );
  };

  const handleLinkButtonClickBody = () => {
    const url = window.prompt("Enter the URL");
    if (url) {
      const newEditorState = addLinkToBody(emailBodyContent, url);
      console.log(newEditorState, "newEditorState");
      console.log(
        convertToHTML(newEditorState.getCurrentContent()),
        "NEW HTML"
      );
      handleEmailBodyContentChange(newEditorState);
    }
  };

  const handleLinkButtonClickSignature = () => {
    const url = window.prompt("Enter the URL");
    if (url) {
      const newEditorState = addLinkToSignature(emailSignature, url);
      console.log(newEditorState, "newEditorState");
      console.log(
        convertToHTML(newEditorState.getCurrentContent()),
        "NEW HTML"
      );
      handleEmailSignatureChange(newEditorState);
    }
  };

  return (
    <Container>
      {!preview && (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3} sx={{ mb: 2 }}>
            <Grid item lg={2}>
              <p>Insert Variables:</p>
            </Grid>
            {variables.map((variable) => (
              <Grid
                item
                lg={2}
                onClick={() => handleVariableSelect(variable.variable)}
              >
                <Button variant="outlined">{variable.name}</Button>
              </Grid>
            ))}
          </Grid>
          {flow.campaignFlowStep == "Initial Email" && (
            <Grid container spacing={3}>
              <Grid item lg={2}>
                <p>Subject:</p>
              </Grid>
              <Grid item lg={10}>
                <TextField
                  label="Enter Subject"
                  variant="outlined"
                  fullWidth
                  value={subject}
                  onChange={handleSubjectChange}
                />
              </Grid>
            </Grid>
          )}
          <Grid container spacing={3}>
            <Grid item lg={2}>
              <p>Email:</p>
            </Grid>
            <Grid item lg={10}>
              <div
                style={{
                  border: "1px solid #E8ECEE",
                  padding: "8px",
                  borderRadius: "5px",
                }}
              >
                <DraftEditor
                  toolbar={newCustomToolbar}
                  editorState={emailBodyContent}
                  onEditorStateChange={handleEmailBodyContentChange}
                />
                <button onClick={handleLinkButtonClickBody}>Add Link</button>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item lg={2}>
              <p>Email Signature</p>
            </Grid>
            <Grid item lg={10}>
              <div
                style={{
                  border: "1px solid #E8ECEE",
                  padding: "8px",
                  borderRadius: "5px",
                }}
              >
                <DraftEditor
                  toolbar={newCustomToolbar}
                  editorState={emailSignature}
                  onEditorStateChange={handleEmailSignatureChange}
                />
                <button onClick={handleLinkButtonClickSignature}>
                  Add Link
                </button>
              </div>
            </Grid>
          </Grid>
          <Button
            sx={{
              mt: 3,
              textAlign: "left",
              alignItems: "left",
              color: "primary.contrastText",
              backgroundColor: "grey.800",
            }}
            variant="contained"
            color="secondary"
            type="submit"
          >
            Save Template
          </Button>
          <Button
            sx={{
              mt: 3,
              ml: 5,
              textAlign: "left",
              alignItems: "left",
              color: "primary.contrastText",
              backgroundColor: "grey.800",
            }}
            variant="contained"
            color="secondary"
            onClick={handlePreviewClick}
          >
            Preview
          </Button>
        </form>
      )}

      {preview && (
        <>
          <Typography variant="h6" gutterBottom>
            Preview:
          </Typography>
          <div
            style={{
              border: "1px solid #e5e5e5",
              borderRadius: "4px",
              padding: "20px",
              boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
              backgroundColor: "#fff",
              fontFamily: "Arial, sans-serif",
            }}
            dangerouslySetInnerHTML={{
              __html: `<div style="margin-bottom: 20px;"></div>${replacedHtmlContent}${signatureHtmlContent}`,
            }}
          />

          <Button
            sx={{
              mt: 3,
              ml: 5,
              textAlign: "left",
              alignItems: "left",
              color: "primary.contrastText",
              backgroundColor: "grey.800",
            }}
            variant="contained"
            color="secondary"
            onClick={handlePreviewClick}
          >
            Edit Email
          </Button>
        </>
      )}
    </Container>
  );
}

export default EmailEditForm;
