import React, { useState, useRef, useEffect } from "react";
import { ReactMediaRecorder } from "react-media-recorder";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  getSingle,
  uploadVideo,
  updateSingle,
} from "src/auth/context/firebase/auth-provider";
import { useAuthContext } from "src/auth/hooks";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router";

const mimeType = 'video/webm; codecs="opus,vp8"';

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  // justifyContent: "center",
  height: "100vh",
};

const videoControlsStyle = {
  marginBottom: "20px",
};

const videoPlayerStyle = {
  borderRadius: "15px",
  border: "2px solid #ccc",
  overflow: "hidden",
};

const livePlayerStyle = {
  width: "100%",
  height: "auto",
  transform: "scaleX(-1)",
};
const recordedPlayerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
};

const recordedStyle = {
  width: "100%",
  maxWidth: "640px", // Adjust the max width as needed
};
export default function VideoEditForm() {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [showUploadVideo, setShowUploadVideo] = useState();
  const [loading, setLoading] = useState(false);
  const [permission, setPermission] = useState(false);
  const mediaRecorder = useRef(null);
  const liveVideoFeed = useRef(null);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [stream, setStream] = useState(null);
  const [recordedVideo, setRecordedVideo] = useState(null);
  const [videoChunks, setVideoChunks] = useState([]);
  const [videoFile, setVideoFile] = useState(null);
  const [showToolTip, setShowToolTip] = useState(0);

  const getRecording = async () => {
    const video = await getSingle("video", "client", user?.client);
    if (video[0]) {
      setRecordedVideo(video[0].url);
    }
  };

  useEffect(() => {
    if (!recordedVideo) getRecording();
  }, [recordedVideo]);

  const getCameraPermission = async () => {
    setRecordedVideo(null);
    setShowToolTip(1);
    if ("MediaRecorder" in window) {
      try {
        const videoConstraints = {
          audio: false,
          video: true,
        };
        const audioConstraints = { audio: true };

        const audioStream = await navigator.mediaDevices.getUserMedia(
          audioConstraints
        );
        const videoStream = await navigator.mediaDevices.getUserMedia(
          videoConstraints
        );

        setPermission(true);

        const combinedStream = new MediaStream([
          ...videoStream.getVideoTracks(),
          ...audioStream.getAudioTracks(),
        ]);

        setStream(combinedStream);
        liveVideoFeed.current.srcObject = videoStream;
      } catch (err) {
        alert(err.message);
      }
    } else {
      alert("The MediaRecorder API is not supported in your browser.");
    }
  };

  const startRecording = async () => {
    setRecordingStatus("recording");
    const media = new MediaRecorder(stream, { mimeType });
    mediaRecorder.current = media;
    mediaRecorder.current.start();
    let localVideoChunks = [];
    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === "undefined") return;
      if (event.data.size === 0) return;
      localVideoChunks.push(event.data);
    };
    setVideoChunks(localVideoChunks);
  };

  const stopRecording = async () => {
    enqueueSnackbar("Uploading...");
    setLoading(true);
    setPermission(false);
    setRecordingStatus("inactive");
    mediaRecorder.current.stop();
    mediaRecorder.current.onstop = async () => {
      const videoBlob = new Blob(videoChunks, { type: mimeType });
      try {
        console.log(videoBlob, "videoBlob");
        const downloadURL = await uploadVideo(
          "client",
          "video",
          videoBlob,
          user?.client
        );
        setRecordedVideo(downloadURL);
        setVideoChunks([]);
        enqueueSnackbar("Video Uploaded");
        navigate("/dashboard/leads?onboarding=true");
      } catch (error) {
        console.error("Error uploading video:", error);
      }
    };
    setLoading(false);
  };

  const deleteVideo = async () => {
    const removeVideo = await updateSingle("video", "client", user?.client, {
      url: null,
    });
    setRecordingStatus("active");
    setRecordedVideo(null);
    setVideoChunks([]);
    getCameraPermission();
  };

  const [videoSrc, setVideoSrc] = useState(null);
  const handleChange = async (event) => {
    setLoading(true);
    setShowToolTip(1);
    const file = event.target.files[0];
    if (!file) return;

    const downloadURL = await uploadVideo(
      "client",
      "video",
      file,
      user?.client
    );
    setRecordedVideo(URL.createObjectURL(file));
    setLoading(false);
  };
  const videoRef = useRef();
  useEffect(() => {
    videoRef.current?.load();
  }, [videoSrc]);
  return (
    <Container style={containerStyle}>
      <div style={videoControlsStyle} className="video-controls">
        {loading ? (
          <CircularProgress />
        ) : (
          <div>
            <Grid
              container
              spacing={3}
              style={{
                width: "50vw",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {!recordedVideo && !user?.onboardingComplete && (
                <Tooltip
                  fullWidth
                  style={{
                    border: "1px solid #133A26",
                    borderRadius: "30px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    paddingBottom: "15px",
                  }}
                  title={
                    <div style={{ padding: "20px" }}>
                      <Typography variant="h6">
                        Create a Video or Upload Video
                      </Typography>
                      <Typography>Click a button</Typography>
                    </div>
                  }
                  arrow
                  open={showToolTip === 0 ? true : false}
                >
                  <Grid
                    container
                    spacing={2}
                    style={{ alignItems: "center", textAlign: "center" }}
                  >
                    <Grid item xs={12} sm={6}>
                      <Button
                        variant="contained"
                        onClick={getCameraPermission}
                        type="button"
                        fullWidth
                        style={{
                          backgroundColor: "#133A26",
                          padding: "30px",
                          borderRadius: "15px",
                        }}
                      >
                        Record Video
                      </Button>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Button
                        variant={showUploadVideo ? "outlined" : "contained"}
                        onClick={() => setShowUploadVideo(true)}
                        type="button"
                        fullWidth
                        style={{
                          // backgroundColor: "#133A26",
                          padding: "30px",
                          borderRadius: "15px",
                        }}
                      >
                        Upload Video
                      </Button>
                    </Grid>
                  </Grid>
                </Tooltip>
              )}

              {user?.onboardingComplete && (
                <Grid
                  container
                  spacing={2}
                  style={{ alignItems: "center", textAlign: "center" }}
                >
                  <Grid item xs={12} sm={6}>
                    <Button
                      variant="contained"
                      onClick={getCameraPermission}
                      type="button"
                      fullWidth
                      style={{
                        backgroundColor: "#133A26",
                        padding: "30px",
                        borderRadius: "15px",
                      }}
                    >
                      Record Video
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Button
                      variant={showUploadVideo ? "outlined" : "contained"}
                      onClick={() => setShowUploadVideo(true)}
                      type="button"
                      fullWidth
                      style={{
                        // backgroundColor: "#133A26",
                        padding: "30px",
                        borderRadius: "15px",
                      }}
                    >
                      Upload Video
                    </Button>
                  </Grid>
                </Grid>
              )}

              {showUploadVideo && (
                <Grid item xs={12}>
                  <input
                    type="file"
                    className="form-control form-control-sm"
                    style={{ display: "none" }}
                    id="media-URL"
                    accept="video/mp4,video/x-m4v,video/*"
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                {videoFile && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleUpload}
                    fullWidth
                  >
                    Upload
                  </Button>
                )}
              </Grid>
              <Grid
                xs={12}
                style={{
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                {permission && recordingStatus === "inactive" ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {permission && recordingStatus === "inactive" ? (
                      <Button
                        variant="contained"
                        onClick={startRecording}
                        type="button"
                      >
                        Start Recording
                      </Button>
                    ) : null}
                  </div>
                ) : null}
              </Grid>
              {recordingStatus === "recording" ? (
                <Button
                  variant="contained"
                  onClick={stopRecording}
                  type="button"
                >
                  Stop Recording
                </Button>
              ) : null}
            </Grid>
          </div>
        )}
      </div>

      <div style={videoPlayerStyle} className="video-player">
        {!recordedVideo ? (
          <video
            ref={liveVideoFeed}
            autoPlay
            style={livePlayerStyle}
            className="live-player flip-horizontal"
          ></video>
        ) : null}
        {recordedVideo ? (
          <div style={recordedPlayerStyle} className="recorded-player">
            <video
              style={recordedStyle}
              className="recorded"
              src={recordedVideo}
              controls
            ></video>
            <Button onClick={deleteVideo} variant="contained" sx={{ mt: 3 }}>
              Re-Record Video
            </Button>
          </div>
        ) : null}
      </div>
    </Container>
  );
}
