import React, { useState, useEffect } from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import moment from "moment-timezone";

const TimezoneSelector = ({ userTimezone, onChange }) => {
  console.log(userTimezone, "userTimezone");
  const timezones = [
    "America/New_York",
    "America/Los_Angeles",
    "America/Chicago",
    "America/Denver",
    "America/Phoenix",
    "America/Anchorage",
    "America/Adak",
    "America/Honolulu",
    "America/Toronto",
    "America/Vancouver",
    "America/Mexico_City",
    "America/Buenos_Aires",
    "America/Sao_Paulo",
    "Europe/London",
    "Europe/Paris",
    "Europe/Berlin",
    "Europe/Rome",
    "Europe/Madrid",
    "Europe/Moscow",
    "Europe/Istanbul",
    "Asia/Tokyo",
    "Asia/Seoul",
    "Asia/Shanghai",
    "Asia/Singapore",
    "Asia/Dubai",
    "Asia/Hong_Kong",
    "Asia/Kolkata",
    "Asia/Jakarta",
    "Australia/Sydney",
    "Australia/Melbourne",
    "Australia/Brisbane",
    "Australia/Perth",
    "Australia/Adelaide",
    "Australia/Darwin",
    "Australia/Hobart",
  ];
  const [selectedTimezone, setSelectedTimezone] = useState(null);

  useEffect(() => {
    if (userTimezone) {
      setSelectedTimezone(userTimezone);
    }
  }, [userTimezone]);

  const handleTimezoneChange = (event) => {
    const selectedTimezone = event.target.value;
    setSelectedTimezone(selectedTimezone);
    onChange(selectedTimezone);
  };

  const formatTimezone = (timezone) => {
    return moment.tz(timezone).format("z (Z)");
  };

  if (!userTimezone) {
    // User timezone not available yet
    return null;
  }

  return (
    <FormControl fullWidth variant="outlined" sx={{ mt: 3 }}>
      <InputLabel id="timezone-label">Timezone</InputLabel>
      <Select
        labelId="timezone-label"
        value={selectedTimezone}
        onChange={handleTimezoneChange}
        defaultValue={userTimezone}
        label="Timezone"
      >
        {timezones.map((timezone, index) => (
          <MenuItem
            key={index}
            value={timezone}
            selected={timezone === selectedTimezone}
          >
            {timezone}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TimezoneSelector;
