import { useEffect, useState } from "react";
// @mui
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import ListItemText from "@mui/material/ListItemText";
// hooks
import {
  deleteSingle,
  getMultiple,
  updateSingle,
} from "src/auth/context/firebase/auth-provider";
import { useBoolean } from "src/hooks/use-boolean";
// components
import Label from "src/components/label";
import Iconify from "src/components/iconify";
import CustomPopover, { usePopover } from "src/components/custom-popover";
import { ConfirmDialog } from "src/components/custom-dialog";
//
import { enqueueSnackbar } from "notistack";
import { CheckBox } from "@mui/icons-material";
import { useAuthContext } from "src/auth/hooks";

// ----------------------------------------------------------------------

LeadFinderTableRow.propTypes = {
  onQuickEditChange: PropTypes.func.isRequired,
};

export default function LeadFinderTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
  onQuickEditChange,
  client,
  onboarding,
}) {
  const { id, name, headline, organization, email_status } = row;

  const { user } = useAuthContext();
  const [businessCustomURL, setBusinessCustomURL] = useState();

  const confirm = useBoolean();

  const quickEdit = useBoolean();

  const popover = usePopover();

  const [prevValue, setPrevValue] = useState(quickEdit.value);

  const [getLeadData, setGetLeadData] = useState(false);

  useEffect(() => {
    if (prevValue === true && quickEdit.value === false) {
      setGetLeadData(true);
      onQuickEditChange(quickEdit.value);
    } else if (quickEdit.value === true) {
      setGetLeadData(false);
    }

    setPrevValue(quickEdit.value);
    // if (leadAnalytics) getLeadAnalytics();
  }, [quickEdit.value, prevValue, onQuickEditChange]);
  useEffect(() => {}, [row]);

  const handleCheckboxChange = () => {
    onSelectRow(id);
  };
  return (
    <>
      <TableRow hover>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onChange={handleCheckboxChange} />
        </TableCell>
        <TableCell sx={{ display: "flex", alignItems: "center" }}>
          <ListItemText
            primary={name}
            // secondary={email}
            primaryTypographyProps={{ typography: "body2" }}
            secondaryTypographyProps={{
              component: "span",
              color: "text.disabled",
            }}
          />
        </TableCell>

        <TableCell>{headline}</TableCell>
        <TableCell>{organization.name}</TableCell>

        <Checkbox checked={email_status} />
      </TableRow>
    </>
  );
}

LeadFinderTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
};
