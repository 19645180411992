import * as Yup from "yup";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// @mui
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
// @mui
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router";
import { getSingle } from "src/auth/context/firebase/auth-provider";
import Logo from "src/components/logo";
import { useAuthContext } from "src/auth/hooks";

// ----------------------------------------------------------------------

DemoRouteView.propTypes = {
  complete: PropTypes.func,
};

export default function DemoRouteView({ complete }) {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const urlID = window.location.origin;
  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [user]);
  return (
    <Box
      component="main"
      sx={{
        display: "flex",
        minHeight: "100vh",
        textAlign: "center",
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
        "&:before": {
          width: 1,
          height: 1,
          zIndex: -1,
          content: "''",
          opacity: 0.24,
          position: "absolute",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundImage: "url(/assets/background/overlay_4.jpg)",
        },
      }}
    >
      <Card
        sx={{
          py: 5,
          px: 5,
          width: 500,
        }}
      >
        <Stack
          spacing={1}
          sx={{ my: 0, textAlign: "center", alignItems: "center" }}
        >
          <Logo />
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            Please check your URL
          </Typography>
        </Stack>
      </Card>
    </Box>
  );
}
