import PropTypes from "prop-types";
import { useCallback, useState } from "react";
// @mui
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Select from "@mui/material/Select";
// components
import Iconify from "src/components/iconify";
import CustomPopover, { usePopover } from "src/components/custom-popover";
import { Tooltip } from "@mui/material";

// ----------------------------------------------------------------------

export default function UserTableToolbar({
  filters,
  onFilters,
  //
  roleOptions,
  showSentOnly
}) {
  const popover = usePopover();
  const [sentOnly,setSentOnly] = useState(false)

  const handleFilterName = useCallback(
    (event) => {
      console.log(event.target.value, "event.target.value");
      onFilters("name", event.target.value);
    },
    [onFilters]
  );

  const handleFilterRole = useCallback(
    (event) => {
      onFilters(
        "role",
        typeof event.target.value === "string"
          ? event.target.value.split(",")
          : event.target.value
      );
    },
    [onFilters]
  );


  const handleShowSentOnly = useCallback(
    (event) => {
      if(sentOnly){
        showSentOnly(
          false
         );
         setSentOnly(false)
      } else if(!sentOnly){
        showSentOnly(
          true
         );
         setSentOnly(true)
      }
    
    },
    [showSentOnly]
  );

  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: "flex-end", md: "center" }}
        direction={{
          xs: "column",
          md: "row",
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
        }}
      >

        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          flexGrow={1}
          sx={{ width: 1 }}
        >
          <TextField
            fullWidth
            value={filters.name}
            onChange={handleFilterName}
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ color: "text.disabled" }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <Tooltip title="Show Sent Only">
            <IconButton sx={{color: sentOnly ? "green" : ""}} onClick={handleShowSentOnly}>
              <Iconify icon="wpf:sent" />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
      
    </>
  );
}

UserTableToolbar.propTypes = {
  filters: PropTypes.object,
  onFilters: PropTypes.func,
  roleOptions: PropTypes.array,
  showSentOnly: PropTypes.func,
};
