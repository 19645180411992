import * as Yup from "yup";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// @mui
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import moment from "moment-timezone";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import {
  deleteLogo,
  getLogo,
  getProfilePhoto,
  getSingle,
  updateSingle,
  uploadMedia,
} from "src/auth/context/firebase/auth-provider";
// hooks
import { useAuthContext } from "src/auth/hooks";
// utils
import { fData } from "src/utils/format-number";
// assets
import { countries } from "src/assets/data";
// components
import Iconify from "src/components/iconify";
import { useSnackbar } from "src/components/snackbar";
import FormProvider, {
  RHFSwitch,
  RHFTextField,
  RHFUploadAvatar,
  RHFAutocomplete,
} from "src/components/hook-form";
import { CircularProgress } from "@mui/material";
import Cookies from "js-cookie";
import axios from "axios";
import TimezoneSelector from "./timezoneselector";

// ----------------------------------------------------------------------

export default function AccountGeneral({ onboarding, onClose }) {
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useAuthContext();

  const [profilePhotoLogo, setProfilePhotoLogo] = useState();
  const [loading, setLoading] = useState();
  const [business, setBusiness] = useState();
  const [dnsRecords, setDNSRecords] = useState(false);

  const UpdateUserSchema = Yup.object().shape({
    displayName: Yup.string().required("Name is required"),
    email: Yup.string()
      .required("Email is required")
      .email("Email must be a valid email address"),
  });

  // Assuming you have the 'user' and 'business' objects available

  const [defaultValues, setDefaultValues] = useState({
    displayName: user?.displayName || "",
    email: user?.email || "",
    businessContact: "",
    businessContactTitle: "",
    businessName: "",
    calendarLink: "",
    businessEmail: "",
    customDomain: "",
    dnsRecords: "",
    // replyToEmail: "",
  });

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    setValue,
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const [userTimezone, setUserTimezone] = useState();

  useEffect(() => {
    if (!profilePhotoLogo) getProfilePhotoLogo();
  }, [profilePhotoLogo, setValue]);
  useEffect(() => {
    if (!business) getBusinessDetails();
  }, [business]);
  const getBusinessDetails = async () => {
    try {
      const businessDetails = await getSingle(
        "business",
        "client",
        user?.client
      );
      if (businessDetails[0]) {
        setDefaultValues((prevDefaultValues) => ({
          ...prevDefaultValues,
          businessContact: businessDetails[0]?.businessContact || "",
        }));
        setBusiness(businessDetails[0]);
        setValue("businessContact", businessDetails[0]?.businessContact, {
          shouldValidate: false,
        });
        setValue("businessName", businessDetails[0]?.businessName, {
          shouldValidate: false,
        });
        setValue("businessEmail", businessDetails[0]?.businessEmail, {
          shouldValidate: false,
        });
        // setValue("replyToEmail", businessDetails[0]?.replyToEmail, {
        //   shouldValidate: false,
        // });
        setValue(
          "businessContactTitle",
          businessDetails[0]?.businessContactTitle,
          {
            shouldValidate: false,
          }
        );
        setValue("calendarLink", businessDetails[0]?.calendarLink, {
          shouldValidate: false,
        });
        setValue("customDomain", businessDetails[0]?.customDomain, {
          shouldValidate: false,
        });
        if (businessDetails[0]?.dnsRecord) {
          setDNSRecords(true);
        }
        setValue("dnsRecords", businessDetails[0]?.dnsRecords, {
          shouldValidate: false,
        });
        setUserTimezone(businessDetails[0].timezone);
      }
    } catch (error) {
      console.error("Error retrieving business details:", error);
    }
  };

  const getProfilePhotoLogo = async () => {
    const photo = await getSingle("business", "client", user?.client);
    setProfilePhotoLogo(photo[0]?.profilephoto);
  };

  const deleteProfilePhotoLogo = async () => {
    await deleteLogo("business", "client", "profilephoto", user?.client);

    setProfilePhotoLogo("");
  };

  const onSubmit = useCallback(
    async (data) => {
      try {
        console.log(userTimezone, "userTimezonefirst");
        const currentTimezone = userTimezone; // Capture the current value of userTimezone
        if (data.businessName) {
          console.log(currentTimezone, "userTimezone");
          const businessDetails = await getSingle(
            "business",
            "client",
            user?.client
          );
          const userData = {
            displayName: data.displayName,
            email: data.email,
          };
          await updateSingle("users", "uid", user?.uid, userData);
          const cleanedDomain = data.customDomain.replace(
            /^(https?:\/\/)?(www\.)?/,
            ""
          );
          if (
            data.customDomain &&
            businessDetails[0].customDomain != data.customDomain
          ) {
            const addDomain = await axios.post(
              `https://us-central1-video-outreach-773a2.cloudfunctions.net/addDomain?domain=${data.customDomain.replace(
                /^(https?:\/\/)?/,
                ""
              )}`
            );
          }

          console.log(data.businessContactTitle, "businessContactTitle");
          await updateSingle("business", "client", user?.client, {
            businessContact: data.businessContact,
            businessContactTitle: data.businessContactTitle
              ? data.businessContactTitle
              : "",
            businessName: data.businessName ? data.businessName : "",
            calendarLink: data.calendarLink ? data.calendarLink : "",
            email: data.businessEmail ? data.businessEmail : "",
            customDomain: data.customDomain ? cleanedDomain : "",
            dnsRecords: "cname.outreachhippo.com",
            timezone: currentTimezone ? currentTimezone : "", // Use the captured value of userTimezone
          });
        }
        enqueueSnackbar("Update success!");
        setDNSRecords(true);
        if (onboarding) {
          updateSingle("users", "email", user?.email, {
            onboarding: "lead",
          });
          onClose();
        }
      } catch (error) {
        console.error(error);
      }
    },
    [enqueueSnackbar, user, userTimezone] // Include userTimezone in the dependency array
  );

  const handleDrop = useCallback(
    async (acceptedFiles) => {
      setLoading(true);
      const file = acceptedFiles[0];
      await uploadMedia(
        "client",
        "business",
        file,
        "profilephoto",
        user?.client
      );
      setTimeout(() => {
        window.location.reload(true);
      }, 3000);
    },

    [user]
  );

  const handleLogoDrop = useCallback(
    async (acceptedFiles) => {
      setLoading(true);
      const file = acceptedFiles[0];
      await uploadMedia(
        "client",
        "business",
        file,
        "businessLogo",
        user?.client
      );
      setTimeout(() => {
        window.location.reload(true);
      }, 3000);
    },

    [user]
  );

  // BUSINESS
  const [businessLogo, setBusinessLogo] = useState();

  useEffect(() => {
    if (!businessLogo) getBusinessLogo();
  }, [businessLogo]);

  const getBusinessLogo = async () => {
    const logo = await getSingle("business", "client", user?.client);
    setBusinessLogo(logo[0]?.businessLogo);
  };

  const deleteBusinessLogo = async () => {
    await deleteLogo("business", "client", "businessLogo", user?.client);
    Cookies.remove("logo");
    setBusinessLogo();
  };

  const getAccessToken = () => {
    // Replace with the actual URL
    const apiUrlAgency =
      "https://us-central1-video-outreach-773a2.cloudfunctions.net/getAccessTokenAgencySEOGrowth";

    // Send an HTTP GET request
    axios
      .get(apiUrlAgency)
      .then((response) => {
        // Handle the access token data here
        const { data } = response;
        enqueueSnackbar("Agency Refreshed");
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error("Error:", error);
      });

    // Replace with the actual URL
    const apiUrlDigital =
      "https://us-central1-video-outreach-773a2.cloudfunctions.net/getAccessTokenDigitalSEOGrowth";

    // Send an HTTP GET request
    axios
      .get(apiUrlDigital)
      .then((response) => {
        // Handle the access token data here
        const { data } = response;
        enqueueSnackbar("Digital Refreshed");
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error("Error:", error);
      });
  };

  const changeTimezone = (v) => {
    setUserTimezone(v);
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        {!onboarding && (
          <Grid xs={12} md={4}>
            <Card sx={{ pt: 10, pb: 5, px: 3, textAlign: "center" }}>
              {loading ? (
                <CircularProgress />
              ) : (
                <>
                  <Typography variant="h6" sx={{ textAlign: "left" }}>
                    Profile Photo
                  </Typography>
                  &nbsp; &nbsp;
                  {profilePhotoLogo && (
                    <>
                      <img src={profilePhotoLogo} alt={profilePhotoLogo} />
                      <Button
                        variant="soft"
                        color="error"
                        sx={{ mt: 3 }}
                        onClick={deleteProfilePhotoLogo}
                      >
                        Change Profile Photo
                      </Button>
                    </>
                  )}
                  {!profilePhotoLogo && (
                    <RHFUploadAvatar
                      name="photoURL"
                      maxSize={3145728}
                      onDrop={handleDrop}
                      helperText={
                        <Typography
                          variant="caption"
                          sx={{
                            mt: 3,
                            mx: "auto",
                            display: "block",
                            textAlign: "center",
                            color: "text.disabled",
                          }}
                        >
                          Profile Photo Logo <br />
                          Allowed *.jpeg, *.jpg, *.png, *.gif
                          <br /> max size of {fData(3145728)}
                        </Typography>
                      }
                    />
                  )}
                </>
              )}
            </Card>
          </Grid>
        )}

        {!onboarding && (
          <Grid xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Box
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(2, 1fr)",
                }}
              >
                <RHFTextField name="displayName" label="Name" />
                <RHFTextField name="email" label="Email Address" />
              </Box>

              <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Save Changes
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
        )}
        {!onboarding && (
          <Grid xs={12} md={4}>
            <Card sx={{ pt: 10, pb: 5, px: 3, textAlign: "center" }}>
              {loading ? (
                <CircularProgress />
              ) : (
                <>
                  <Typography variant="h6" sx={{ textAlign: "left" }}>
                    Logo
                  </Typography>
                  &nbsp; &nbsp;
                  {businessLogo && (
                    <>
                      <img src={businessLogo} alt={businessLogo} />
                      <Button
                        variant="soft"
                        color="error"
                        sx={{ mt: 3 }}
                        onClick={deleteBusinessLogo}
                      >
                        Change Logo
                      </Button>
                    </>
                  )}
                  {!businessLogo && (
                    <RHFUploadAvatar
                      name="photoURL"
                      maxSize={3145728}
                      onDrop={handleLogoDrop}
                      helperText={
                        <Typography
                          variant="caption"
                          sx={{
                            mt: 3,
                            mx: "auto",
                            display: "block",
                            textAlign: "center",
                            color: "text.disabled",
                          }}
                        >
                          Business Logo <br />
                          Allowed *.jpeg, *.jpg, *.png, *.gif
                          <br /> max size of {fData(3145728)}
                        </Typography>
                      }
                    />
                  )}
                </>
              )}
            </Card>
          </Grid>
        )}
        <Grid xs={12} md={onboarding ? 12 : 8}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
              }}
            >
              <RHFTextField name="businessName" label="Business Name" />
              <RHFTextField
                name="businessEmail"
                label="Business Email Address"
              />
              <RHFTextField name="businessContact" label="Business Contact" />
              <RHFTextField
                name="businessContactTitle"
                label="Business Contact Title"
              />
              {/* <RHFTextField name="replyToEmail" label="Reply To Email" /> */}
              <RHFTextField name="calendarLink" label="Calendar Link" />
              <RHFTextField name="customDomain" label="Custom Domain" />
            </Box>
            <TimezoneSelector
              onChange={changeTimezone}
              userTimezone={userTimezone}
            />

            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Save Changes
              </LoadingButton>
            </Stack>
          </Card>
          {/* {dnsRecords && ( */}
          <Card
            sx={{
              mt: 3,
              p: 2,
              border: "1px solid #eaeaea",
              // borderRadius: "15px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography variant="h6">DNS Records</Typography>
            <Typography variant="body2">
              Please add custom domain above and these records to your Domain
              DNS to activate custom domain
            </Typography>
            <Grid container>
              <Grid xs={3}>
                <div
                  style={{
                    padding: "8px",
                    borderRadius: "4px",
                    marginTop: "10px",
                  }}
                >
                  <Typography>CNAME:</Typography>
                </div>
              </Grid>
              <Grid xs={6}>
                {" "}
                <div
                  style={{
                    backgroundColor: "#f5f5f5",
                    padding: "8px",
                    borderRadius: "4px",
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    navigator.clipboard
                      .writeText("cname.outreachhippo.com")
                      .then(() => {
                        enqueueSnackbar("Copied");
                      })
                  }
                >
                  <Typography>cname.outreachhippo.com</Typography>
                </div>
              </Grid>
            </Grid>
          </Card>
          {/* )} */}
        </Grid>
      </Grid>
    </FormProvider>
  );
}
