import PropTypes from "prop-types";
import * as Yup from "yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";

import CryptoJS from "crypto-js";

// @mui
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { enqueueSnackbar, useSnackbar } from "src/components/snackbar";

import {
  addDocument,
  getSingle,
  getSingleDetailed,
} from "src/auth/context/firebase/auth-provider";
import {
  CircularProgress,
  Container,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useAuthContext } from "src/auth/hooks";
import { useNavigate } from "react-router";

// ----------------------------------------------------------------------

export default function EmailAccountTypeForm({ open, onClose, showToolTip }) {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [gmail, setGmail] = useState(false);
  const [gmailEmail, setGmailEmail] = useState("");
  const [gmailAppPassword, setGmailAppPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [imap, showImap] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    imapHost: "",
    imapUsername: "",
    imapPassword: "",
    imapPort: "",
    smtpHost: "",
    smtpUsername: "",
    smtpPassword: "",
    smtpPort: "",
    signature: "",
  });

  const handleGoogleAuth = () => {
    // Replace with your Google OAuth 2.0 configuration
    const clientId =
      "886016310102-f0g5nnr5kn2g0r7mjgbmt91vplh3mgm3.apps.googleusercontent.com";
    const redirectUri = `https://app.outreachhippo.com/dashboard/gmail`;
    // const redirectUri = `http://localhost:3000/dashboard/gmail`;
    const scope = [
      "https://www.googleapis.com/auth/gmail.readonly",
      "https://www.googleapis.com/auth/gmail.send",
    ];
    const scopeString = scope.join(" "); // Convert the array to a space-separated string

    const authUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopeString}&response_type=code&access_type=offline`;

    // Open a new window or redirect to authUrl for user authentication
    window.location.href = authUrl;
  };

  const handleOutlookAuth = () => {
    // Replace with your Outlook OAuth 2.0 configuration
    const clientId = "0d981e3f-6bb3-4114-9eb9-02347dfd4931";
    // const redirectUri = 'https://app.outreachhippo.com/callback'; // Your app's redirect URI
    const redirectUri = "http://localhost:3000/dashboard/outlook";
    const scope = "offline_access Mail.Read Mail.Send User.Read profile openid";

    const authUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&scope=${scope}&response_mode=query&state=12345`;

    // Open a new window or redirect to authUrl for user authentication
    window.location.href = authUrl;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const secretKey = process.env.REACT_APP_ENCRYPT_KEY;

    // Encrypt the password using AES encryption
    const encryptedPassword = CryptoJS.AES.encrypt(
      gmailAppPassword,
      secretKey
    ).toString();

    await addDocument("emailaccounts", "", {
      email: gmailEmail,
      client: user?.client,
      date: new Date(),
      emails: 0,
      type: "gmail",
    });

    await addDocument("accesstoken", "", {
      email: gmailEmail,
      password: encryptedPassword,
      client: user?.client,
      type: "gmail",
    });
    onClose();
    setGmail(false);
    setGmailEmail("");
    setGmailAppPassword("");
  };

  const handleImapChange = (e) => {
    console.log(e.target, "HERE");
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImapSubmit = async (e) => {
    e.preventDefault();
    const userClient = user?.client;

    if (userClient) {
      try {
        await addDocument("emailaccounts", undefined, {
          type: "mailbox",
          client: user?.client,
          emails: 0,
          ...formData, // Include all form data
        });

        await addDocument("accesstoken", undefined, {
          type: "mailbox",
          client: user?.client,
          emails: 0,
          ...formData,
        });
        enqueueSnackbar("Added");
        onClose();
        navigate("/dashboard/emailtemplate?onboarding=true");
      } catch (error) {
        console.error("Error adding data:", error);
      }
    } else {
      console.error("User or client information missing");
    }
  };

  const [csvData, setCSVData] = useState(null);

  const handleCSVUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const contents = event.target.result;
        // Parse the CSV file
        const parsedCSV = parseCSV(contents);
        setCSVData(parsedCSV);
      };
      reader.readAsText(file);
    }
  };

  const parseCSV = (csvText) => {
    const rows = csvText.split("\n");
    const headers = rows[0].split(",");

    const data = [];

    for (let i = 1; i < rows.length; i++) {
      const row = rows[i].split(",");
      if (row.length === headers.length) {
        const rowData = {};
        for (let j = 0; j < headers.length; j++) {
          rowData[headers[j].trim()] = row[j].trim();
        }
        data.push(rowData);
      }
    }

    return data;
  };
  const [fieldMappings, setFieldMappings] = useState({ emailAddress: "" });

  const handleUpload = async () => {
    if (csvData && csvData.length > 0) {
      setLoading(true);
      for (const formData of csvData) {
        try {
          const getEmailInbox = await getSingleDetailed(
            "emailaccounts",
            "email",
            formData.address,
            "client",
            user?.client
          );
          if (getEmailInbox[0]) {
            enqueueSnackbar("Email Already Added", { variant: "error" });
          } else {
            const response = await axios.post(
              // "http://127.0.0.1:5001/video-outreach-773a2/us-central1/testMailboxLogin",
              "https://us-central1-video-outreach-773a2.cloudfunctions.net/testMailboxLogin",
              { formData }
            );
            if (response.data.message == "Login successful") {
              await addDocument("emailaccounts", undefined, {
                type: "mailbox",
                client: user?.client,
                email: formData.address,
                emails: 0,
                ...formData,
              });
              await addDocument("accesstoken", undefined, {
                type: "mailbox",
                client: user?.client,
                email: formData.address,
                emails: 0,
                ...formData,
              });
              enqueueSnackbar(`Added ${formData.address}`, {
                variant: "success",
              });
            } else {
              enqueueSnackbar(`Error connecting to ${formData.address}`, {
                variant: "error",
              });
            }
          }
        } catch (error) {
          console.error("Error adding document:", error);
        }
      }
      setLoading(false);
      onClose();
      setGmail(false);
      showImap(false);
      setCSVData(null);
      enqueueSnackbar("Complete");
    }
  };

  const handleMappingChange = (fieldName, selectedColumn) => {
    setFieldMappings({ ...fieldMappings, [fieldName]: selectedColumn });
  };
  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <DialogContent sx={{ m: 10 }}>
        {loading && <CircularProgress />}
        {!gmail && !loading && showToolTip && (
          <Grid container spacing={3} sx={{ mt: 3 }}>
            <Tooltip
              title={
                <div style={{ padding: "20px" }}>
                  <Typography variant="h6">Choose your Provider</Typography>
                  <Typography>
                    Select your email provider and folow the steps
                  </Typography>
                </div>
              }
              arrow
              open={showToolTip === 2 ? true : false}
            >
              {!imap && (
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      sx={{ pt: 2, pb: 2, fontSize: 30 }}
                      variant="contained"
                      onClick={() => setGmail(true)}
                    >
                      Gmail
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      sx={{ pt: 2, pb: 2, fontSize: 30 }}
                      variant="contained"
                      onClick={handleOutlookAuth}
                    >
                      Outlook
                    </Button>
                  </Grid>

                  <Grid item xs={6}>
                    <Button
                      onClick={() => showImap(true)}
                      fullWidth
                      sx={{ pt: 2, pb: 2, fontSize: 30 }}
                      variant="contained"
                    >
                      Mailbox
                    </Button>
                  </Grid>
                  {/* <Grid item xs={6}>
                    <Button
                      onClick={handleGoogleAuth}
                      fullWidth
                      sx={{ pt: 2, pb: 2 }}
                      variant="outlined"
                    >
                      <p>
                        Gmail OAuth &nbsp;
                        <span>(Coming Soon)</span>
                      </p>
                    </Button>
                  </Grid> */}
                </Grid>
              )}
              <Grid item xs={12}>
                {imap && (
                  <>
                    <Grid
                      container
                      spacing={2}
                      sx={{ alignItems: "center", textAlign: "center" }}
                    >
                      <Typography variant="h3" sx={{ mt: 3, mb: 2 }}>
                        Upload CSV
                      </Typography>
                      <Grid item xs={12}>
                        <TextField
                          type="file"
                          accept=".csv"
                          variant="outlined"
                          onChange={handleCSVUpload}
                          fullWidth
                        />
                      </Grid>
                      {csvData && (
                        <Grid
                          container
                          sx={{ alignItems: "center", textAlign: "center" }}
                        >
                          {/* <Grid item xs={6}>
                          <Typography variant="p">Email Address</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Select
                            value={fieldMappings.emailAddress}
                            onChange={(e) =>
                              handleMappingChange(
                                "emailAddress",
                                e.target.value
                              )
                            }
                            fullWidth
                          >
                            {csvData &&
                              csvData[0] &&
                              Object.keys(csvData[0]).map((column) => (
                                <MenuItem key={column} value={column}>
                                  {column}
                                </MenuItem>
                              ))}
                          </Select>
                        </Grid> */}
                          <Grid item xs={12}>
                            <Button variant="contained" onClick={handleUpload}>
                              Add Mailboxes
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    {!csvData && (
                      <form onSubmit={handleImapSubmit}>
                        <Grid
                          container
                          spacing={2}
                          sx={{ alignItems: "center", textAlign: "center" }}
                        >
                          {!csvData && (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              sx={{ alignItems: "center", textAlign: "center" }}
                            >
                              or
                            </Grid>
                          )}
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label="Email Address"
                              name="email"
                              fullWidth
                              value={formData.email}
                              onChange={handleImapChange}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label="First Name"
                              name="firstName"
                              fullWidth
                              value={formData.firstName}
                              onChange={handleImapChange}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label="Last Name"
                              name="lastName"
                              fullWidth
                              value={formData.lastName}
                              onChange={handleImapChange}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label="IMAP Server Hostname"
                              name="imapHost"
                              fullWidth
                              value={formData.imapHost}
                              onChange={handleImapChange}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label="IMAP Username"
                              name="imapUsername"
                              fullWidth
                              value={formData.imapUsername}
                              onChange={handleImapChange}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label="IMAP Password"
                              name="imapPassword"
                              type="password"
                              fullWidth
                              value={formData.imapPassword}
                              onChange={handleImapChange}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label="IMAP Port"
                              name="imapPort"
                              fullWidth
                              value={formData.imapPort}
                              onChange={handleImapChange}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label="SMTP Server Hostname"
                              name="smtpHost"
                              fullWidth
                              value={formData.smtpHost}
                              onChange={handleImapChange}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label="SMTP Username"
                              name="smtpUsername"
                              fullWidth
                              value={formData.smtpUsername}
                              onChange={handleImapChange}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label="SMTP Password"
                              name="smtpPassword"
                              type="password"
                              fullWidth
                              value={formData.smtpPassword}
                              onChange={handleImapChange}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label="SMTP Port"
                              name="smtpPort"
                              fullWidth
                              value={formData.smtpPort}
                              onChange={handleImapChange}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label="Signature"
                              name="signature"
                              fullWidth
                              multiline
                              value={formData.signature}
                              onChange={handleImapChange}
                              required
                            />
                          </Grid>
                        </Grid>
                        <Button
                          type="submit"
                          variant="contained"
                          style={{ marginTop: "16px" }}
                        >
                          Save Mailbox
                        </Button>
                      </form>
                    )}
                  </>
                )}
              </Grid>
            </Tooltip>
          </Grid>
        )}
        {gmail && (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Gmail Email Address"
                  variant="outlined"
                  value={gmailEmail}
                  onChange={(e) => setGmailEmail(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Gmail App Password"
                  variant="outlined"
                  value={gmailAppPassword}
                  onChange={(e) => setGmailAppPassword(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" fullWidth>
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>
    </Dialog>
  );
}

EmailAccountTypeForm.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
