// utils
import { paramCase } from "src/utils/change-case";
import { _id, _postTitles } from "src/_mock/assets";

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: "/auth",
  AUTH_DEMO: "/auth-demo",
  DASHBOARD: "/dashboard",
};

// ----------------------------------------------------------------------

export const paths = {
  auth: {
    firebase: {
      login: `${ROOTS.AUTH}/user/login`,
      verify: `${ROOTS.AUTH}/user/verify`,
      register: `${ROOTS.AUTH}/user/register`,
      forgotPassword: `${ROOTS.AUTH}/user/forgot-password`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      leads: `${ROOTS.DASHBOARD}/leads`,
      inbox: `${ROOTS.DASHBOARD}/inbox`,
      emailtemplate: `${ROOTS.DASHBOARD}/emailtemplate`,
      leadfinder: `${ROOTS.DASHBOARD}/leadfinder`,
      trigger: `${ROOTS.DASHBOARD}/trigger`,

      video: `${ROOTS.DASHBOARD}/video`,
      emailaccounts: `${ROOTS.DASHBOARD}/emailaccounts`,
      gmail: `${ROOTS.DASHBOARD}/gmail`,
      // linkedin: `${ROOTS.DASHBOARD}/linkedin`,
      // followups: `${ROOTS.DASHBOARD}/followups`,
    },
    user: {
      account: `${ROOTS.DASHBOARD}/user/account`,
    },
  },
};
