import { lazy } from "react";
import { Outlet } from "react-router-dom";
// auth
import { GuestGuard } from "src/auth/guard";
// layouts
import CompactLayout from "src/layouts/compact";
import AuthClassicLayout from "src/layouts/auth/classic";

// ----------------------------------------------------------------------

// AMPLIFY
const AmplifyLoginPage = lazy(() => import("src/pages/auth/amplify/login"));
const AmplifyRegisterPage = lazy(() =>
  import("src/pages/auth/amplify/register")
);
const AmplifyVerifyPage = lazy(() => import("src/pages/auth/amplify/verify"));
const AmplifyNewPasswordPage = lazy(() =>
  import("src/pages/auth/amplify/new-password")
);
const AmplifyForgotPasswordPage = lazy(() =>
  import("src/pages/auth/amplify/forgot-password")
);

// JWT
const JwtLoginPage = lazy(() => import("src/pages/auth/jwt/login"));
const JwtRegisterPage = lazy(() => import("src/pages/auth/jwt/register"));

// FIREBASE
const FirebaseLoginPage = lazy(() => import("src/pages/auth/firebase/login"));
const FirebaseRegisterPage = lazy(() =>
  import("src/pages/auth/firebase/register")
);
const FirebaseVerifyPage = lazy(() => import("src/pages/auth/firebase/verify"));
const FirebaseForgotPasswordPage = lazy(() =>
  import("src/pages/auth/firebase/forgot-password")
);

// AUTH0
const Auth0LoginPage = lazy(() => import("src/pages/auth/auth0/login"));
const Auth0Callback = lazy(() => import("src/pages/auth/auth0/callback"));

// ----------------------------------------------------------------------

const authFirebase = {
  path: "user",
  element: (
    <GuestGuard>
      <Outlet />
    </GuestGuard>
  ),
  children: [
    {
      path: "login",
      element: (
        <AuthClassicLayout>
          <FirebaseLoginPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: "register",
      element: (
        <AuthClassicLayout title="Reach Your Prospects">
          <FirebaseRegisterPage />
        </AuthClassicLayout>
      ),
    },
    {
      element: (
        <CompactLayout>
          <Outlet />
        </CompactLayout>
      ),
      children: [
        { path: "verify", element: <FirebaseVerifyPage /> },
        { path: "forgot-password", element: <FirebaseForgotPasswordPage /> },
      ],
    },
  ],
};

export const authRoutes = [
  {
    path: "auth",
    children: [authFirebase],
  },
];
