import PropTypes from "prop-types";
import * as Yup from "yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";

import CryptoJS from "crypto-js";

// @mui
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useSnackbar } from "src/components/snackbar";

import {
  addDocument,
  updateSingle,
} from "src/auth/context/firebase/auth-provider";
import { TextField, Typography } from "@mui/material";
import { useAuthContext } from "src/auth/hooks";
import { useNavigate } from "react-router";
import AccountGeneral from "src/sections/account/account-general";

// ----------------------------------------------------------------------

export default function OnboardingUserModalView({ open, onClose }) {
  const { user } = useAuthContext();
  const navigate = useNavigate();

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <DialogContent sx={{ m: 10 }}>
        <Typography
          variant="h6"
          sx={{
            pt: 3,
            borderRadius: "15px",
            textAlign: "center",
            mb: 3,
            pb: 3,
          }}
        >
          Add your details
        </Typography>
        <AccountGeneral onboarding={true} onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
}

OnboardingUserModalView.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
