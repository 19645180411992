import PropTypes from "prop-types";
import "src/utils/highlight";
import ReactQuill from "react-quill";
// @mui
import { alpha } from "@mui/material/styles";
//
import { StyledEditor } from "./styles";
import Toolbar, { formats } from "./toolbar"; // Assuming you still need the formats

export default function Editor({
  id = "minimal-quill",
  error,
  simple = true,
  helperText,
  sx,
  value,
  onChange,
  ...other
}) {
  const modules = {
    toolbar: false,
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
    syntax: true,
    clipboard: {
      matchVisual: false,
    },
  };

  return (
    <>
      <StyledEditor
        sx={{
          ...(error && {
            border: (theme) => `solid 1px ${theme.palette.error.main}`,
            "& .ql-editor": {
              bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
            },
          }),
          ...sx,
        }}
      >
        <ReactQuill
          value={value}
          onChange={onChange}
          modules={modules}
          formats={formats}
          placeholder="Write your reply..."
          {...other}
        />
      </StyledEditor>

      {helperText && helperText}
    </>
  );
}
