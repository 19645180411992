import React, { useState, useRef, useEffect, useMemo } from "react";
import { ReactMediaRecorder } from "react-media-recorder";
import {
  Button,
  Card,
  CircularProgress,
  Container,
  Grid,
  Tooltip,
  Typography,
  keyframes,
  styled,
} from "@mui/material";
import axios from "axios";
import {
  getSingle,
  uploadVideo,
  updateSingle,
  getMultiple,
  deleteSingle,
} from "src/auth/context/firebase/auth-provider";
import { useAuthContext } from "src/auth/hooks";
import { enqueueSnackbar } from "notistack";
import GoogleOAuth from "../user/view/gmail-api";
import EmailAccountTypeForm from "./emailaccount-type-form";
import { useBoolean } from "src/hooks/use-boolean";
import { Animate, AnimateKeyframes, AnimateGroup } from "react-simple-animate";

export default function EmailAccountsEditForm() {
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [emails, setEmails] = useState();
  const quickEdit = useBoolean();
  const [showToolTip, setShowToolTip] = useState(0);

  const getEmailAccounts = async () => {
    const emailaccounts = await getMultiple(
      "emailaccounts",
      "client",
      user?.client
    );
    setEmails(emailaccounts);
  };

  useEffect(() => {
    if (!emails) getEmailAccounts();
    function isOnboardingInURL() {
      const urlParams = new URLSearchParams(window.location.search);
      return (
        urlParams.has("onboarding") && urlParams.get("onboarding") === "true"
      );
    }

    // Example usage:
    if (isOnboardingInURL()) {
      console.log("Onboarding is enabled in the URL.");
      setShowToolTip(1);
    }
  }, [emails]);

  const updateEmailsAccounts = () => {
    quickEdit.onFalse();
    getEmailAccounts();
  };

  const removeEmail = async (email) => {
    const deleteEmail = await deleteSingle(
      "emailaccounts",
      email,
      "email",
      user?.client,
      "client"
    );
    const deleteAccessToken = await deleteSingle(
      "accesstoken",
      email,
      "email",
      user?.client,
      "client"
    );
    getEmailAccounts();
    getEmailAccounts();
    getEmailAccounts();
    enqueueSnackbar("Removed");
  };

  // const sendEmail = async () => {
  //   const url =
  //     "http://127.0.0.1:5001/video-outreach-773a2/us-central1/sendMailboxEmail";

  //   const getEmail = await getSingle(
  //     "accesstoken",
  //     "email",
  //     "thomas@hiseogrowth.com"
  //   );
  //   const formData = getEmail[0];
  //   console.log(formData, "formData");
  //   const response = await axios.post(url, {
  //     formData: formData,
  //     to: "tom@seogrowth.io",
  //     subject: "Meeting Tomorrow",
  //     html: "Hey just checking you're still free tomorrow?",
  //   });
  // };

  const testConnection = async (account) => {
    console.log(account, "ACCOUNT");
    try {
      const response = await axios.post(
        // "http://127.0.0.1:5001/video-outreach-773a2/us-central1/testMailboxLogin",
        "https://us-central1-video-outreach-773a2.cloudfunctions.net/testMailboxLogin",
        { formData: account }
      );
      console.log("Response:", response.data);
      if (response.data.message == "Login successful") {
        enqueueSnackbar("Success");
      } else {
        enqueueSnackbar("Error");
      }
    } catch (error) {
      console.error("Error occurred during login:", error);
      enqueueSnackbar(
        `Login failed. Please check your credentials and try again.`,
        { variant: "error" }
      );
    }
  };

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {/* <Button variant="contained" sx={{ m: 3 }} onClick={sendEmail}>
        Send Email
      </Button> */}
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Grid container spacing={2}>
            {emails &&
              emails.map((account) => (
                <Grid item xs={6}>
                  <Card sx={{ p: 3 }}>
                    <Typography variant="p">Email: {account.email}</Typography>
                    <br></br>
                    <Typography variant="p">Sent: {account.emails}</Typography>
                    <br></br>
                    <br></br>
                    <Grid container spacing={3}>
                      <Grid item sx={6}>
                        <Button
                          variant="contained"
                          onClick={() => removeEmail(account.email)}
                        >
                          Remove Email
                        </Button>
                      </Grid>
                      {account.type === "mailbox" && (
                        <Grid item sx={6}>
                          <Button
                            variant="outlined"
                            onClick={() => testConnection(account)}
                          >
                            Test Connection
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Card>
                </Grid>
              ))}
            <Grid
              item
              xs={6}
              style={{
                marginTop: "40px",
                verticalAlign: "center",
                textAlign: "center",
              }}
            >
              <Tooltip
                title={
                  <div style={{ padding: "20px" }}>
                    <Typography variant="h6">Click here</Typography>
                    <Typography>Let's connect your email account</Typography>
                  </div>
                }
                arrow
                open={
                  showToolTip === 1 && !user?.onboardingComplete ? true : false
                }
              >
                <Button
                  className="add-email-button"
                  variant="contained"
                  onClick={() => {
                    quickEdit.onTrue();
                    setShowToolTip(2);
                  }}
                >
                  Add Email Account
                </Button>
              </Tooltip>
            </Grid>
          </Grid>

          <EmailAccountTypeForm
            open={quickEdit.value}
            onClose={updateEmailsAccounts}
            showToolTip={showToolTip}
          />
        </>
      )}
    </Container>
  );
}
