import { useEffect, useCallback, useState } from "react";

import Container from "@mui/material/Container";
// routes
import { paths } from "src/routes/paths";
import { useParams } from "src/routes/hook";
// _mock
import { _userList } from "src/_mock";
// components
import { useSettingsContext } from "src/components/settings";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";

import EmailEditForm from "./video-edit-form";
import { Button } from "@mui/material";
import { useAuthContext } from "src/auth/hooks";
import VideoEditForm from "./video-edit-form";

// ----------------------------------------------------------------------

export default function VideoEditView() {
  const [preview, setPreview] = useState(false);
  const settings = useSettingsContext();

  const params = useParams();

  const { id } = params;

  const currentuser = useAuthContext();

  useEffect(() => {}, [currentuser]);

  return (
    <Container maxWidth={settings.themeStretch ? false : "lg"}>
      <CustomBreadcrumbs
        heading="Edit"
        links={[
          {
            name: "Dashboard",
            href: paths.dashboard.root,
          },
          {
            name: "Video",
            // href: paths.dashboard.user.root,
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <VideoEditForm currentUser={currentuser?.user} preview={preview} />
    </Container>
  );
}
