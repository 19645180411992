import { CircularProgress } from "@material-ui/core";
import { Stack } from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import {
  addDocument,
  getSingle,
  updateSingle,
} from "src/auth/context/firebase/auth-provider";
import { useAuthContext } from "src/auth/hooks";

export const sendEmailFromOutlook = async (
  message,
  email,
  subject,
  leadEmail
) => {
  const accessToken = await getSingle("accesstoken", "email", email);
  console.log(message, email, subject, email, "message");
  try {
    const response = await fetch(
      "https://graph.microsoft.com/v1.0/me/sendMail",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken[0].accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(message),
      }
    );

    if (response.status === 202) {
      const searchQuery = `subject:${subject} from:"${email}"`;

      const searchEmailResponse = await fetch(
        `https://graph.microsoft.com/v1.0/me/messages?$search="to:${leadEmail}"`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken[0].accessToken}`,
          },
        }
      );

      if (searchEmailResponse.status === 200) {
        const emailSearchResult = await searchEmailResponse.json();
        console.log(emailSearchResult.value[0]);

        if (emailSearchResult.value.length > 0) {
          // Assuming you found the sent email, extract the conversation ID
          const conversationId = emailSearchResult.value[0].conversationId;

          return {
            success: true,
            message: "Email sent successfully.",
            conversationID: conversationId,
          };
        } else {
          return {
            success: false,
            message: "Sent email not found in the mailbox.",
          };
        }
      } else {
        return {
          success: false,
          message: `Failed to search for emails: ${searchEmailResponse.status}`,
        };
      }

      // const emailsData = await response.json();

      // // Find the sent email in the list by comparing subjects or other criteria
      // const sentEmail = emailsData.value.find(
      //   (email) => email.subject === "Your Email Subject"
      // );
      // const responseData = await response;
      // console.log(sentEmail, "sentEmail");
      // console.log(responseData, "responseData");
      // const messageId = responseData.Id;
      // return {
      //   success: true,
      //   message: "Email sent successfully.",
      //   messageID: messageId,
      // };
    } else {
      return {
        success: false,
        message: `Failed to send email: ${response.status}`,
      };
    }
  } catch (error) {
    console.error("Error sending email:", error);
  }
};

const OutlookOAuth = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code) {
      callFirebaseFunction(code);
    }
  }, []);
  const callFirebaseFunction = async (authorizationCode) => {
    try {
      if (authorizationCode) {
        console.log(authorizationCode, "authorizationCode");
        // const firebaseFunctionURL =
        // "http://127.0.0.1:5001/video-outreach-773a2/us-central1/getTokens";
        const firebaseFunctionURL =
          "https://us-central1-video-outreach-773a2.cloudfunctions.net/getTokens";
        // Define the data to send in the request body.
        const requestData = {
          authCode: authorizationCode,
        };

        // Make a POST request to your Firebase Function.
        const response = await axios.post(firebaseFunctionURL, requestData);

        const tokens = response.data;
        console.log(tokens, "tokens");
        if (tokens) {
          const expirationInSeconds = 3600;
          const currentTimestampInSeconds = Math.floor(Date.now() / 1000); // Current Unix timestamp in seconds

          const expirationTimestamp =
            currentTimestampInSeconds + expirationInSeconds;
          const getEmail = await getEmailAddress(tokens.access_token);
          if (getEmail) {
            await addDocument("accesstoken", "", {
              type: "outlook",
              accessToken: tokens.access_token ? tokens.access_token : "",
              refreshToken: tokens.refresh_token ? tokens.refresh_token : "",
              tokenExpirationTimeInSeconds: expirationTimestamp,
              email: getEmail,
              client: user?.client,
            });
            await addDocument("emailaccounts", "", {
              email: getEmail,
              client: user?.client,
              date: new Date(),
              emails: 0,
              type: "outlook",
            });
          }
          const getOnboarding = () => getSingle("users", email, user?.email);
          const onboardingStep = getOnboarding[0].onboarding;
          if (onboardingStep == "add email") {
            const update = updateSingle("users", email, user?.email, {
              onboarding: "email template",
            });
            navigate("/dashboard/emailtemplate?onboarding=true");
          } else {
            navigate("/dashboard");
          }
        }
      }
    } catch (error) {
      // Handle errors here.
      console.error("Error:", error);
    }
  };

  async function getEmailAddress(accessToken) {
    try {
      console.log(accessToken);
      const response = await axios.get("https://graph.microsoft.com/v1.0/me", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        console.log(response.data, "DATA");
        const { mail } = response.data;
        return mail;
      } else {
        console.error(
          "Failed to retrieve email address:",
          response.status,
          response.statusText
        );
        return null;
      }
    } catch (error) {
      console.error("Error retrieving email address:", error.message);
      return null;
    }
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh", // Make the container full height
      }}
    >
      <Stack sx={{ alignItems: "center", textAlign: "center" }}>
        <CircularProgress color="green" />
        <p>Checking Credentials...</p>
      </Stack>
    </div>
  );
};

export default OutlookOAuth;
