import { useMemo } from "react";
// routes
import { paths } from "src/routes/paths";
// locales
import { useLocales } from "src/locales";
// components
import Label from "src/components/label";
import Iconify from "src/components/iconify";
import SvgColor from "src/components/svg-color";
import { useAuthContext } from "src/auth/hooks";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const iconify = (name) => (
  <Iconify icon={name} />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon("ic_job"),
  blog: icon("ic_blog"),
  chat: icon("ic_chat"),
  heart: icon("ic_heart"),
  mail: icon("ic_mail"),
  user: icon("ic_user"),
  file: icon("ic_file"),
  lock: icon("ic_lock"),
  tour: icon("ic_tour"),
  order: icon("ic_order"),
  label: icon("ic_label"),
  blank: icon("ic_blank"),
  kanban: icon("ic_kanban"),
  folder: icon("ic_folder"),
  banking: icon("ic_banking"),
  booking: icon("ic_booking"),
  invoice: icon("ic_invoice"),
  product: icon("ic_product"),
  calendar: icon("ic_calendar"),
  disabled: icon("ic_disabled"),
  external: icon("ic_external"),
  menuItem: icon("ic_menu_item"),
  ecommerce: icon("ic_ecommerce"),
  analytics: icon("ic_analytics"),
  dashboard: icon("ic_dashboard"),
  planning: iconify("grommet-icons:plan"),
  design: iconify("material-symbols:design-services"),
  linkedin: iconify("mdi:linkedin"),
  video: iconify("carbon:video"),
  emailaccount: iconify("carbon:account"),
  inbox: iconify("teenyicons:inbox-solid"),
  leads: iconify("mdi:leads"),
  trigger: iconify("grommet-icons:trigger"),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();
  const { user } = useAuthContext();

  const data = useMemo(() => {
    const navItems = [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t("overview"),
        items: [
          {
            title: t("home"),
            path: paths.dashboard.root,
            icon: ICONS.dashboard,
          },
          {
            title: t("leads"),
            path: paths.dashboard.general.leads,
            icon: ICONS.job,
          },
          {
            title: t("email template"),
            path: paths.dashboard.general.emailtemplate,
            icon: ICONS.mail,
          },

          {
            title: t("video"),
            path: paths.dashboard.general.video,
            icon: ICONS.video,
          },
          {
            title: t("email accounts"),
            path: paths.dashboard.general.emailaccounts,
            icon: ICONS.emailaccount,
          },
          {
            title: t("triggers"),
            path: paths.dashboard.general.trigger,
            icon: ICONS.trigger,
          },
        ],
      },
    ];

    // Add 'inbox' item conditionally based on user's premium status
    if (user?.premium === "infinity") {
      navItems[0].items.push({
        title: t("inbox"),
        path: paths.dashboard.general.inbox,
        icon: ICONS.inbox,
      });
    }

    // if (
    //   user?.premium === "infinity" ||
    //   user?.premium === "scale" ||
    //   user?.premium === "starter"
    // ) {
    navItems[0].items.push({
      title: t("lead finder"),
      path: paths.dashboard.general.leadfinder,
      icon: ICONS.leads,
    });
    // }

    // Add user item
    navItems[0].items.push({
      title: t("user"),
      path: paths.dashboard.user.account,
      icon: ICONS.user,
    });

    return navItems;
  }, [t, user]);

  return data;
}
