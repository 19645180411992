import PropTypes from "prop-types";
import * as Yup from "yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";

import CryptoJS from "crypto-js";

// @mui
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useSnackbar } from "src/components/snackbar";

import {
  addDocument,
  updateSingle,
} from "src/auth/context/firebase/auth-provider";
import { TextField, Typography } from "@mui/material";
import { useAuthContext } from "src/auth/hooks";
import { useNavigate } from "react-router";

// ----------------------------------------------------------------------

export default function OnboardingModalView({ open, onClose }) {
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const teachMyself = () => {
    console.log(user?.client, "user?.client");
    const update = updateSingle("users", "client", user?.client, {
      onboardingComplete: true,
      onboarding: "complete",
    });
    onClose();
  };

  const startOnboarding = () => {
    const update = updateSingle("users", "client", user?.client, {
      onboarding: "add email",
    });
    navigate("/dashboard/emailaccounts?onboarding=true");
  };

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <DialogContent sx={{ m: 10 }}>
        <Typography
          variant="h6"
          style={{ border: "1px solid #133A26" }}
          sx={{
            pt: 3,
            borderRadius: "15px",
            textAlign: "center",
            mb: 3,
            pb: 3,
          }}
        >
          Welcome to Outreach Hippo, we'd love you<br></br> to learn more about
          our platform
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Button
              fullWidth
              sx={{ pt: 2, pb: 2, fontSize: 30 }}
              variant="contained"
              onClick={startOnboarding}
            >
              Start Setup
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              sx={{ pt: 2, pb: 2, fontSize: 30 }}
              variant="outlined"
              onClick={teachMyself}
            >
              Teach Myself
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

OnboardingModalView.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
