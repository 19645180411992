import PropTypes from "prop-types";
import { useEffect, useReducer, useCallback, useMemo } from "react";
import { initializeApp } from "firebase/app";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getStorage,
  getMetadata,
} from "firebase/storage";
import {
  getAuth,
  signOut,
  signInWithPopup,
  onAuthStateChanged,
  GoogleAuthProvider,
  GithubAuthProvider,
  TwitterAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import uuidv4 from "src/utils/uuidv4";
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  getDocs,
  deleteDoc,
  addDoc,
  where,
  query,
  onSnapshot,
} from "firebase/firestore";
import "firebase/storage"; // Import Firebase Storage

// config
import { FIREBASE_API } from "src/config-global";
//
import { AuthContext, BusinessContext } from "./auth-context";
import moment from "moment";

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);

export const DB = getFirestore(firebaseApp);

export const refStorage = getStorage(firebaseApp);

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === "INITIAL") {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(() => {
    try {
      onAuthStateChanged(AUTH, async (user) => {
        if (user) {
          const userProfile = doc(DB, "users", user.uid);

          const docSnap = await getDoc(userProfile);

          const profile = docSnap.data();

          dispatch({
            type: "INITIAL",
            payload: {
              user: {
                ...user,
                ...profile,
                id: user.uid,
                role: "admin",
              },
            },
          });
        } else {
          dispatch({
            type: "INITIAL",
            payload: {
              user: null,
            },
          });
        }
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: "INITIAL",
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    await signInWithEmailAndPassword(AUTH, email, password);
  }, []);

  const loginWithGoogle = useCallback(async () => {
    const provider = new GoogleAuthProvider();

    await signInWithPopup(AUTH, provider);
  }, []);

  const loginWithGithub = useCallback(async () => {
    const provider = new GithubAuthProvider();

    await signInWithPopup(AUTH, provider);
  }, []);

  const loginWithTwitter = useCallback(async () => {
    const provider = new TwitterAuthProvider();

    await signInWithPopup(AUTH, provider);
  }, []);

  // REGISTER
  const register = useCallback(
    async (email, password, firstName, lastName, businessName, appSumo) => {
      console.log(appSumo, "appSumo");
      const newUser = await createUserWithEmailAndPassword(
        AUTH,
        email,
        password
      );

      // await sendEmailVerification(newUser.user);

      const userProfile = doc(collection(DB, "users"), newUser.user?.uid);

      const newId = uuidv4();

      var userData;
      if (appSumo) {
        userData = {
          uid: newUser.user?.uid,
          email,
          displayName: `${firstName} ${lastName}`,
          client: businessName.replace(/\s/g, "").toLowerCase(),
          premium: "starter",
          role: "user",
          onboardingComplete: false,
          onboarding: "welcome",
          appSumo: appSumo,
        };
      } else {
        userData = {
          uid: newUser.user?.uid,
          email,
          displayName: `${firstName} ${lastName}`,
          client: businessName.replace(/\s/g, "").toLowerCase(),
          premium: "",
          role: "user",
          onboardingComplete: false,
          onboarding: "welcome",
        };
      }

      await setDoc(userProfile, userData);

      const businessProfile = collection(DB, "business");

      await addDoc(businessProfile, {
        url: "",
        businessName: businessName,
        businessContact: firstName,
        businessContactTitle: "",
        calendarLink: "",
        customDomain: "",
        businessLogo: "",
        profilephoto: "",
        businessEmail: email,
        client: businessName.replace(/\s/g, "").toLowerCase(),
      });

      const video = collection(DB, "video");

      await addDoc(video, {
        url: "",
        client: businessName.replace(/\s/g, "").toLowerCase(),
      });

      const accessToken = collection(DB, "accesstoken");

      await addDoc(accessToken, {
        client: businessName.replace(/\s/g, "").toLowerCase(),
      });

      const emailTemplate = collection(DB, "emailtemplate");

      await addDoc(emailTemplate, {
        // campaignID: id,
        campaignFlowStep: "Initial Email",
        client: businessName.replace(/\s/g, "").toLowerCase(),
        subject: "Creating your first Video Email",
        emailbody: `<p>Hey ${firstName}<br><br>Welcome to your first email template!<br><br>This is a test template so you understand how your email will look</p>{{screenshot}}`,
        signature: `<p>—</p><p>${firstName} ${lastName}</p><p>Managing Director</p><p>${businessName}<br></p>`,
      });

      const campaigns = collection(DB, "campaigns");

      await addDoc(campaigns, {
        client: businessName.replace(/\s/g, "").toLowerCase(),
        id: newId,
        name: "Initial Campaign",
        flow: ["Initial Email"],
      });

      const flows = collection(DB, "flows");

      await addDoc(flows, {
        client: businessName.replace(/\s/g, "").toLowerCase(),
        flow: ["Initial Email"],
      });

      const lead = collection(DB, "leads");

      await addDoc(lead, {
        client: businessName.replace(/\s/g, "").toLowerCase(),
        screenshot:
          "https://firebasestorage.googleapis.com/v0/b/video-outreach-773a2.appspot.com/o/screenshots%2Fscreenshot_1706782812857.png?alt=media",
        preview:
          "https://firebasestorage.googleapis.com/v0/b/video-outreach-773a2.appspot.com/o/screenshots%2Fscreenshot_1705294800684.png?alt=media",
        leadWebsite: "https://www.outreachhippo.com/",
        leadName: "Tom",
        leadBusiness: "Outreach Hippo",
        leadID: "tom-outreach-hippo-app",
        leadEmail: "tom@outreachhippo.com",
        date: new Date(),
      });
    },
    []
  );

  // LOGOUT
  const logout = useCallback(async () => {
    await signOut(AUTH);
  }, []);

  // FORGOT PASSWORD
  const forgotPassword = useCallback(async (email) => {
    await sendPasswordResetEmail(AUTH, email);
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? "authenticated" : "unauthenticated";

  const status = state.loading ? "loading" : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: "firebase",
      loading: status === "loading",
      authenticated: status === "authenticated",
      unauthenticated: status === "unauthenticated",
      //
      login,
      logout,
      register,
      forgotPassword,
      loginWithGoogle,
      loginWithGithub,
      loginWithTwitter,
    }),
    [
      status,
      state.user,
      //
      login,
      logout,
      register,
      forgotPassword,
      loginWithGithub,
      loginWithGoogle,
      loginWithTwitter,
    ]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export async function updateSingleByDocID(col, docId, updateData) {
  const documentRef = doc(collection(DB, col), docId);

  try {
    await updateDoc(documentRef, updateData);
  } catch (error) {
    console.error("Error updating document:", error);
  }
}

export function getAllRealtime(col, callback) {
  const collectionRef = collection(DB, col);

  const unsubscribe = onSnapshot(
    collectionRef,
    (querySnapshot) => {
      const results = [];
      querySnapshot.forEach((doc) => {
        results.push(doc.data());
      });

      callback(results);
    },
    (error) => {
      console.error("Error fetching data from Firestore:", error);
    }
  );

  return unsubscribe;
}

export const getDocumentDocID = async (collectionName, documentId) => {
  try {
    const documentRef = doc(DB, collectionName, documentId);
    const documentSnapshot = await getDoc(documentRef);

    if (documentSnapshot.exists()) {
      const data = documentSnapshot.data();
      return data;
    } else {
      console.error(`Document with ID ${documentId} does not exist.`);
      return null; // Return a default value (e.g., null) when the document doesn't exist
    }
  } catch (error) {
    console.error("Error fetching document:", error);
    return null; // Return a default value (e.g., null) when an error occurs
  }
};

export function getDocumentRealtime(col, docId, callback) {
  const documentRef = doc(collection(DB, col), docId);

  const unsubscribe = onSnapshot(
    documentRef,
    (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        callback(data);
      } else {
        console.error(`Document with ID ${docId} does not exist.`);
      }
    },
    (error) => {
      console.error(
        `Error fetching data for document with ID ${docId}:`,
        error
      );
    }
  );

  return unsubscribe;
}

export async function updateSingle(col, whereQuery, id, updateData) {
  const collectionRef = collection(DB, col);

  // Create a query to find the document with the matching UID and ID
  const q = query(collectionRef, where(whereQuery, "==", id));

  try {
    const querySnapshot = await getDocs(q);

    // Iterate over the query results
    querySnapshot.forEach(async (docSnapshot) => {
      await updateDoc(doc(collectionRef, docSnapshot.id), updateData);
    });
  } catch (error) {
    console.error("Error updating documents:", error);
  }
}

export async function updateOrCreateSingle(col, whereQuery, id, updateData) {
  const collectionRef = collection(DB, col);

  // Create a query to find the document with the matching UID and ID
  const q = query(collectionRef, where(whereQuery, "==", id));

  try {
    const querySnapshot = await getDocs(q);

    // If the document doesn't exist, create a new one
    if (querySnapshot.empty) {
      await addDoc(collectionRef, { ...updateData, [whereQuery]: id });
    } else {
      // Iterate over the query results and update each document
      querySnapshot.forEach(async (docSnapshot) => {
        await updateDoc(doc(collectionRef, docSnapshot.id), updateData);
      });
    }
  } catch (error) {
    console.error("Error updating or creating document:", error);
  }
}
export async function updateOrCreateSingleDetailed(
  col,
  whereQuery,
  id,
  updateData,
  whereQuery2,
  id2
) {
  const collectionRef = collection(DB, col);

  // Create a query to find the document with the matching UID and ID
  const q = query(
    collectionRef,
    where(whereQuery, "==", id),
    where(whereQuery2, "==", id2)
  );

  try {
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      // Document doesn't exist, so add it
      await addDoc(collectionRef, {
        ...updateData,
        [whereQuery]: id,
        [whereQuery2]: id2,
      });
    } else {
      // Document exists, so update it
      querySnapshot.forEach(async (docSnapshot) => {
        await updateDoc(doc(collectionRef, docSnapshot.id), updateData);
      });
    }
  } catch (error) {
    console.error("Error updating documents:", error);
  }
}

export async function updateSingleDetailed(
  col,
  whereQuery,
  id,
  updateData,
  whereQuery2,
  id2
) {
  const collectionRef = collection(DB, col);

  // Create a query to find the document with the matching UID and ID
  const q = query(
    collectionRef,
    where(whereQuery, "==", id),
    where(whereQuery2, "==", id2)
  );

  try {
    const querySnapshot = await getDocs(q);

    // Iterate over the query results
    querySnapshot.forEach(async (docSnapshot) => {
      await updateDoc(doc(collectionRef, docSnapshot.id), updateData);
    });
  } catch (error) {
    console.error("Error updating documents:", error);
  }
}

export async function updateTimeWatched(col, whereQuery, id, newData) {
  const collectionRef = collection(DB, col);

  // Create a query to find the document with the matching UID and ID
  const q = query(collectionRef, where(whereQuery, "==", id));

  try {
    const querySnapshot = await getDocs(q);

    const leadDoc = querySnapshot.docs[0];

    if (leadDoc) {
      // Merge the existing data with the new data
      const existingData = leadDoc.data();
      if (!existingData.timewatched) {
        const data = {
          0: newData,
        };

        const updatedData = {
          timewatched: { ...existingData.timewatched, ...data },
        };
        querySnapshot.forEach(async (docSnapshot) => {
          await updateDoc(doc(collectionRef, docSnapshot.id), updatedData, {
            merge: true,
          });
        });
      } else {
        let nextNumber = 0;
        let foundNumber = false;
        // let document = null;

        const docAtIndex = querySnapshot.docs[nextNumber];

        while (!foundNumber && nextNumber <= 999) {
          if (docAtIndex && docAtIndex.data().timewatched[nextNumber]) {
            nextNumber += 1;
          } else {
            // leadDoc = docAtIndex;
            foundNumber = true;
          }
        }
        const data = {
          [nextNumber]: newData,
        };

        const updatedData = {
          timewatched: { ...existingData.timewatched, ...data },
        };
        querySnapshot.forEach(async (docSnapshot) => {
          await updateDoc(doc(collectionRef, docSnapshot.id), updatedData, {
            merge: true,
          });
        });

        console.log("Data added to the lead successfully!");
      }
    } else {
      console.log("Lead document not found!");
    }
  } catch (error) {
    console.error("Error updating documents:", error);
  }
}

export async function updateUserDocument(col, whereQuery, id, updateData) {
  const documentRef = doc(DB, col, id);
  try {
    await updateDoc(documentRef, updateData);
    return "Document updated successfully"; // Return a success message or any desired value
  } catch (error) {
    console.error("Error updating document:", error);
    throw error; // Throw the error to be caught by the caller
  }
}

export async function addDocument(col, user, data) {
  const dbRef = collection(DB, col);
  return new Promise((resolve, reject) => {
    addDoc(dbRef, data)
      .then((docRef) => {
        resolve(docRef);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}

export async function getMultiple(col, whereQuery, uid) {
  const q = query(collection(DB, col), where(whereQuery, "==", uid));

  const snapshot = await getDocs(q);
  const results = [];
  snapshot.forEach((document) => {
    results.push(document.data());
  });
  return results;
}

export async function getMultipleDetailed(
  col,
  whereQuery,
  uid,
  whereQuery2,
  uid2
) {
  const q = query(collection(DB, col), where(whereQuery, "==", uid));

  const snapshot = await getDocs(q);
  const results = [];
  snapshot.forEach((document) => {
    if (whereQuery2 == uid2) {
      results.push(document.data());
    }
  });
  return results;
}

export async function getConversations(col, whereQuery, uid) {
  const q = query(collection(DB, col), where(whereQuery, "==", uid));

  const snapshot = await getDocs(q);
  const results = [];
  snapshot.forEach((document) => {
    const data = document.data();
    // Check if the document has a 'conversation' field
    if ("conversation" in data) {
      results.push(data);
    }
  });
  return results;
}

export async function getAll(col, whereQuery, uid) {
  const q = query(collection(DB, col));

  const snapshot = await getDocs(q);
  const results = [];
  snapshot.forEach((document) => {
    results.push(document.data());
  });
  return results;
}

export async function getAllFollowUps(col) {
  const q = query(
    collection(DB, col),
    where("account", "==", "gmail") // Filter by account = "gmail"
  );

  const snapshot = await getDocs(q);
  const results = [];

  // Calculate the cutoff date (72 hours ago)
  const cutoffDate = moment().subtract(48, "hours");

  snapshot.forEach((document) => {
    // results.push(document.data());
    const data = document.data();

    if (data.dateEmailed && !data.followedUp) {
      const firestoreTimestamp = new Date(
        data.dateEmailed.seconds * 1000 + data.dateEmailed.nanoseconds / 1000000
      );

      // Check if the date is more than 72 hours ago
      const followUpDate = moment(firestoreTimestamp);
      if (followUpDate.isBefore(cutoffDate)) {
        results.push(data);
      }
    }
  });
  return results;
}

export async function getMultipleDemoID(col, whereQuery, uid) {
  const q = query(collection(DB, col), where("demoid", "==", uid));
  const snapshot = await getDocs(q);
  const results = [];
  snapshot.forEach((document) => {
    results.push(document.data());
  });
  return results;
}

export async function getSingle(col, wherequery, id) {
  const snapshot = await getDocs(collection(DB, col));
  const results = [];

  snapshot.forEach((documents) => {
    if (documents.data()[wherequery] === id) {
      results.push(documents.data());
    }
  });

  return results;
}

export async function getSingleDetailed(col, wherequery, id, wherequery2, id2) {
  const snapshot = await getDocs(collection(DB, col));
  const results = [];

  snapshot.forEach((documents) => {
    if (
      documents.data()[wherequery] === id &&
      documents.data()[wherequery2] === id2
    ) {
      results.push(documents.data());
    }
  });

  return results;
}

export async function getUniqueDemoID(col, wherequery, id) {
  const snapshot = await getDocs(
    collection(DB, col),
    where(wherequery, "===", id)
  );
  const results = [];
  snapshot.forEach((docs) => {
    if (docs.data().id === id) {
      results.push(docs.data());
    }
  });
  return results;
}

export async function getUniqueLeadId(col, wherequery, id) {
  const snapshot = await getDocs(
    collection(DB, col),
    where(wherequery, "===", id)
  );
  const results = [];
  snapshot.forEach((docs) => {
    if (docs.data().leadid === id) {
      results.push(docs.data());
    }
  });
  return results;
}

export async function deleteSingle(col, uid, whereQuery, uid2, whereQuery2) {
  console.log(col, uid, whereQuery, uid2, whereQuery2, "col, uid, whereQuery");
  const collectionRef = collection(DB, col);

  // Create a query to find the document with the matching URL
  const q = query(
    collectionRef,
    where(whereQuery, "==", uid),
    where(whereQuery2, "==", uid2)
  );

  let deleted = false;
  console.log(deleted, "DELETED");

  try {
    const querySnapshot = await getDocs(q);
    // Iterate over the query results
    querySnapshot.forEach(async (docSnapshot) => {
      console.log(docSnapshot, "DOC SNAPSHOT");
      // Delete each matching document
      await deleteDoc(doc(collectionRef, docSnapshot.id));
      deleted = true; // Set the deleted flag to true if at least one document was deleted
    });
  } catch (error) {
    console.error("Error deleting documents:", error);
  }

  return deleted;
}

export function uploadMedia(isWhere, col, file, type, id) {
  return new Promise((resolve, reject) => {
    const storageRef = ref(refStorage, `/files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        // Handle progress if needed
      },
      (err) => {
        console.log(err);
        reject(err);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

          const collectionRef = collection(DB, col);
          // const q = query(collectionRef, where('demoid', '==', demoid));

          if (id) {
            const q = query(collectionRef, where(isWhere, "==", id));

            try {
              const querySnapshot = await getDocs(q);

              if (!querySnapshot.empty) {
                // Document exists, update it
                querySnapshot.forEach(async (docSnapshot) => {
                  // Update each matching document to remove the URL field
                  await updateDoc(doc(collectionRef, docSnapshot.id), {
                    [type]: downloadURL,
                  });
                });
              } else {
                // Document doesn't exist, create it
                const userProfile = doc(collection(DB, col));

                await setDoc(userProfile, {
                  [type]: downloadURL,
                });
              }
            } catch (error) {
              console.error("Error querying documents:", error);
            }
          } else {
            console.error("demoid is undefined");
          }

          resolve(downloadURL);
        } catch (error) {
          console.error(error);
          reject(error);
        }
      }
    );
  });
}

export function uploadJustMedia(file) {
  return new Promise((resolve, reject) => {
    const storageRef = ref(refStorage, `/files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        // Handle progress if needed
      },
      (err) => {
        console.log(err);
        reject(err);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        } catch (error) {
          console.error(error);
          reject(error);
        }
      }
    );
  });
}

export async function getLogo(col, isWhere, id) {
  const snapshot = await getDocs(collection(DB, col), where(isWhere, "==", id));
  const results = [];
  snapshot.forEach((docs) => {
    if (docs.data().isWhere === id) {
      results.push(docs.data());
    }
  });
  return results[0];
}

export async function deleteLogo(col, isWhere, type, id) {
  const collectionRef = collection(DB, col);

  // Create a query to find the document with the matching criteria
  const q = query(collectionRef, where(isWhere, "==", id));

  try {
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach(async (docSnapshot) => {
      await updateDoc(doc(collectionRef, docSnapshot.id), {
        [type]: null,
      });
    });
  } catch (error) {
    console.error("Error deleting type field:", error);
  }
}

export async function getProfilePhoto(demoid) {
  const collectionRef = collection(DB, "business");

  // Create a query to find the document with the matching URL
  const q = query(collectionRef, where("demoid", "==", demoid));
  const results = [];
  try {
    const querySnapshot = await getDocs(q);

    // Iterate over the query results
    querySnapshot.forEach(async (docSnapshot) => {
      results.push(docSnapshot.data());
    });
  } catch (error) {
    console.error("Error:", error);
  }
  return results[0];
}

export async function deleteProfilePhoto(url) {
  const collectionRef = collection(DB, "business");

  // Create a query to find the document with the matching URL
  const q = query(collectionRef, where("url", "==", url));

  try {
    const querySnapshot = await getDocs(q);

    // Iterate over the query results
    querySnapshot.forEach(async (docSnapshot) => {
      // Update each matching document to remove the URL field
      await updateDoc(doc(collectionRef, docSnapshot.id), {
        url: null, // Set the URL field to null
        // or use deleteField to remove the URL field altogether:
        // url: deleteField()
      });
    });
  } catch (error) {
    console.error("Error removing URLs:", error);
  }
}

export function uploadVideo(where, col, file, id) {
  console.log(where, col, file, id, "where, col, file, id");
  return new Promise((resolve, reject) => {
    const storageRef = ref(refStorage, `videos/${id}`); // Update the path to include "videos"
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        // Handle progress if needed
      },
      (err) => {
        console.log(err);
        reject(err);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          console.log(downloadURL, "downloadURL");
          await updateSingle("video", "client", id, { url: downloadURL });

          resolve(downloadURL);
        } catch (error) {
          console.error(error);
          reject(error);
        }
      }
    );
  });
}

export async function getVideoLength(videoURL) {
  const videoPath = videoURL;

  // Get a reference to the video file in Firebase Storage
  const storageRef = ref(refStorage, videoURL);

  // Fetch the metadata of the video file
  getMetadata(storageRef)
    .then((metadata) => {
      console.log(metadata, "metadata");
      // Access the duration in milliseconds
      const durationInMilliseconds = metadata.customMetadata.durationMillis;

      // Convert the duration to seconds
      const durationInSeconds = durationInMilliseconds / 1000;

      console.log(durationInSeconds);
    })
    .catch((error) => {
      console.error("Error fetching video metadata:", error);
    });
}
