import isEqual from "lodash/isEqual";
import { useState, useCallback, useEffect } from "react";
import axios from "axios";
// @mui
import Papa from "papaparse";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Container from "@mui/material/Container";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import TableContainer from "@mui/material/TableContainer";
import {
  Box,
  CircularProgress,
  LinearProgress,
  TextField,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
// routes
import { paths } from "src/routes/paths";
import { useRouter } from "src/routes/hook";
import { RouterLink } from "src/routes/components";
// _mock
import { _userList, _roles, USER_STATUS_OPTIONS } from "src/_mock";
// hooks
import { useAuthContext } from "src/auth/hooks";
import { useBoolean } from "src/hooks/use-boolean";
import {
  addDocument,
  getAll,
  getMultiple,
  getSingle,
  getSingleDetailed,
  updateSingle,
  updateSingleDetailed,
  uploadJustMedia,
  uploadMedia,
} from "src/auth/context/firebase/auth-provider";
// components
import Label from "src/components/label";
import Iconify from "src/components/iconify";
import Scrollbar from "src/components/scrollbar";
import { ConfirmDialog } from "src/components/custom-dialog";
import { useSettingsContext } from "src/components/settings";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from "src/components/table";
//

import LeadFinderTableToolbar from "./lead-finder-table-toolbar";
import LeadFinderTableRow from "./lead-finder-table-row";

// ----------------------------------------------------------------------

export const LEAD_STATUS_OPTIONS = [{ value: "emailed", label: "Emailed" }];

const STATUS_OPTIONS = [{ value: "all", label: "All" }, ...LEAD_STATUS_OPTIONS];

const defaultFilters = {
  name: "",
  role: [],
  status: "all",
};

// ----------------------------------------------------------------------

export default function LeadFinderListView({ data, loadMoreData, loading }) {
  const TABLE_HEAD = [
    { id: "selected", label: " " },
    { id: "name", label: "Lead Name" },
    { id: "headline", label: "Job Title" },
    { id: "organization", label: "Orginisation" },
    { id: "email_status", label: "Email Verified" },
    // { id: "" },
  ];
  const TABLE_HEAD_INFINITY = [
    { id: "selected", label: " " },
    { id: "name", label: "Lead Name" },
    { id: "headline", label: "Job Title" },
    { id: "organization", label: "Orginisation" },
    { id: "email_status", label: "Email Verified" },
    // { id: "" },
  ];
  const currentuser = useAuthContext();
  const { user } = useAuthContext();

  const table = useTable();

  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    setTableData(data);
  }, [tableData, data]);

  const [filters, setFilters] = useState(defaultFilters);
  const [totalLeads, setTotalLeads] = useState(data.length);

  var dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage =
    dataFiltered &&
    dataFiltered.slice(
      table.page * table.rowsPerPage,
      table.page * table.rowsPerPage + table.rowsPerPage
    );

  const denseHeight = table.dense ? 52 : 72;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound =
    !dataFiltered ||
    (!dataFiltered?.length && canReset) ||
    !dataFiltered?.length;

  const handleDeleteRows = useCallback(() => {
    const deleteRows =
      tableData && tableData.filter((row) => !table.selected.includes(row.id));
    setTableData(deleteRows);

    const totalRows = tableData ? tableData?.length : 0;
    const totalRowsInPage = dataInPage ? dataInPage?.length : 0;
    const totalRowsFiltered = dataFiltered ? dataFiltered?.length : 0;

    table.onUpdatePageDeleteRows({
      totalRows,
      totalRowsInPage,
      totalRowsFiltered,
    });
  }, [dataFiltered, dataInPage, table, tableData]);

  table.rowsPerPage = totalLeads;

  const addLeads = async () => {
    console.log(table.selected);
    enqueueSnackbar("Adding Leads...");
    const getBusinessURL = await getSingle("business", "client", user?.client);
    const businessURL = getBusinessURL[0].customDomain;
    table.selected.forEach(async (selectedId) => {
      const matchingRow = dataFiltered.find((row) => row.id === selectedId);

      if (matchingRow) {
        console.log("Matching row found with id:", selectedId, matchingRow);

        const requestData = {
          api_key: "e0g0KL88pwCcCdnz5g3Nig",
          id: matchingRow.id,
          first_name: matchingRow.first_name,
          domain: matchingRow.domain,
          reveal_personal_emails: true,
        };
        axios
          .post(
            "https://us-central1-video-outreach-773a2.cloudfunctions.net/getLeadDataApollo",
            requestData,
            {
              headers: {
                "Content-Type": "application/json",
                "Cache-Control": "no-cache",
              },
            }
          )
          .then(async (response) => {
            console.log("Response:", response.data);
            const person = response.data.person;
            console.log(person, "person");
            const leadid = `${matchingRow.name}-${person.organization.name}`;
            const leadIdentification = leadid.replace(/ /g, "-").toLowerCase();
            const data = {
              leadName: matchingRow.name,
              leadWebsite: person.organization.website_url,
              leadEmail: person.email,
              client: user?.client,
              leadBusiness: person.organization.name,
              leadID: leadIdentification,
              domain: businessURL,
            };
            const checkifAdded = await getSingleDetailed(
              "leads",
              "client",
              user?.client,
              "leadEmail",
              person.email
            );
            if (!checkifAdded[0]) {
              console.log(data, "DTA");
              const add = await axios.post(
                "https://us-central1-video-outreach-773a2.cloudfunctions.net/addLeadtoClient",
                data
              );
              console.log(add, "add");
              if (add.data) {
                enqueueSnackbar("Added");
                // setTableData((prevTableData) =>
                //   prevTableData.filter((row) => row.id !== selectedId)
                // );
              }
            } else {
              enqueueSnackbar("Lead Already Added: " + matchingRow.name, {
                variant: "error",
              });
              // setTableData((prevTableData) =>
              //   prevTableData.filter((row) => row.id !== selectedId)
              // );
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    });
  };

  return (
    <>
      <Card>
        {/* <LeadFinderTableToolbar
          filters={filters}
          //
          roleOptions={_roles}
        /> */}
        <Button
          onClick={(checked) => {
            table.onSelectAllRows(
              checked,
              tableData.map((row) => row.id)
            );
          }}
        >
          Select All
        </Button>

        <TableContainer sx={{ position: "relative", overflow: "unset" }}>
          <TableSelectedAction
            dense={table.dense}
            numSelected={table.selected?.length}
            rowCount={tableData?.length}
            onSelectAllRows={(checked) =>
              table.onSelectAllRows(
                checked,
                tableData.map((row) => row.id)
              )
            }
            action={
              <>
                <Tooltip title="Add to Leads">
                  <IconButton color="primary" onClick={addLeads}>
                    <Iconify icon="material-symbols:add" />
                  </IconButton>
                </Tooltip>
              </>
            }
          />
          <Scrollbar>
            <Table
              size={table.dense ? "small" : "medium"}
              sx={{ minWidth: 960 }}
            >
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={
                  user?.premium === "infinity"
                    ? TABLE_HEAD_INFINITY
                    : TABLE_HEAD
                }
                rowCount={tableData?.length}
                numSelected={table.selected?.length}
              />
              {loading ? (
                <LinearProgress fullWidth sx={{ width: "100vw" }} />
              ) : (
                <TableBody>
                  {dataFiltered &&
                    [
                      ...new Map(
                        dataFiltered.map((row) => [row.id, row])
                      ).values(),
                    ].map((row) => (
                      <LeadFinderTableRow
                        key={row.id}
                        row={row}
                        selected={table.selected.includes(row.id)}
                        onSelectRow={() => table.onSelectRow(row.id)}
                        client={currentuser?.user?.client}
                      />
                    ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(
                      table.page,
                      table.rowsPerPage,
                      tableData?.length
                    )}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              )}
            </Table>
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom
          count={dataFiltered?.length}
          page={table.page}
          rowsPerPage={1000}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
          onLoadMore={loadMoreData}
          //
          dense={table.dense}
          onChangeDense={table.onChangeDense}
        />
      </Card>
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, comparator, filters }) {
  const { name, status, role } = filters;

  const stabilizedThis = inputData && inputData.map((el, index) => [el, index]);

  if (stabilizedThis) {
    const sortedStabilizedThis =
      stabilizedThis &&
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
    // Use sortedStabilizedThis as needed
  }

  inputData = stabilizedThis && stabilizedThis.map((el) => el[0]);

  // if (name) {
  //   inputData =
  //     inputData &&
  //     inputData.filter(
  //       (user) => user.name.toLowerCase().indexOf(name.toLowerCase()) !== -1
  //     );
  // }

  if (status !== "all") {
    inputData = inputData && inputData.filter((user) => user.status === status);
  }

  if (role?.length) {
    inputData =
      inputData && inputData.filter((user) => role.includes(user.role));
  }

  return inputData;
}
