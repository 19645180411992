import { Button, CircularProgress, Stack } from "@mui/material";
import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  addDocument,
  getSingle,
  updateSingle,
  updateSingleByDocID,
} from "src/auth/context/firebase/auth-provider";
import { useAuthContext } from "src/auth/hooks";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router";

export const sendEmailFromGmail = async (
  rawMessage,
  senderEmail,
  to,
  subject,
  htmlMessage
) => {
  const accessToken = await getSingle("accesstoken", "email", senderEmail);
  if (accessToken[0].accessToken) {
    const url = "https://www.googleapis.com/gmail/v1/users/me/messages/send";

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken[0].accessToken}`, // Use the access token obtained from Google OAuth
        },
        body: JSON.stringify({
          raw: rawMessage,
        }),
      });
      console.log(response, "response");
      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData, "RESPONSE DATA");
        const messageId = responseData.id;
        console.log("Email sent successfully. Message ID:", messageId);
        return {
          success: true,
          message: "Email sent successfully.",
          messageID: messageId,
        };
      } else {
        console.error("Failed to send email:", response.status);
        return {
          success: false,
          message: `Failed to send email: ${response.status}`,
        };
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  } else if (accessToken[0].password) {
    console.log("YEAH STARTED");
    console.log(rawMessage, senderEmail, to, subject, "rawMessage");
    const secretKey = process.env.REACT_APP_ENCRYPT_KEY;
    const encryptedPassword = accessToken[0].password;

    // Decrypt the password
    const bytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey);
    const decryptedPassword = bytes.toString(CryptoJS.enc.Utf8);

    try {
      const response = await axios.post(
        "http://127.0.0.1:5001/video-outreach-773a2/us-central1/sendGmailUsingAppPassword",
        {
          from: senderEmail,
          to: to,
          subject: subject,
          rawMessage: htmlMessage,
          appPassword: decryptedPassword,
        }
      );

      if (response.status === 200) {
        return {
          success: true,
          message: "Email sent successfully.",
        };
      } else {
        return {
          success: false,
          message: "Email Failed",
        };
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  }
};

const GoogleOAuth = ({ newEmail }) => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // Function to handle Google OAuth authentication
  // const handleGoogleAuth = () => {
  //   // Replace with your Google OAuth 2.0 configuration
  //   const clientId =
  //     "886016310102-f0g5nnr5kn2g0r7mjgbmt91vplh3mgm3.apps.googleusercontent.com";
  //   const redirectUri = `https://app.outreachhippo.com/dashboard/`;
  //   // const redirectUri = `http://localhost:3000/dashboard/`;
  //   const scope = [
  //     "https://www.googleapis.com/auth/gmail.readonly",
  //     "https://www.googleapis.com/auth/gmail.send",
  //   ];
  //   const scopeString = scope.join(" "); // Convert the array to a space-separated string

  //   const authUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopeString}&response_type=code&access_type=offline`;

  //   // Open a new window or redirect to authUrl for user authentication
  //   window.location.href = authUrl;
  // };
  const getRefreshToken = async (authCode) => {
    const clientId =
      "886016310102-f0g5nnr5kn2g0r7mjgbmt91vplh3mgm3.apps.googleusercontent.com";
    const clientSecret = "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";
    const redirectUri = `https://app.outreachhippo.com/dashboard/gmail`;
    // const redirectUri = `http://localhost:3000/dashboard/gmail`;
    const authorizationCode = authCode;

    const data = {
      client_id: clientId,
      client_secret: clientSecret,
      code: authorizationCode,
      redirect_uri: redirectUri,
      grant_type: "authorization_code",
      access_type: "offline",
      prompt: "consent",
    };

    async function exchangeCodeForTokens() {
      try {
        const response = await axios.post(
          "https://oauth2.googleapis.com/token",
          null,
          {
            params: data, // Send data as query parameters
          }
        );

        if (response.status === 200) {
          const accessToken = response.data.access_token;
          const refreshToken = response.data.refresh_token;
          console.log(response.data, "refreshToken");
          // Retrieve email/account details using the access token
          const profileResponse = await axios.get(
            "https://www.googleapis.com/gmail/v1/users/me/profile",
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          const email = profileResponse.data.emailAddress;

          // Parse the token response to obtain the 'expires_in' field
          const expiresIn = response.data.expires_in;

          // Calculate the expiration time in seconds
          const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
          const tokenExpirationTimeInSeconds =
            currentTimestampInSeconds + expiresIn;

          setAccessToken(accessToken);

          return {
            accessToken,
            refreshToken,
            tokenExpirationTimeInSeconds,
            email,
          };
        } else {
          // Handle error responses
          console.error(
            "Error exchanging authorization code for tokens:",
            response.status,
            response.data
          );
          setError(response.data);
        }
      } catch (error) {
        // Handle network errors or exceptions
        console.error("Error exchanging authorization code for tokens:", error);
        setError(error);
      }
    }

    // Call the function to exchange the code for tokens
    exchangeCodeForTokens()
      .then(async (tokens) => {
        if (tokens) {
          console.log(tokens, "tokens");
          try {
            await addDocument("accesstoken", "", {
              type: "gmail",
              accessToken: tokens.accessToken ? tokens.accessToken : "",
              refreshToken: tokens.refreshToken ? tokens.refreshToken : "",
              tokenExpirationTimeInSeconds: tokens.tokenExpirationTimeInSeconds
                ? tokens.tokenExpirationTimeInSeconds
                : "",
              email: tokens.email ? tokens.email : "",
              client: user?.client,
            });
            await addDocument("emailaccounts", "", {
              email: tokens.email,
              client: user?.client,
              date: new Date(),
              emails: 0,
              type: "gmail",
            });
            if (user?.onboarding == "add email") {
              const update = updateSingle("users", "email", user?.email, {
                onboarding: "email template",
              });
              navigate("/dashboard/emailtemplate?onboarding=true");
            } else {
              navigate("/dashboard");
            }
          } catch (error) {
            console.log(error, "ERROR");
          }
        }
      })
      .catch((error) => {
        console.error("Error handling tokens:", error);
      });
  };

  const getAccessToken = async () => {
    try {
      setLoading(true);
      const getToken = await getSingle("accesstoken", "client", user?.client);
      if (getToken) {
        const token = getToken[0].accessToken;
        setAccessToken(token);
        // fetchData(token);
      }
      setLoading(false);
    } catch (error) {
      console.log(error, "ERROR");
    }
  };

  useEffect(() => {
    // Parse the access token from the URL after user grants permission
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code) {
      getRefreshToken(code);
      console.log(code, "code");
      window.close();
    }
    if (!accessToken && !code) {
      getAccessToken();
    }
  }, []);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh", // Make the container full height
      }}
    >
      <Stack sx={{ alignItems: "center", textAlign: "center" }}>
        <CircularProgress />
        <p>Checking Credentials...</p>
        {error && <p>{error.message}</p>}
      </Stack>
    </div>
  );
};

export default GoogleOAuth;
