// @mui
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
// hooks
import { useAuthContext } from "src/auth/hooks";
// routes
import { paths } from "src/routes/paths";
// locales
import { useLocales } from "src/locales";
// components
import Label from "src/components/label";
import { useEffect, useState } from "react";
import { getSingle } from "src/auth/context/firebase/auth-provider";

// ----------------------------------------------------------------------

export default function NavUpgrade() {
  const { user } = useAuthContext();

  const { t } = useLocales();

  const [profilePhoto, setProfilePhoto] = useState();
  const getProfilePhoto = async () => {
    const getPhoto = await getSingle("business", "client", user?.client);
    if (getPhoto[0]) {
      setProfilePhoto(getPhoto[0].profilephoto);
    }
  };

  useEffect(() => {
    if (user) getProfilePhoto();
  }, [user]);

  return (
    <Stack
      sx={{
        px: 2,
        py: 5,
        textAlign: "center",
      }}
    >
      <Stack alignItems="center">
        <Box sx={{ position: "relative" }}>
          <Avatar
            src={profilePhoto}
            alt={user?.displayName}
            sx={{ width: 48, height: 48 }}
          />
          {/* <Label
            color="primary"
            variant="filled"
            sx={{
              top: -6,
              px: 0.5,
              left: 40,
              height: 20,
              position: 'absolute',
              borderBottomLeftRadius: 2,
            }}
          >
            Free
          </Label> */}
        </Box>

        <Stack spacing={0.5} sx={{ mt: 1.5, mb: 2 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.displayName}
          </Typography>

          <Typography variant="body2" noWrap sx={{ color: "text.disabled" }}>
            {user?.email}
          </Typography>
        </Stack>
        {!user?.premium && (
          <Button
            variant="contained"
            href="https://app.outreachhippo.com/dashboard/user/account?billing"
          >
            {t("Upgrade")}
          </Button>
        )}
      </Stack>
    </Stack>
  );
}
