import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";
import { Button } from "@mui/material";

export default function TablePaginationCustom({
  dense,
  onChangeDense,
  rowsPerPageOptions = [5, 10, 25],
  sx,
  onLoadMore,
  ...other
}) {
  return (
    <Box sx={{ position: "relative", ...sx }}>
      {onLoadMore && <Button onClick={onLoadMore}>Load More</Button>}
      {/* <TablePagination rowsPerPageOptions={rowsPerPageOptions} {...other} /> */}
    </Box>
  );
}

TablePaginationCustom.propTypes = {
  dense: PropTypes.bool,
  onChangeDense: PropTypes.func,
  onLoadMore: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  sx: PropTypes.object,
};
