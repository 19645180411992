import { useEffect, useCallback, useState } from "react";

import Container from "@mui/material/Container";
// routes
import { paths } from "src/routes/paths";
import { useParams } from "src/routes/hook";
// _mock
import { _userList } from "src/_mock";
// components
import { useSettingsContext } from "src/components/settings";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";

import EmailEditForm from "./email-edit-form";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useAuthContext } from "src/auth/hooks";
import {
  AddCircleOutline,
  ExpandMoreOutlined,
  KeyboardArrowDown,
  KeyboardArrowDownOutlined,
  KeyboardDoubleArrowDown,
} from "@mui/icons-material";
import FollowupEmailEditForm from "./followup-email-edit-form";
import { useNavigate } from "react-router";
import {
  addDocument,
  getSingle,
  getSingleDetailed,
  updateSingle,
} from "src/auth/context/firebase/auth-provider";
import { useBoolean } from "src/hooks/use-boolean";
import CampaignListView from "../user/view/campaign-list-view";
import EmailFlowModalView from "./email-flow-modal";

// ----------------------------------------------------------------------

export default function EmailEditView() {
  const navigate = useNavigate();
  const settings = useSettingsContext();
  const modal = useBoolean();

  const params = useParams();

  const { id } = params;

  const { user } = useAuthContext();
  const [flows, setFlows] = useState();
  useEffect(() => {
    if (user) getFlow();
  }, [user]);

  const getFlow = async () => {
    const getFlows = await getSingle("flows", "client", user?.client);
    if (getFlows) {
      setFlows(getFlows[0].flow);
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const openDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeDropdown = () => {
    setAnchorEl(null);
  };

  const [onboarding, setOnboarding] = useState(false);
  useEffect(() => {
    function isOnboardingInURL() {
      const urlParams = new URLSearchParams(window.location.search);
      return (
        urlParams.has("onboarding") && urlParams.get("onboarding") === "true"
      );
    }

    // Example usage:
    if (isOnboardingInURL()) {
      console.log("Onboarding is enabled in the URL.");
      setOnboarding(true);
    }
  }, []);

  const [mainFlow, setMainFlow] = useState();
  const openModal = async (flow) => {
    const getEmailTemplate = await getSingleDetailed(
      "emailtemplate",
      "campaignFlowStep",
      flow,
      "client",
      user?.client
    );
    if (getEmailTemplate) {
      modal.onTrue();
      setMainFlow(getEmailTemplate[0]);
    }
  };

  const addFollowUp = () => {
    const newFlows = [...flows];

    // Calculate the new entry name based on the number of existing entries
    const numberOfEntries = flows.length;
    console.log(numberOfEntries, "numberOfEntries");

    let newEntryName = `Follow Up ${numberOfEntries + 1}`;

    // Add the new entry to the array
    newFlows.push(newEntryName);

    console.log(newFlows);
    const add = updateSingle("flows", "client", user?.client, {
      flow: newFlows,
    });
    const addEmailTemplate = addDocument("emailtemplate", user?.uid, {
      campaignFlowStep: newEntryName,
      client: user?.client,
      emailBody: "",
      signature: "",
      subject: "",
    });
    getFlow();
  };

  return (
    <Container maxWidth={settings.themeStretch ? false : "lg"}>
      <CustomBreadcrumbs
        heading="Edit"
        links={[
          {
            name: "Dashboard",
            href: paths.dashboard.root,
          },
          {
            name: "Email Template",
            // href: paths.dashboard.user.root,
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      {onboarding && (
        <Card
          style={{
            backgroundColor: "#133A26",
            color: "white",
            alignItems: "center",
            textAlign: "center",
            padding: "30px",
            marginBottom: "30px",
          }}
        >
          <Typography>Onboarding</Typography>
          <Typography variant="h6">
            Now we will create our email template
          </Typography>
          <Typography>
            Below is the email template that will go out to your leads. You can
            come back later and edit the copy.
          </Typography>
          <Button
            variant="outlined"
            onClick={() => {
              updateSingle("users", "email", user?.email, {
                onboarding: "video",
              });
              navigate("/dashboard/video?onboarding=true");
            }}
            style={{
              // backgroundColor: "white",
              color: "white",
              marginTop: "20px",
            }}
          >
            Continue Onboarding
          </Button>
        </Card>
      )}
      <Grid
        container
        spacing={5}
        sx={{ alignItems: "center", textAlign: "center" }}
      >
        {flows &&
          flows.map((flow) => (
            <Grid
              item
              xs={12}
              onClick={() => openModal(flow)}
              sx={{ cursor: "pointer" }}
            >
              <Card sx={{ padding: "30px" }}>
                <Typography variant="h3">{flow}</Typography>
              </Card>
              <KeyboardArrowDownOutlined
                sx={{ marginTop: "30px", marginBottom: "0px !important" }}
              />
            </Grid>
          ))}
        <Grid item xs={12}>
          <Button
            variant="none"
            placeholder="Add Step"
            style={{
              textAlign: "center",
              marginTop: "16px",
              alignItems: "center",
            }}
            onClick={addFollowUp}
          >
            <AddCircleOutline />
          </Button>
        </Grid>
      </Grid>
      {/* <Card sx={{ p: 3 }}>
        <p style={{ textAlign: "center", fontWeight: "bold" }}>Initial Email</p>
        <EmailEditForm user={user} />
      </Card>
      <Card sx={{ p: 3, mt: 3 }}>
        <p style={{ textAlign: "center", fontWeight: "bold" }}>
          Follow Up Email
        </p>
        <FollowupEmailEditForm user={user} />
      </Card> */}
      {mainFlow && (
        <EmailFlowModalView
          open={modal.value}
          onClose={modal.onFalse}
          flow={mainFlow}
        />
      )}
    </Container>
  );
}
