import PropTypes from "prop-types";
import { useEffect, useCallback, useState } from "react";
// routes
import { paths } from "src/routes/paths";
import { useRouter } from "src/routes/hook";
//
import { useAuthContext } from "../hooks";

// ----------------------------------------------------------------------

const loginPaths = {
  firebase: paths.auth.firebase.login,
};

// ----------------------------------------------------------------------

export default function AuthGuard({ children }) {
  const router = useRouter();

  const { authenticated, method } = useAuthContext();

  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    if (!authenticated) {
      console.log("NOT AUTHENTICATED");
      const searchParams = new URLSearchParams({
        returnTo: window.location.href,
      }).toString();

      const loginPath = loginPaths[method];

      const href = `${loginPath}?${searchParams}`;

      router.replace(href);
    } else {
      setChecked(true);
    }
  }, [authenticated, method, router]);

  useEffect(() => {
    check();
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}

AuthGuard.propTypes = {
  children: PropTypes.node,
};
