import { useEffect, useState } from "react";
// @mui
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import ListItemText from "@mui/material/ListItemText";
// hooks
import {
  deleteSingle,
  getMultiple,
  getSingle,
  updateSingle,
} from "src/auth/context/firebase/auth-provider";
import { useBoolean } from "src/hooks/use-boolean";
// components
import Label from "src/components/label";
import Iconify from "src/components/iconify";
import CustomPopover, { usePopover } from "src/components/custom-popover";
import { ConfirmDialog } from "src/components/custom-dialog";
//
import { enqueueSnackbar } from "notistack";
import LeadViewForm from "./lead-view-form";
import { checkAccessTokenAgencySEOGrowth } from "./view/gmail-api-agencyseogrowth";
import { checkAccessTokenDigitalSEOGrowth } from "./view/gmail-api-digitalseogrowth";
import { CheckBox } from "@mui/icons-material";
import { useAuthContext } from "src/auth/hooks";

// ----------------------------------------------------------------------

LeadTableRow.propTypes = {
  onQuickEditChange: PropTypes.func.isRequired,
};

export default function LeadTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
  onQuickEditChange,
  client,
  campaignID,
  onboarding,
}) {
  const {
    leadName,
    leadBusiness,
    leadID,
    leadWebsite,
    emailed,
    leadEmail,
    email,
    personalBody,
    // timewatched,
    id,
  } = row;
  const { user } = useAuthContext();
  const [businessCustomURL, setBusinessCustomURL] = useState();

  const confirm = useBoolean();

  const quickEdit = useBoolean();

  const popover = usePopover();

  const [prevValue, setPrevValue] = useState(quickEdit.value);

  const [getLeadData, setGetLeadData] = useState(false);

  useEffect(() => {
    if (prevValue === true && quickEdit.value === false) {
      setGetLeadData(true);
      onQuickEditChange(quickEdit.value);
    } else if (quickEdit.value === true) {
      setGetLeadData(false);
    }

    setPrevValue(quickEdit.value);
    // if (leadAnalytics) getLeadAnalytics();
  }, [quickEdit.value, prevValue, onQuickEditChange]);
  const [agencyToken, setAgencyToken] = useState();
  const [digitalToken, setDigitalToken] = useState();

  useEffect(() => {
    // if (agencyToken == null) {
    // const isAgencyAccessTokenValid = checkAccessTokenAgencySEOGrowth();
    // setAgencyToken(isAgencyAccessTokenValid);
    // }
    // if (digitalToken == null) {
    // const isDigitalAccessTokenValid = checkAccessTokenDigitalSEOGrowth();
    // setDigitalToken(isDigitalAccessTokenValid);
    // }
  }, [agencyToken, digitalToken]);
  const deleteClient = async () => {
    if (leadID && client) {
      await deleteSingle("leads", leadID, "leadID", client, "client");
      onDeleteRow("DELETED");
      confirm.onFalse();
      onQuickEditChange(quickEdit.value);
    } else {
      console.log(leadID, client, "NO LEAD ID");
    }
  };
  useEffect(() => {}, [row]);

  const [imageBlob, setImageBlob] = useState();

  const copyImageUrlToClipboard = async () => {
    try {
      const blobs = await getThumbnail();

      if (blobs) {
        setImageBlob(blobs);
        await updateSingle("leads", "leadID", leadID, { preview: blobs });
        enqueueSnackbar("Copied to Clipboard");
      }
    } catch (error) {
      console.error("Error copying image URL:", error);
      enqueueSnackbar("ERROR");
    }
  };

  const emailComplete = async () => {
    try {
      await updateSingle("leads", "leadID", leadID, { emailed: "true" });
      enqueueSnackbar("Updated");
    } catch (error) {
      console.error("Error copying image URL:", error);
      enqueueSnackbar("ERROR");
    }
  };

  const getThumbnail = async () => {
    try {
      const getBusinessURL = await getSingle(
        "business",
        "client",
        user?.client
      );
      const url = getBusinessURL[0].customDomain;
      const response = await axios.post(
        `https://us-central1-video-outreach-773a2.cloudfunctions.net/takePreviewScreenshot?url=https://${url}/video/${leadID}`
      );
      return response.data;
    } catch (error) {
      console.error("Error taking screenshot:", error);
    }
    return null;
  };

  return (
    <>
      <TableRow hover>
        <TableCell padding="checkbox">
          <Checkbox checked={emailed} />
        </TableCell>
        {imageBlob && <img src={imageBlob} alt="Blob " />}
        <TableCell sx={{ display: "flex", alignItems: "center" }}>
          <ListItemText
            primary={leadName}
            secondary={email ? email : leadEmail}
            primaryTypographyProps={{ typography: "body2" }}
            secondaryTypographyProps={{
              component: "span",
              color: "text.disabled",
            }}
          />
        </TableCell>

        <TableCell>{leadID}</TableCell>

        <TableCell>
          <a target="_blank" rel="noopener noreferrer" href={leadWebsite}>
            Website
          </a>
        </TableCell>

        {user?.premium === "infinity" && (
          <TableCell sx={{ textAlign: "center" }}>
            {personalBody ? <CheckBox style={{ color: "green" }} /> : <p></p>}
          </TableCell>
        )}

        {/* {timewatched ? (
          <TableCell>Watched</TableCell>
        ) : (
          <TableCell>N/A</TableCell>
        )} */}

        <TableCell align="right" sx={{ px: 1, whiteSpace: "nowrap" }}>
          <Tooltip title="Send Email" placement="top" arrow>
            <Button variant="contained" onClick={quickEdit.onTrue}>
              Send Email
            </Button>
          </Tooltip>

          <IconButton
            color={popover.open ? "inherit" : "default"}
            onClick={popover.onOpen}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <LeadViewForm
        currentUser={row}
        open={quickEdit.value}
        onClose={quickEdit.onFalse}
        getLead={getLeadData}
        campaignID={campaignID}
        onboarding={onboarding}
      />

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={async () => {
            const getBusiness = await getSingle(
              "business",
              "client",
              row.client
            );
            const businessURL = getBusiness[0].customDomain;
            const textToCopy = `<a href="${businessURL}/video/${row.leadID}"></a>`;

            navigator.clipboard
              .writeText(textToCopy)
              .then(() => {
                console.log("Text copied to clipboard:", textToCopy);
              })
              .catch((error) => {
                console.error("Failed to copy text to clipboard:", error);
              });
            enqueueSnackbar("Copied");
          }}
          sx={{ color: "success" }}
        >
          <Iconify icon="material-symbols:image" />
          Copy Link
        </MenuItem>
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: "error.main" }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={deleteClient}>
            Delete
          </Button>
        }
      />
    </>
  );
}

LeadTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
};
