import { Helmet } from "react-helmet-async";
import EmailEditView from "src/sections/email/email-edit-view";
import LeadFinderView from "src/sections/leadfinder/lead-finder-view";
import TriggerView from "src/sections/triggers/trigger-view";

// ----------------------------------------------------------------------

export default function TriggerPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Trigger</title>
      </Helmet>

      <TriggerView />
    </>
  );
}
