import { useEffect, useCallback, useState } from "react";

import Container from "@mui/material/Container";
// routes
import { paths } from "src/routes/paths";
import { useParams } from "src/routes/hook";
// _mock
import { _userList } from "src/_mock";
// components
import { useSettingsContext } from "src/components/settings";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";
import axios from "axios";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useAuthContext } from "src/auth/hooks";
import Iconify from "src/components/iconify";
import {
  addDocument,
  getMultiple,
  getSingle,
  updateOrCreateSingle,
  updateOrCreateSingleDetailed,
  updateSingle,
} from "src/auth/context/firebase/auth-provider";
import { useBoolean } from "src/hooks/use-boolean";
import { ConfirmDialog } from "src/components/custom-dialog";
import { enqueueSnackbar } from "notistack";

// ----------------------------------------------------------------------

export default function TriggerView() {
  const settings = useSettingsContext();
  const { user } = useAuthContext();
  const modal = useBoolean();
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");

  const [integrations, setIntegrations] = useState([
    {
      name: "Keyword Rankings",
      description: "Send Emails based off the leads current rankings.",
      descriptionEnabled:
        "Outreach Hippo will now look for potential keywords your leads should be ranking for. Once found, it'll craft a personal email. Note: This will only apply for leads added going forward.",
      connected: false, // default to false, update after fetching triggers
    },
    {
      name: "Website",
      description:
        "Craft a personal email based off the leads website content.",
      descriptionEnabled:
        "Outreach Hippo will now craft personal emails based of your leads website. Note: This will overide Keyword Rankings if enabled",
      connected: false, // default to false, update after fetching triggers
    },
    {
      name: "CRM",
      description: "Update your CRM with events.",
      descriptionEnabled:
        "Outreach Hippo will now craft personal emails based of your leads website. Note: This will overide Keyword Rankings if enabled",
      connected: false,
      available: false,
    },
  ]);

  useEffect(() => {
    if (user) {
      getUsersTriggers();
    }
  }, [user]);

  const getUsersTriggers = async () => {
    try {
      const triggers = await getMultiple("triggers", "client", user?.client);
      console.log(triggers, "triggers");
      // Check if triggers is an array and has at least one element
      if (triggers[0]) {
        setIntegrations(triggers);
      } else {
        // Handle case where triggers is not an array or is empty
        const data = [
          {
            name: "Keyword Rankings",
            description: "Send Emails based of the leads current rankings.",
            descriptionEnabled:
              "Outreach Hippo will now look for potential keywords your leads should be ranking for. Once found, it'll craft a personal email. Note: This will only apply for leads added going forward.",
            connected: false,
            available: true,
          },
          {
            name: "Website",
            description:
              "Craft a personal email based off the leads website content.",
            descriptionEnabled:
              "Outreach Hippo will now craft personal emails based of your leads website. Note: This will overide Keyword Rankings if enabled",
            connected: false,
            available: true,
          },
          {
            name: "CRM",
            description: "Update your CRM with events.",
            descriptionEnabled:
              "Outreach Hippo will now craft personal emails based of your leads website. Note: This will overide Keyword Rankings if enabled",
            connected: false,
            available: false,
          },
        ];
        data.forEach((trigger) => {
          console.log(trigger, "TRIGGER");
          addDocument("triggers", "", { ...trigger, client: user?.client });
          setIntegrations(data);
        });
      }
    } catch (error) {
      console.error("Failed to fetch triggers:", error);
    }
  };

  return (
    <Container maxWidth={settings.themeStretch ? false : "lg"}>
      <CustomBreadcrumbs
        heading="View"
        links={[
          {
            name: "Dashboard",
            href: paths.dashboard.root,
          },
          {
            name: "Triggers",
            // href: paths.dashboard.user.root,
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <Grid container spacing={4}>
        {integrations.map((integration, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h5" component="div">
                  {integration.name}
                </Typography>
                <Typography variant="body2">
                  {integration.description}
                </Typography>
                {/* {integration.connected && (
                  <Button
                    variant={!integration.connected ? "contained" : "outlined"}
                    sx={{ mt: 3 }}
                    fullWidth
                    onClick={() =>
                      integration.connected
                        ? (() => {
                            setModalTitle(integration.name);
                            setModalContent(integration.descriptionEnabled);
                            modal.onTrue();
                          })()
                        : (() => {
                            updateOrCreateSingleDetailed(
                              "triggers",
                              "client",
                              user?.client,
                              {
                                ...integration,
                                connected: true,
                              },
                              "name",
                              integration.name
                            );
                            setModalTitle(integration.name);
                            setModalContent(integration.descriptionEnabled);
                            modal.onTrue();
                            getUsersTriggers();
                          })()
                    }
                  >
                    {integration.connected ? "Manage" : "Connect"}
                  </Button>
                )} */}
                {!integration.available ? (
                  <Button disabled>Coming Soon</Button>
                ) : (
                  <>
                    <Button
                      variant={
                        !integration.connected ? "contained" : "outlined"
                      }
                      sx={{ mt: 3 }}
                      fullWidth
                      onClick={() =>
                        integration.connected
                          ? (() => {
                              updateOrCreateSingleDetailed(
                                "triggers",
                                "client",
                                user?.client,
                                {
                                  ...integration,
                                  connected: false,
                                },
                                "name",
                                integration.name
                              );
                              enqueueSnackbar(
                                `${integration.name} has been disconnected`
                              );
                              getUsersTriggers();
                            })()
                          : (() => {
                              updateOrCreateSingleDetailed(
                                "triggers",
                                "client",
                                user?.client,
                                {
                                  ...integration,
                                  connected: true,
                                },
                                "name",
                                integration.name
                              );
                              setModalTitle(integration.name);
                              setModalContent(integration.descriptionEnabled);
                              modal.onTrue();
                              getUsersTriggers();
                            })()
                      }
                    >
                      {integration.connected ? "Disconnect" : "Connect"}
                    </Button>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <ConfirmDialog
        open={modal.value}
        onClose={modal.onFalse}
        title={modalTitle}
        content={modalContent}
      />
    </Container>
  );
}
