import React, { useEffect, useState } from 'react';
import { getSingle, getUniqueDemoID } from 'src/auth/context/firebase/auth-provider';
import Box from '@mui/material/Box';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import DemoQuestions from '../demo-questions';
import DemoVideo from '../demo-video';
import DemoWelcomeView from './demo-welcome';

export default function DemoView() {
  const [view, setView] = useState('welcome');
  const [demoData, setDemoData] = useState();
  const [intercom, setIntercom] = useState();

  const handleComplete = (value) => {
    setView(value);
  };

  // Example
  const urlID = window.location.pathname;

  useEffect(() => {
    const lastPart = urlID.split('/').pop();
    async function fetchDemoData() {
      const getDemoData = await getUniqueDemoID('leads', 'leadid', lastPart);
      if (getDemoData) {
        setDemoData(getDemoData);
        setView('questions');
      }
    }
    if (!demoData && lastPart !== 'demo') fetchDemoData();
  }, [urlID, demoData]);

  function getDemoId(url) {
    const regex = /\/demo\/([^/]+)/;
    const match = url.match(regex);

    if (match && match.length >= 2) {
      return match[1];
    }

    return null;
  }

  const demoId = getDemoId(urlID);

  useEffect(() => {
    async function fetchDemoData() {
      const getBusiness = await getSingle('business', 'demoid', demoId);
      if (getBusiness) {
        setIntercom(getBusiness[0].intercom);
      }
    }
    if (!intercom) fetchDemoData();
  }, [demoId, intercom]);

  return (
    <Box
      component="main"
      sx={{
        py: 12,
        display: 'flex',
        minHeight: '100vh',
        textAlign: 'center',
        px: { xs: 2, md: 0 },
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        '&:before': {
          width: 1,
          height: 1,
          zIndex: -1,
          content: "''",
          opacity: 0.24,
          position: 'absolute',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundImage: 'url(/assets/background/overlay_4.jpg)',
        },
      }}
    >
      {/* {view === 'welcome' && <DemoWelcomeView complete={handleComplete} />} */}
      {view === 'questions' && (
        <IntercomProvider appId={intercom}>
          <DemoQuestions complete={handleComplete} />
        </IntercomProvider>
      )}
      {view === 'video' && (
        <IntercomProvider appId={intercom}>
          <DemoVideo />
        </IntercomProvider>
      )}
    </Box>
  );
}
